<template>
  <FolderReport />
  <div class="saved-items-page">
    <div class="column nav-for-mobile">
      <q-splitter
        v-model="splitterModel"
        style="min-height: calc(100vh - 310px); height: auto"
        class="saved-items-small"
      >
        <template v-slot:before>
          <div
            v-if="isVind"
            style="min-height: 15vh; min-width: 300px; background-color: #f8f8f8"
            class="new-folder-container"
          >
            <q-btn no-caps @click="enableAddNew()" class="q-mb-md newFolder">
              <q-icon name="create_new_folder"></q-icon>
              <span style="font-weight: bold"
                >&nbsp;&nbsp;&nbsp;{{ t('saved_and_hidden_items.new_folder') }}</span
              >
            </q-btn>
            <div class="q-pl-xl folders-list">
              <div
                class="q-mt-none row q-gutter-sm text-left folders-btns"
                v-for="folder in foldersWithItems"
                :key="folder.id"
              >
                <div class="list">
                  <a
                    class="selected-folder row"
                    v-if="folder.id == selectedFolderId"
                    @click="getItems(folder.id, folder.name)"
                  >
                    <q-icon name="fa-solid fa-folder-open" size="15px">
                    </q-icon>
                    <div class="q-ma-none q-ml-xs overflow-folder-name">
                      {{ folder.name }}
                    </div>
                    <q-tooltip>{{folder.name}}</q-tooltip>
                  </a>
                  <a
                    v-else
                    class="row non-selected-folder"
                    @click="getItems(folder.id, folder.name)"
                  >
                    <q-icon
                      class="row wrap"
                      name="fa-solid fa-folder-closed"
                      size="15px"
                      no-capst
                    >
                    </q-icon>
                    <div class="q-ma-none q-ml-xs overflow-folder-name">
                      {{ folder.name }}
                    </div>
                    <q-tooltip>{{folder.name}}</q-tooltip>
                  </a>
                </div>
                <div class="q-mr-md float-right edit-map-prompt">
                  <q-icon
                    class="edit-folders"
                    name="fa-solid fa-pen-to-square"
                    size="14px"
                    style="color: #0a108f; margin-top: -3px; margin-right: 8px"
                    @click="enableRenameFolder(folder.id, folder.name)"
                  >
                    <q-tooltip> {{ t('saved_and_hidden_items.rename_folder') }} </q-tooltip>
                  </q-icon>
                  <q-icon
                    class="delete-folders"
                    name="fa-solid fa-trash"
                    size="14px"
                    style="color: #0a108f; margin-bottom: 5px"
                    @click="
                      (confirmDeleteFolder = true), (pickedFolderId = folder.id)
                    "
                  >
                    <q-tooltip> {{ t('saved_and_hidden_items.delete_folder') }} </q-tooltip>
                  </q-icon>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:after>
          <div
            class="folderContent"
            style="display: flex; flex-direction: column"
          >
            <div v-if="selectedFolderId != null">
              <!-- <q-tabs
              v-model="savedItemsTab"
              dense
              class="text-grey"
              align="justify"
              narrow-indicator
            >
              <q-tab name="schemes" label="Opgeslagen items"> </q-tab>
              <q-tab name="searches" label="Opgeslagen zoekacties"> </q-tab>
            </q-tabs>

            <q-separator /> -->

              <div class="zoekacties">
                <q-tab-panel
                  name="savedItems"
                  class="searches"
                  v-if="isVind "
                >
                  <q-breadcrumbs class="text-brown navigation-breadcrumbs">
                    <template v-slot:separator>
                      <q-icon size="1.5em" name="chevron_right"></q-icon>
                    </template>

                    <q-breadcrumbs-el :label="t('profile.profile')"></q-breadcrumbs-el>
                    <q-breadcrumbs-el
                      :label="t('navbar.saved_items')"
                    ></q-breadcrumbs-el>
                    <q-breadcrumbs-el
                      :label="t('profile.saved_searches')"
                    ></q-breadcrumbs-el>
                  </q-breadcrumbs>

                  <div class="text-h4 text-left main-caption">
                    {{ t('profile.saved_searches') }}
                  </div>

                  <p class="heading2">
                    {{ t('saved_and_hidden_items.select_or_create_folder_for_saved_searches_hint') }}
                  </p>
                  <div class="open-drawer-btn">
                    <q-btn
                      @click="leftDrawerOpen = true"
                      class="open-drawer-btn"
                    >
                      <q-icon
                        size="18px"
                        name="fa-solid fa-folder-open"
                      ></q-icon>
                    </q-btn>
                  </div>
                  <div
                    id="q-app"
                    style="min-height: 50vh"
                    v-if="folderSearchesItems.length > 0"
                  >
                    <div class="column table-for-mobile">
                      <div class="column no-wrap search-zoekacties">
                        <span class="q-mb-xs q-mt-md">
                          {{ folderName }}
                        </span>
                        <q-input
                          class="input-field"
                          borderless
                          dense
                          debounce="300"
                          color="primary"
                          :placeholder="t('search_schemes.search')"
                          v-model="filter"
                          @update:model-value="
                            searchesFilter(folderSearchesItems, filter)
                          "
                        >
                          <q-icon
                            class="search"
                            name="search"
                            size="16px"
                            style="margin-right: 70%"
                          ></q-icon>
                        </q-input>
                      </div>
                      <div class="expansion-list">
                        <q-list
                          v-for="x in filteredArraySearches"
                          :key="x.id"
                          padding
                          bordered
                        >
                          <q-expansion-item
                            v-if="x && x.savedSearch"
                            :label="x.savedSearch.title"
                            dense
                            dense-toggle
                            expand-separator
                          >
                            <div class="column q-mx-md content-for-searches">
                              <div class="flex justify-between about-scheme">
                                <span class="spans">{{ t('saved_and_hidden_items.alert') + ':' }}</span>
                                <div class="alert">
                                  <div v-if="x.hasAlert == false">
                                    <q-toggle
                                      v-model="x.hasAlert"
                                      icon="clear"
                                      style="color: #191d96"
                                      @click="
                                        updateAlert(x.id, x.folderId, true)
                                      "
                                    >
                                      <q-tooltip> {{ t('saved_and_hidden_items.turn_on_alert') }} </q-tooltip>
                                    </q-toggle>
                                  </div>

                                  <div v-if="x.hasAlert == true">
                                    <q-toggle
                                      v-model="x.hasAlert"
                                      icon="check"
                                      @click="
                                        updateAlert(x.id, x.folderId, false)
                                      "
                                    >
                                      <q-tooltip> {{ t('saved_and_hidden_items.turn_on_alert') }} </q-tooltip>
                                    </q-toggle>
                                  </div>
                                </div>
                              </div>
                              <div class="flex justify-between about-scheme">
                                <span class="spans">{{ t('saved_and_hidden_items.actions') }}</span>
                                <div class="row activity-buttons">
                                  <q-btn
                                    v-if="x.savedSearch != null"
                                    class="details-btn"
                                    size="sm"
                                    no-caps
                                    @click="applyFilter(x.savedSearch.id)"
                                  >
                                    <i
                                      class="las la-2x la-search"
                                      style="color: white"
                                    ></i>
                                    <q-tooltip> {{ t('saved_and_hidden_items.apply_filter') }} </q-tooltip>
                                  </q-btn>

                                  <q-btn
                                    class="delete-button"
                                    v-if="x.savedSearch != null"
                                    @click="
                                      (confirm = true),
                                        (searchId = x.savedSearch.searchId),
                                        (folderId = x.id)
                                    "
                                    size="sm"
                                    no-caps
                                  >
                                    <q-icon
                                      name="delete_outline"
                                      size="20px"
                                    ></q-icon>
                                    <q-tooltip> {{ t('saved_and_hidden_items.to_delete') }} </q-tooltip>
                                  </q-btn>
                                </div>
                              </div>
                            </div>
                          </q-expansion-item>
                        </q-list>
                      </div>
                    </div>

                    <div class="table-for-desktop q-pa-md">
                      <q-table
                        :rows="folderSearchesItems"
                        :columns="savedSearchesColumns"
                        row-key="id"
                        :filter="filter"
                        :loading="loading"
                        :filter-method="searchesFilter"
                        :pagination="initialPagination"
                        :rows-per-page-label="t('search_news.results_per_page')"
                      >
                        <template v-slot:top>
                          <div class="column no-wrap" style="overflow: auto;">
                            <div class="folderName q-mb-md">
                              {{ folderName }}
                            </div>
                            <q-input
                              borderless
                              dense
                              debounce="300"
                              color="primary"
                              :placeholder="t('search_schemes.search')"
                              v-model="filter"
                            >
                              <q-icon
                                class="search"
                                name="search"
                                size="16px"
                              ></q-icon>
                            </q-input>
                          </div>
                          <q-space />
                          <!-- trenutno ne rade raports provjeriti  -->
                        </template>

                        <template v-slot:body="props">
                          <q-tr :props="props">
                            <q-td key="title" :props="props">
                              <a
                                @click="applyFilter(props.row.searchId)"
                                class="saved-search-title"
                              >
                                {{
                                  props.row.savedSearch != null
                                    ? props.row.savedSearch.title
                                    : null
                                }}
                              </a>
                            </q-td>
                            <q-td
                              key="alert"
                              :props="props"
                              class="q-gutter-xs"
                            >
                              <div v-if="props.row.hasAlert == false">
                                <q-toggle
                                  v-model="props.row.hasAlert"
                                  icon="clear"
                                  style="color: #0a108f"
                                  @click="
                                    updateAlert(
                                      props.row.id,
                                      props.row.folderId,
                                      true
                                    )
                                  "
                                >
                                  <q-tooltip> {{ t('saved_and_hidden_items.turn_on_alert') }} </q-tooltip>
                                </q-toggle>
                              </div>
                              <div v-if="props.row.hasAlert == true">
                                <q-toggle
                                  v-model="props.row.hasAlert"
                                  icon="check"
                                  @click="
                                    updateAlert(
                                      props.row.id,
                                      props.row.folderId,
                                      false
                                    )
                                  "
                                >
                                  <q-tooltip> Alert uitzetten </q-tooltip>
                                </q-toggle>
                              </div>
                            </q-td>
                            <q-td
                              key="applyFilters"
                              :props="props"
                              class="text-right q-gutter-xs"
                            >
                              <q-btn
                                v-if="props.row.savedSearch != null"
                                class="details-btn"
                                size="sm"
                                no-caps
                                @click="applyFilter(props.row.searchId)"
                              >
                                <i
                                  class="las la-2x la-search"
                                  style="color: white"
                                ></i>
                                <q-tooltip> {{ t('saved_and_hidden_items.apply_filter') }} </q-tooltip>
                              </q-btn>
                              <q-btn
                                v-if="props.row.savedSearch != null"
                                class="delete-button"
                                @click="
                                  (confirm = true),
                                    (searchId = props.row.searchId),
                                    (folderId = props.row.folderId)
                                "
                                size="sm"
                                no-caps
                              >
                                <q-icon
                                  name="delete_outline"
                                  size="20px"
                                ></q-icon>
                                <q-tooltip> {{ t('saved_and_hidden_items.to_delete') }} </q-tooltip>
                              </q-btn>
                            </q-td>
                          </q-tr>
                        </template>
                      </q-table>
                    </div>
                  </div>
                  <div class="none-selected-folder" v-else>
                    {{ t('saved_and_hidden_items.there_are_no_saved_searches') }}
                  </div>
                </q-tab-panel>
              </div>
            </div>
          </div>
          <q-dialog v-model="showWarning" class="profile-popup">
            <q-card>
              <q-card-section class="row items-center">
                <!--   <i class="las la-2x la-exclamation-circle"></i> -->
                <q-icon
                  name="info"
                  color="indigo-10"
                  size="32px"
                  class="popup-icon"
                ></q-icon>
                <span class="q-ml-sm bold-font popup-text">
                  {{ t('search_schemes.select_at_least_one_item') }}
                </span>
              </q-card-section>

              <q-card-actions align="right">
                <q-btn
                  :label="t('common.ok')"
                  class="orange-btn bold-font q-mr-md q-mb-sm"
                  style="width: 60px"
                  v-close-popup
                />
              </q-card-actions>
            </q-card>
          </q-dialog>
          <q-dialog
            v-model="showRenameFolderDialog"
            persistent
            class="rename-folder-dialog"
          >
            <q-card class="edit-map-prompt" style="min-width: 350px">
              <q-card-section>
                <div class="row" style="color: #0a108f">
                  <i class="las la-2x la-pen la-fw"></i>
                  <span class="edit-folder-span"> {{ t('saved_and_hidden_items.rename_folder') }} </span>
                </div>
              </q-card-section>
              <q-separator />
              <q-card-section class="q-pt-none">
                <div class="row">
                  <q-input
                    class="q-pa-md q-ml-lg"
                    style="min-width: 300px"
                    v-model="pickedFolderName"
                    autogrow
                    :placeholder="pickedFolderName"
                    :rules="requiredFieldRule"
                    borderless
                  >
                  </q-input>
                </div>
              </q-card-section>
              <q-card-actions align="right">
                <q-btn
                  style="background: #ff8500; color: white"
                  no-caps
                  class="orange-btn-filled"
                  flat
                  :label="t('search_schemes.save')"
                  @click="renameFolder(pickedFolderId, pickedFolderName)"
                />
                <q-btn
                  class="orange-btn bg-gray-button"
                  flat
                  no-caps
                  :label="t('common.cancel')"
                  @click="disableRenameFolder()"
                />
              </q-card-actions>
            </q-card>
          </q-dialog>
          <q-dialog class="delete-card" v-model="confirm" persistent>
            <q-card class="delete-prompt">
              <q-card-section class="row items-center">
                <span class="q-ml-sm"
                  >{{ t('saved_and_hidden_items.remove_saved_search_confirmation') }}</span
                >
              </q-card-section>
              <q-card-actions align="right">
                <q-btn
                  :label="t('common.cancel')"
                  v-close-popup
                  no-caps
                  flat
                  class="orange-btn bg-gray-button"
                />
                <q-btn
                  :label="t('saved_and_hidden_items.to_delete')"
                  no-caps
                  color="white"
                  class="submit-btn-profile"
                  flat
                  v-close-popup
                  @click="removeSavedSearch(searchId, folderId)"
                />
              </q-card-actions>
            </q-card>
          </q-dialog>
          <q-dialog
            class="remove-saved-schemes"
            v-model="confirmSchemeDelete"
            persistent
          >
            <q-card style="background-color: #f1f1f1" class="delete-prompt">
              <q-card-section class="row items-center">
                <span v-if="isVind" class="q-ml-sm">
                  {{ labelRemoveFavoriteItem }}
                </span>
                <span v-else class="label-env">
                  {{ labelRemoveFavoriteItem }}
                </span>
              </q-card-section>

              <q-card-actions align="center">
                <q-btn
                  :label="labelDontRemoveFavoriteItem"
                  color="white"
                  class="submit-btn-profile"
                  v-close-popup
                  no-caps
                  flat
                />
                <q-btn
                  :label="deletePromptLabel"
                  flat
                  no-caps
                  class="orange-btn bg-gray-button"
                  v-close-popup
                  @click="removeSavedScheme(schemeItemId, folderId)"
                />
              </q-card-actions>
            </q-card>
          </q-dialog>
          <q-dialog
            class="delete-folder"
            v-model="confirmDeleteFolder"
            persistent
          >
            <q-card class="delete-prompt">
              <q-card-section class="row items-center">
                <span class="q-ml-sm">
                  {{ t('saved_and_hidden_items.delete_folder_confirmation') }}</span
                >
              </q-card-section>

              <q-card-actions align="right">
                <q-btn
                  class="orange-btn bg-gray-button"
                  no-caps
                  :label="t('common.cancel')"
                  v-close-popup
                  flat
                />
                <q-btn
                  class="orange-btn-filled"
                  :label="t('saved_and_hidden_items.to_delete')"
                  no-caps
                  v-close-popup
                  @click="removeFolder(pickedFolderId)"
                  flat
                />
              </q-card-actions>
            </q-card>
          </q-dialog>
          <q-dialog class="add-new-map" v-model="showAddNew" persistent>
            <q-card class="q-px-xl">
              <q-card-section class="q-pa-none q-mb-md">
                <div class="new-map-prompt q-mt-md">{{ t('save_scheme.create_new_folder')}}</div>
              </q-card-section>
              <q-card-section class="q-pa-none">
                <div class="row justify-around q-ml-lg">
                  <q-input
                    borderless
                    v-model="newFolderName"
                    stack-label
                    :placeholder="t('save_scheme.create_new_folder')"
                    :rules="requiredFieldRule"
                    style="width: 100%; margin-left: -19px"
                    dense
                  />
                </div>
              </q-card-section>

              <q-card-actions class="q-mb-md" style="margin-left: 30px">
                <q-btn
                  :label="t('search_schemes.save')"
                  @click="addNewFolder()"
                  class="orange-btn-filled bold-font"
                  no-caps
                  flat
                  style="width: 100px"
                />
                <q-btn
                  :label="t('common.cancel')"
                  flat
                  no-caps
                  @click="disableAddNew()"
                  class="orange-btn bold-font bg-gray-button"
                  style="width: 100px"
                />
              </q-card-actions>
            </q-card>
          </q-dialog>
        </template>
      </q-splitter>

      <q-layout class="showOnMobile">
        <q-drawer v-model="leftDrawerOpen" side="left">
          <span class="row mijnMappen">{{ t('saved_and_hidden_items.my_folders') }}</span>
          <div class="row">
            <!--  :style="{zIndex: leftDrawerOpen ? 1  : 'none' }"  -->
            <div style="min-height: 15vh" class="new-folder-container">
              <q-btn no-caps @click="enableAddNew()" class="q-mb-md newFolder">
                <q-icon name="create_new_folder"></q-icon>
                <!-- treba biti outline dodati s ajdinom-->
                <span style="font-weight: bold"
                  >&nbsp;&nbsp;&nbsp; {{ t('saved_and_hidden_items.new_folder') }}</span
                >
              </q-btn>
              <div class="q-pl-md folders-list">
                <div
                  class="q-mt-none row q-gutter-sm text-left folders-btns"
                  v-for="folder in foldersWithItems"
                  :key="folder.id"
                >
                  <div class="row q-ma-none list">
                    <a
                      class="selected-folder row"
                      v-if="folder.id == selectedFolderId"
                      @click="getItems(folder.id, folder.name)"
                    >
                      <q-icon
                        name="fa-solid fa-folder-open"
                        size="15px"
                      ></q-icon>

                      <div class="q-ma-none q-ml-xs overflow-folder-name">
                        {{ folder.name }}
                      </div>
                      <q-tooltip>{{folder.name}}</q-tooltip>
                    </a>
                    <a
                      v-else
                      class="non-selected-folder row"
                      @click="getItems(folder.id, folder.name)"
                    >
                      <q-icon
                        name="fa-solid fa-folder-closed"
                        size="15px"
                      ></q-icon>

                      <div class="q-ma-none q-ml-xs overflow-folder-name">
                        {{ folder.name }}
                      </div>
                      <q-tooltip>{{folder.name}}</q-tooltip>
                    </a>
                    <div class="float-right edit-map-prompt">
                      <q-icon
                        name="fa-solid fa-pen-to-square"
                        size="14px"
                        style="
                          color: #0a108f;
                          margin-top: -3px;
                          margin-right: 8px;
                        "
                        @click="enableRenameFolder(folder.id, folder.name)"
                      >
                        <q-tooltip> {{ t('saved_and_hidden_items.rename_folder') }} </q-tooltip>
                      </q-icon>
                      <q-icon
                        name="fa-solid fa-trash"
                        size="14px"
                        style="color: #0a108f; margin-bottom: 5px"
                        @click="
                          (confirmDeleteFolder = true),
                            (pickedFolderId = folder.id)
                        "
                      >
                        <q-tooltip> {{ t('saved_and_hidden_items.delete_folder') }} </q-tooltip>
                      </q-icon>
                    </div>
                  </div>
                </div>
                <!--             </q-scroll-area>-->
              </div>
            </div>
          </div>
          <div
            class="absolute drawer-close-container"
            style="top: 50%; right: -34px; z-index: 200"
          >
            <q-btn
              dense
              class="drawer-close-btn"
              round
              unelevated
              color="accent"
              icon="close"
              @click="() => (leftDrawerOpen = false)"
            ></q-btn>
          </div>

          <!-- drawer content -->
        </q-drawer>
      </q-layout>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import store from "../../store/index.js";
import UserService from "../../services/userprofile.service";
import GrantsService from "../../services/grants.service.js";
import SchemeUtils from "../../utils/schemes";
import FolderReport from "../share-components/FolderReport.vue";
import { useI18n } from 'vue-i18n';

export default {
  components: {
    FolderReport,
  },
  name: "SavedItemsSearches",
  setup() {

    const { t, locale } = useI18n();
    const savedSearchesColumns = ref([
      {
        name: "title",
        align: "left",
        label: computed(() => t('share_and_download_schemes.name')),
        field: "title",
        sortable: true,
      },
      {
        name: "alert",
        label: computed(() => t('saved_and_hidden_items.alert')),
        align: "center",
      },
      { name: "applyFilters", align: "center" },
    ]);

    const savedItemsTab = ref("schemes");
    const selectedForSharingScheme = ref([]);
    const show = ref(false);
    const leftDrawerOpen = ref(false);
    const filterSchemes = ref("");
    const filter = ref("");
    const loading = ref(false);
    const confirm = ref(false);
    const confirmSchemeDelete = ref(false);
    const confirmDeleteFolder = ref(false);
    const searchId = ref(null);
     const foldersWithItems = computed({
      get: () => store.state.user.foldersList,
    });

    const folderId = ref(null);
    const selectedFolderId = ref(null);
    var folderName = ref("");
    var newFolderName = ref("");

    const firstFolderId = computed({
      get: () => store.state.user.firstFolderId,
    });

    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });

    onMounted(async () => {
      await UserService.getFoldersWithItems();
      const firstFolderName = await UserService.getFirstFolderName(firstFolderId.value);
      getItems(firstFolderId.value, firstFolderName.data);
      portalLabelUpdates();
    });


    const folderSchemesItems = computed({
      get: () => store.state.user.folderSchemes,
    });


    const filteredArraySearches = computed({
      get: () => store.state.user.filteredArraySearches,
    });

    const folderSearchesItems = computed({
      get: () => store.state.user.folderSearches,
    });

    const getItems = (folderId, name) => {
      selectedFolderId.value = folderId;
      folderName.value = name;
      UserService.getFolderItems(folderId);
    };

    const labelRemoveFavoriteItem = ref("");
    const deletePromptLabel = ref("");
    const labelDontRemoveFavoriteItem = ref("");
    const labelSavedItems = ref("");
    const showWarning = ref(false);
    const shareForMobile = ref(false);

    const portalLabelUpdates = () => {
      if (isVind.value) {
        labelRemoveFavoriteItem.value = t('saved_and_hidden_items.vind_remove_item_from_saved_items_confirmation');
        deletePromptLabel.value =  t('saved_and_hidden_items.to_delete');
        labelDontRemoveFavoriteItem.value = t('common.cancel');
        labelSavedItems.value = t('search_schemes.saved_items');
      } else {
        labelRemoveFavoriteItem.value =
          "Deze regeling verwijderen uit opgeslagen items?";
        deletePromptLabel.value = t('search_schemes.yes');
        labelDontRemoveFavoriteItem.value = t('search_schemes.no');
        labelSavedItems.value = "Favorieten";
      }
    };

    const applyFilter = async (searchId) => {
      store.commit("advancedSearch/updateapplySavedSearchPause", true);
      UserService.loadFilter(searchId);
      UserService.setApplyFilter(false);
      await SchemeUtils.searchSchemes(locale);
      store.commit("advancedSearch/updateapplySavedSearchPause", false);
    };


    const removeSavedSearch = async (searchId, folderId) => {
      await GrantsService.removeSavedSearch(searchId, folderId);
      await UserService.getFolderItems(folderId);
    };

    const updateAlert = async (itemId, folderId, value) => {
      await UserService.updateFolderItemAlert(itemId, value);
      await UserService.getFolderItems(folderId);
    };

    const updateAlertSearch = async () => {
      console.log("search update alert");
    };

    const enableAddNew = () => {
      store.commit("user/enableAddNew");
    };

    const custid = computed({
      get: () => store.state.auth.custid,
    });

    const showAddNew = computed({
      get: () => store.state.user.showAddNew,
    });

    const addNewFolder = async () => {
      await UserService.addNewFolder(newFolderName.value, locale);
      store.commit("user/disableAddNew");
      UserService.getFoldersWithItems();
      newFolderName.value = "";
    };

    const disableAddNew = () => {
      store.commit("user/disableAddNew");
      newFolderName.value = "";
    };
    const initialPagination = {
      sortBy: "desc",
      descending: false,
      page: 1,
      rowsPerPage: 10,
    };

    const foldersLength = computed({
      get: () => store.state.user.foldersList.length,
    });

    const generateFolderReportPopup = (id) => {
      var folders = foldersWithItems.value;
      var folderName = "";
      for (var i = 0; i < folders.length; i++) {
        if (folders[i].id == id) {
          folderName = folders[i].name;
        }
      }
      var selectedFolder = {
        id: id,
        name: folderName,
      };
      store.commit("grantItems/enableDownloadFolderReport", selectedFolder);
    };

    // the name of the folder to be modified
    const pickedFolderName = computed({
      get: () => store.state.user.pickedFolderName,
      set: (value) => store.commit("user/updatePickedFolderName", value),
    });

    //the id of the folder to be modified
    const pickedFolderId = ref(null);

    const showRenameFolderDialog = computed({
      get: () => store.state.user.showRenameFolderDialog,
    });

    const enableRenameFolder = (folderId, folderName) => {
      pickedFolderId.value = folderId;
      store.commit("user/enableRenameFolderDialog", folderName);
    };

    const disableRenameFolder = () => {
      store.commit("user/disableRenameFolderDialog");
    };

    const renameFolder = async () => {
      await UserService.renameFolder(pickedFolderName, pickedFolderId, locale);
      store.commit("user/disableRenameFolderDialog");
      UserService.getFoldersWithItems();
      folderName.value = pickedFolderName.value;
    };

    const removeFolder = async (folderId) => {
      await UserService.removeFolder(folderId);
      UserService.getFoldersWithItems();
    };

    return {
      t,
      locale,
      shareForMobile,
      selectedForSharingScheme,
      filteredArraySearches,
      portalLabelUpdates,
      show,
      isVind,
      deletePromptLabel,
      labelRemoveFavoriteItem,
      labelSavedItems,
      labelDontRemoveFavoriteItem,
      removeFolder,
      confirmDeleteFolder,
      renameFolder,
      disableRenameFolder,
      showRenameFolderDialog,
      pickedFolderId,
      pickedFolderName,
      enableRenameFolder,
      firstFolderId,
      foldersLength,
      initialPagination,
      splitterModel: ref(15),
      savedItemsTab,
      savedSearchesColumns,
      foldersWithItems,
      searchId,
      folderName,
      folderId,
      getItems,
      folderSchemesItems,
      folderSearchesItems,
      filterSchemes,
      filter,
      loading,
      confirm,
      applyFilter,
      showWarning,
      removeSavedSearch,
      selectedFolderId,
      confirmSchemeDelete,
      updateAlert,
      updateAlertSearch,
      enableAddNew,
      showAddNew,
      newFolderName,
      disableAddNew,
      addNewFolder,
      generateFolderReportPopup,
      custid,
      leftDrawerOpen,

      barStyle: {
        right: "2px",
        borderRadius: "9px",
        backgroundColor: "#f0f2f5",
        width: "8px",
        opacity: 0.2,
      },
      thumbStyle: {
        right: "4px",
        borderRadius: "7px",
        width: "4px",
        opacity: 0.75,
      },

      requiredFieldRule: [
        (val) => (val && val.length > 0) || t('common.required_field'),
      ],
      toggleLeftDrawer() {
        leftDrawerOpen.value = !leftDrawerOpen.value;
      },

      searchesFilter(rows, terms) {
        // rows contain the entire data
        // terms contains whatever you have as filter
        const lowerFilter = terms ? terms.toLowerCase() : "";
        if (lowerFilter != "") {
          var filtered = rows.filter(
            (row) =>
              row.savedSearch.title.toLowerCase().indexOf(lowerFilter) > -1
          );
          store.commit("user/updateFilteredArraySearches", filtered);
          return filtered;
        } else {
          store.commit("user/updateFilteredArraySearches", rows);
          return rows;
        }
      },
    };
  },
};
</script>

