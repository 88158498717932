<template>
  <div class="calls">
    <div class="call-card">
      <div class="grant-info">
        <div class="main-info-top">
          <div class="row justify-between title-main">
            <div class="container-title-checkbox">
              <router-link
                style="text-decoration: none"
                :to="{ name: 'GrantDetails', params: { grantId: id } }"
                v-bind:target="openDetailsInNewTab ? '_blank' : null"
              >
                <div class="grant-text">
                  <div class="container-title-checkbox bold-font">
                    <span v-html="title"></span>
                  </div>
                </div>
              </router-link>
              <div class="radiobutton-section row">
                <SelectButton :id="schemeId" :allSelected="selectedResults" />
              </div>
            </div>
          </div>
          <span
            v-if="grant.identifier && grant.identifier.length > 0"
            class="organisation-text row"
            >{{ grant.identifier }}</span
          >
        </div>

        <div class="other-details">
          <div class="text-details row">
            <div class="detail-item">
              <span class="detail-tag">Status</span>

              <span class="detail-value bold-font">{{ statusNL }}</span>
            </div>

            <div class="detail-item row">
              <span class="detail-tag">
                {{ grant.currentDeadlineHeaderText }}</span
              >
              <span class="detail-value bold-font">{{
                grant.currentDeadlineText
              }}</span>
            </div>
          </div>
          <div class="action-icons">
            <q-icon
              class="action-icon"
              name="save"
              size="18px"
              @click="enableSaveScheme(schemeId)"
            >
              <q-tooltip> {{ t('search_schemes.save') }} </q-tooltip></q-icon
            >
            <q-icon
              class="action-icon"
              name="visibility_off"
              size="18px"
              @click="hideScheme(schemeId)"
            >
              <q-tooltip> Verbergen </q-tooltip></q-icon
            >
            <q-icon
              class="action-icon"
              name="share"
              size="18px"
              @click="shareGrant()"
            >
              <q-tooltip> {{ t('search_schemes.share') }} </q-tooltip></q-icon
            >
          </div>
        </div>

        <!--   <div class="bottom-options">
          <div class="radiobutton-section row">
            <SelectButton :id="schemeId" :allSelected="selectedResults" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import store from '../store/index';
import GrantsService from '../services/grants.service';
import SelectButton from '../components/GrantSelectButton.vue';
import SchemeUtils from '../utils/schemes';
import UserService from '../services/userprofile.service';
import { useI18n } from 'vue-i18n';

export default {
  name: 'GrantCard',
  props: {
    title: String,
    introduction: String,
    body: String,
    organization: String,
    id: String,
    grant: Object,
  },
  components: {
    SelectButton,
  },

  setup(props) {
    const schemeId = ref(props.id);
    const isSelected = ref(false);
    const { locale } = useI18n();
    
    onMounted(() => {});

    const toggleDisplayCalls = () => (displayCalls.value = !displayCalls.value);

    const selectedResults = computed({
      get: () => store.state.grantItems.selectedResults,
    });

    const displayCalls = ref(false);

    const enableSaveScheme = (id) => {
      store.commit('grantItems/addSelected', id);
      UserService.getFolders();
      store.commit('user/enableSaveScheme');
    };

    const shareGrant = () => {
      store.commit('grantItems/enableShareGrant', props.grant);
    };

    const hideScheme = async (schemeId) => {
      await GrantsService.hideScheme(schemeId, locale);
      SchemeUtils.searchSchemes(locale);
    };

    const openDetailsInNewTab = computed({
      get: () => store.state.user.openDetailsInNewTab,
    });

    const statusNL = SchemeUtils.translateStatus(props.grant.status);

    return {
      locale,
      statusNL,
      shareGrant,
      selectedResults,
      toggleDisplayCalls,
      displayCalls,
      schemeId,
      isSelected,
      enableSaveScheme,
      hideScheme,
      openDetailsInNewTab,
    };
  },
};
</script>
