<template>
  <div class="portal-search-bar">
    <div class="left-row lg-screen-searchbar">
      <q-btn
        dense
        class="filters-btn bold-font"
        color="white"
        flat
        no-caps
        style="height: 36px"
        @click="enableDrawerLeft"
        id="enableDrawerBtn"
      >
        <q-icon size="14px" class="chevron-icon">
          <img
            class="white-icon q-mr-sm"
            src="../../assets/icons/filter-list-solid.svg"
          />
        </q-icon>
        Filter resultaten</q-btn
      >

      <div class="row">
        <q-input
          dense
          borderless
          v-model="searchText"
          placeholder="Zoeken op trefwoord(en)"
          class="search-input"
          @keydown.enter="searchGrants()"
          style="height: 36px"
        >
          <template v-slot:append>
            <q-icon
              name="search"
              class="search-icon"
              style="margin-right: 12px"
            />
          </template>
        </q-input>

        <q-btn
          dense
          no-caps
          class="search-btn bold-font"
          color="white"
          flat
          @click="searchGrants()"
          style="height: 36px"
        >
          <q-icon
            left="true"
            name="search"
            class="q-mr-sm"
            size="22px"
          ></q-icon>
          Zoeken</q-btn
        >
      </div>

      <div class="column sort-column">
        <span>{{ resultsCount }} resultaten</span>
        <q-select
          class="sort-select"
          dense
          v-model="selectedSort"
          input-debounce="0"
          :options="sortOptions"
          hide-dropdown-icon
          behavior="menu"
          bg-color="white"
          label="Sorteer op:"
          style="font-size: 10px"
        />
      </div>
    </div>

    <div class="left-row-sm sm-screen-searchbar">
      <div class="column">
        <div class="row sm-screen-input">
          <q-input
            dense
            borderless
            v-model="searchText"
            placeholder="Zoeken op trefwoord(en)"
            class="search-input"
            @keydown.enter="searchGrants()"
            style="height: 36px"
          >
            <template v-slot:append>
              <q-icon
                name="search"
                class="search-icon"
                style="margin-right: 12px"
              />
            </template>
          </q-input>
        </div>

        <div class="row btns-row justify-between">
          <q-btn
            dense
            class="filters-btn bold-font"
            color="white"
            flat
            no-caps
            style="height: 36px"
            @click="enableDrawerLeft"
          >
            <q-icon size="14px" class="chevron-icon">
              <img
                class="white-icon q-mr-sm"
                src="../../assets/icons/filter-list-solid.svg"
              />
            </q-icon>
            Filter resultaten</q-btn
          >

          <q-btn
            dense
            no-caps
            class="search-btn bold-font"
            color="white"
            flat
            @click="searchGrants()"
            style="height: 36px"
          >
            <q-icon
              left="true"
              name="search"
              class="q-mr-sm"
              size="22px"
            ></q-icon>
            Zoeken</q-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store/index';
import { computed, watch } from 'vue';
import GrantsService from '../../services/grants.service';
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t, locale } = useI18n();
    const searchText = computed({
      get: () =>
        store.state.advancedSearch.searchText != ''
          ? store.state.advancedSearch.searchText
          : sessionStorage.getItem('searchText'),
      set: (val) => {
        store.commit('advancedSearch/updateSearchText', val);
        store.commit("advancedSearch/updateSelectedSort", t('search_schemes.relevance'))
        store.commit('newsSearch/updateSearchText', val);
        store.commit("newsSearch/updateSelectedSortNews", t('search_news.newest'));
      },
    });

    const complexityRating = computed({
      get: () => store.state.advancedSearch.difficultiesString,
    });

    const timeForApplicationRating = computed({
      get: () => store.state.advancedSearch.applicationTimeString,
    });

    const chanceOfSuccessRating = computed({
      get: () => store.state.advancedSearch.chancesString,
    });

    watch(
      () => searchText.value,
      async (next, prev) => {
        if (prev && prev.length > 0 && next.length == 0) {
          await GrantsService.getSearchResults(
            locale,
            searchText,
            statusBoxes,
            audiences,
            locations,
            //This is just hidden for now, don't remove it
            //industries,
            purposes,
            schemeTypes,
            projectTypes,
            smeString,
            applicationTermsString,
            minBudget,
            maxBudget,
            scopesString,
            orgsIncludeString,
            orgsExcludeString,
            startDate,
            endDate,
            selectedSort,
            complexityRating,
            chanceOfSuccessRating,
            timeForApplicationRating
          );
        }
      }
    );

    const sortOptions = ['Datum', 'Relevantie', 'Scope'];

    const statusBoxes = computed({
      get: () => sessionStorage.getItem('statusesString'),
    });

    const audiences = computed({
      get: () =>
        store.state.advancedSearch.selectedAudiences != ''
          ? store.state.advancedSearch.selectedAudiences
          : sessionStorage.getItem('selectedAudiences'),
    });
    const locations = computed({
      get: () =>
        store.state.advancedSearch.selectedLocations != ''
          ? store.state.advancedSearch.selectedLocations
          : sessionStorage.getItem('selectedLocations'),
    });
    const purposes = computed({
      get: () =>
        store.state.advancedSearch.selectedPurposes != ''
          ? store.state.advancedSearch.selectedPurposes
          : sessionStorage.getItem('selectedPurposes'),
    });
    const schemeTypes = computed({
      get: () =>
        store.state.advancedSearch.selectedSchemeTypes != ''
          ? store.state.advancedSearch.selectedSchemeTypes
          : sessionStorage.getItem('selectedSchemeTypes'),
    });
    const projectTypes = computed({
      get: () => store.state.advancedSearch.selectedProjectTypes,
    });
    const smeString = computed({
      get: () => store.state.advancedFilters.smeString,
    });
    const applicationTermsString = computed({
      get: () => store.state.advancedFilters.termsString,
    });

    const minBudget = computed({
      get: () =>
        store.state.advancedSearch.minBudget != null
          ? store.state.advancedSearch.minBudget
          : Number(sessionStorage.getItem('minBudget')),
    });

    const maxBudget = computed({
      get: () =>
        store.state.advancedSearch.maxBudget != null
          ? store.state.advancedSearch.maxBudget
          : Number(sessionStorage.getItem('maxBudget')),
    });

    const scopesString = computed({
      get: () => store.state.advancedFilters.scopesString,
    });
    const orgsIncludeString = computed({
      get: () =>
        store.state.advancedSearch.organisationsIncludeString != ''
          ? store.state.advancedSearch.organisationsIncludeString
          : sessionStorage.getItem('organisationsIncludeString'),
    });

    const orgsExcludeString = computed({
      get: () =>
        store.state.advancedSearch.organisationsExcludeString != ''
          ? store.state.advancedSearch.organisationsExcludeString
          : sessionStorage.getItem('organisationsExcludeString'),
    });

    const startDate = computed({
      get: () =>
        store.state.advancedSearch.startDate != null &&
        store.state.advancedSearch.startDate != ''
          ? store.state.advancedSearch.startDate
          : sessionStorage.getItem('startDate'),
    });

    const endDate = computed({
      get: () =>
        store.state.advancedSearch.endDate != null &&
        store.state.advancedSearch.endDate != ''
          ? store.state.advancedSearch.endDate
          : sessionStorage.getItem('endDate'),
    });

    const selectedSort = computed({
      get: () => store.state.advancedSearch.selectedSort,
      set: async (value) => {
        store.commit('advancedSearch/updateSelectedSort', value);
        searchGrants();
      },
    });

    const searchGrants = async () => {
      store.commit('advancedFilters/createTermsString');
      store.commit('advancedFilters/createSMEString');
      store.commit('advancedFilters/createScopesString');
      store.commit('advancedSearch/createOrganisationsIncludeString');
      store.commit('advancedSearch/createOrganisationsExcludeString');
      await GrantsService.getSearchResults(
        locale,
        searchText,
        statusBoxes,
        audiences,
        locations,
        //This is just hidden for now, don't remove it
        //industries,
        purposes,
        schemeTypes,
        projectTypes,
        smeString,
        applicationTermsString,
        minBudget,
        maxBudget,
        scopesString,
        orgsIncludeString,
        orgsExcludeString,
        startDate,
        endDate,
        selectedSort,
        complexityRating,
        chanceOfSuccessRating,
        timeForApplicationRating
      );

      //  await NewsUtils.searchNewsWithGrantsFilter();
    };

    const enableDrawerLeft = () => {
      store.commit('advancedSearch/updatePortalDrawerLeft', true);
    };

    const resultsCount = computed({
      get: () => store.state.advancedSearch.numberOfResults,
    });

    return {
      t,
      locale,
      enableDrawerLeft,
      searchText,
      searchGrants,
      resultsCount,
      sortOptions,
      selectedSort,
    };
  },
};
</script>
