<template>
  <div class="deadlines-page homepage-main">
    <ShareScheme />
    <SaveScheme />
    <DownloadScheme />

    <div class="grant-cards-container grants-container">
      <div class="pagination top-pag row justify-between">
        <span class="newest-heading">
          Gewijzigde regelingen
        </span>
        <q-pagination
          class="pagination-item"
          v-model="currentPage"
          color="black"
          outline
          direction-links
          :max="maxPages"
          :max-pages="6"
          boundary-numbers
          @click="goToPage(currentPage)"
        ></q-pagination>
      </div>
      <div class="cards">
        <GrantCard
          v-for="grant in grants"
          :key="grant.scheme.id"
          :title="grant.scheme.name"
          :grant="grant.scheme"
          :id="grant.scheme.id"
          :introduction="grant.scheme.introduction"
          :isFromHomepage="true"
          class="card q-mt-md"
        />
      </div>
      <div class="pagination top-pag row justify-end bot-pag">
        <q-pagination
          class="pagination-item"
          v-model="currentPage"
          color="black"
          outline
          direction-links
          :max="maxPages"
          :max-pages="6"
          boundary-numbers
          @click="goToPage(currentPage)"
        ></q-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import SchemesUtils from '../../utils/schemes';
import store from '../../store/index';
import GrantCard from '../GrantCard.vue';
import ShareScheme from '../share-components/ShareScheme.vue';
import DownloadScheme from '../share-components/DownloadScheme.vue';
import SaveScheme from '../SaveScheme.vue';
import UserService from '../../services/userprofile.service';
import { useI18n } from "vue-i18n";
export default {
  name: 'NewestSchemes',
  components: {
    GrantCard,
    ShareScheme,
    SaveScheme,
    DownloadScheme,
  },
  setup() {
    const { locale } = useI18n();

    const grants = computed({
      get: () => store.state.homePageItems.significantSchemes,
    });

    const currentPage = computed({
      get: () => store.state.homePageItems.currentPageSignificant,
      set: (val) =>
        store.commit('homePageItems/updateCurrentPageSignificant', val),
    });

    const maxPages = computed({
      get: () => store.state.homePageItems.pageCountSignificant,
    });

    const goToPage = async (page) => {
      await SchemesUtils.searchSignificantSchemes(page - 1, 10, locale);

      window.scrollTo(0, 0);
    };

    onMounted(async () => {
      await UserService.getProfileConfiguration();
      await UserService.getFavoriteAudienceTags();
      await UserService.getFavoritePurposesTags();
      await UserService.getFavoriteLocationsTags();

      window.scrollTo(0, 0);
      checkForTagsUpdate();
      await SchemesUtils.searchSignificantSchemes(0, 10, locale);
    });

    const favoriteAudience = computed({
      get: () => store.state.user.favoriteAudiencesIdsArray,
    });
    const favoritePurposes = computed({
      get: () => store.state.user.favoritePurposesIdsArray,
    });
    const favoriteLocations = computed({
      get: () => store.state.user.selectedFavoriteLocations,
    });

    const hasProfileTags = ref(false);

    const checkForTagsUpdate = () => {
      if (
        favoriteAudience.value.length > 0 ||
        favoritePurposes.value.length > 0 ||
        favoriteLocations.value.length > 0
      ) {
        hasProfileTags.value = true;
      }
    };

    return {
      locale,
      grants,
      currentPage,
      maxPages,
      goToPage,
      favoriteAudience,
      favoritePurposes,
      favoriteLocations,
      checkForTagsUpdate,
    };
  },
};
</script>
