import axios from 'axios';
import axiosUrl from './axios.service';
import store from '../store/index';
import moment from 'moment';
/* import router from '../router'; */
import { computed } from 'vue';
import authHeader from '../helpers/auth-header';

const API_URL = axiosUrl.getAxiosUrl();
const isVind = computed({
  get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
});

class AuthService {
  login(user, locale) {
    if (
      user.email == null ||
      user.email == '' ||
      user.password == null ||
      user.password == ''
    ) {
      store.commit(
        'auth/updateModalError',
        'Gebruikersnaam en wachtwoord zijn verplicht'
      );
      store.commit('auth/enableModalError');
      return false;
    }

    return axios
      .post(API_URL + '/users/authenticate', {
        email: user.email,
        password: user.password,
      },
      {
        params: {
          culture: locale.value
        }
      })
      .then((response) => {
        if (response.data.token) {
          if (location.hostname.split('.').reverse()[1] != null) {
            document.cookie = "testCookie=" + response.data.token + "; domain=" +
              location.hostname.split('.').reverse()[1] + "." +
              location.hostname.split('.').reverse()[0] + "; path=/";
          }
          else {
            document.cookie = "testCookie=" + response.data.token + "; domain=localhost";
          }

          localStorage.setItem('user', JSON.stringify(response.data));
          store.commit('user/setUserRolesList', response.data);
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
    store.commit('advancedSearch/resetSearchFilter', false);
    store.commit('user/setUserRolesList', []);
    sessionStorage.clear();
  }

  loginMsOidc() {
    window.location.href = API_URL + '/users/authorize-oidc';
  }

  getEnvironment() {
    return axios.get(API_URL + '/users/getEnvironment').then((response) => {
      store.commit('auth/setEnvironment', response.data);
      if (localStorage.getItem('user') == null && (response.data == 50 || response.data == 60)) {
        axios.post(API_URL + '/users/authenticateNoLogon').then((res) => {
          if (res.data.token) {
            localStorage.setItem('user', JSON.stringify(res.data));
            window.location.href = 'search-grants';
          }
        });
      } else {
        document.getElementById('login-page').style['display'] = '';
      }
    });
  }

  async getLanguageCode() {
    return await axios
      .get(API_URL + '/users/getLanguageCode')
      .then((response) => response.data);
  }

  async getPortalTypes() {
    return await axios
      .get(API_URL + '/users/getPortalTypes')
      .then((response) => {
        store.commit('auth/setPortalThemeTypes', response.data);
      });
  }

  async getSearchInterests() {
    return await axios
      .get(API_URL + '/users/getSearchInterests')
      .then((response) => {
        store.commit('auth/setSearchInterests', response.data);
      });
  }
  async getProfessionTypes() {
    return await axios
      .get(API_URL + '/users/getProfessionTypes')
      .then((response) => {
        store.commit('auth/setProfessionTypes', response.data);
      });
  }

  async getPortalInfo() {
    return await axios
      .get(API_URL + '/users/getPortalInfo')
      .then((response) => {
        var now = moment().format('YYYY-MM-DD');
        var endDate =
          response.data.endDate != null
            ? moment(response.data.endDate).format('YYYY-MM-DD')
            : moment('2100-01-01').format('YYYY-MM-DD');

        if (!isVind.value && !moment(endDate).isSameOrAfter(now)) {
          if (response.data.environmentType == 'ISV') {
            window.location.href =
              'https://www.vindsubsidies.nl/subsidieloket-voor-inwoners-is-niet-beschikbaar';
          } else {
            window.location.href =
              'https://www.vindsubsidies.nl/subsidieloket-voor-bedrijven-is-niet-beschikbaar';
          }
        }

        store.commit('auth/fillPortalCustomer', response.data);
      });
  }

  async getAppropriateLanguageBasedBaseUrl(userId) {
    return await axios
      .get(API_URL + '/users/getAppropriateLanguageBasedBaseUrl', {
        headers: authHeader(),
        params: {
          UserId: userId
        }
      })
      .then((response) => {
        if (response.data != '') {
          this.logout();
          window.location.href = response.data;
        }
      });
  }

  async register(user) {
    return await axios.post(API_URL + '/users/register', {
      firstName: user.firstName,
      lastName: user.lastName,
      portalOrganisation: user.organisation,
      email: user.email,
      professionType: user.professionType,
    });
  }
}

export default new AuthService();
