<template>
        <div style="height: 62vh;" class="new-password-page" v-if="!isLinkValid">
        <h1 style="padding-top: 77px;">{{ t('common.invalid_link') }}</h1>
      </div>
  <div class="new-password-page" v-if="!isSuccess && isLinkValid">
    <ErrorModal />
    <h3 class="q-pt-lg">{{ t('new_password.enter_password') }}</h3>
    <div class="input-fields">
      <q-input
        dense
        outlined
        v-model="newPw1"
        :label="t('login.password')"
        class="search-input pw-reset-input q-mt-sm"
        bg-color="white"
        :rules="[complexRule]"
        :bottom-slots="true"
        type="password"
        ref="newPwInput1"
      />
      <q-input
        dense
        outlined
        v-model="newPw2"
        :label="t('new_password.confirm_password')"
        class="search-input pw-reset-input q-mt-sm"
        bg-color="white"
        :rules="[matchRule]"
        :bottom-slots="true"
        type="password"
        ref="newPwInput2"
      />
    </div>
    <div class="submit-btn-input row justify-end">
      <q-btn
        class="q-ml-md submit-btn"
        flat
        no-caps
        color="white"
        :label="t('search_schemes.save')"
        @click="submitChangePassword()"
      />
    </div>
  </div>
  <div class="new-password-page q-pb-xl" v-if="isSuccess">
    <h3 class="q-pt-xl">{{ t('new_password.password_sent') }}
      <router-link
        flat
        :to="{ name: 'Login' }"
        style="text-decoration: none"
      >
        {{ t('login.log_in') }}
      </router-link>.</h3>
    <div class="q-pa-xl"></div>
    <div class="q-pa-xl"></div>
    <div class="q-pa-xl"></div>
  </div>
</template>

<script>
import { ref, onMounted,computed } from 'vue';
import UserService from '../services/userprofile.service';
import { useRoute } from 'vue-router';
import ErrorModal from '../components/userProfile-components/ErrorModal.vue';
import store from '../store';
import { useI18n } from 'vue-i18n';

export default {  
  components: {
    ErrorModal,
  },
  setup() {
    const { t, locale } = useI18n();
    const route = useRoute();
    const newPwInput1 = ref(null);
    const newPwInput2 = ref(null);
    const newPw1 = ref('');
    const newPw2 = ref('');
    const token = ref('');
    const isSuccess = ref(false);
    onMounted(() => {
      token.value = route.params.token;
      UserService.checkIsValidLink(token);
    });

    const isLinkValid = computed({
      get: () => store.state.user.isLinkValid,
    })
    const submitChangePassword = async () => {
      var isInputValid = newPwInput1.value.validate();
      var isInputValid2 = newPwInput2.value.validate();
      if (isInputValid && isInputValid2) {
        var s = await UserService.setNewPassword(newPw1, newPw2, token, locale);
        if(s) {
          isSuccess.value = true;
        }
      }
    };
    return {
      t,
      locale,
      token,
      route,
      newPw1,
      newPw2,
      newPwInput1,
      newPwInput2,
      submitChangePassword,
      isLinkValid,
      isSuccess,
      matchRule() {
        return (newPw1.value === newPw2.value) || t('new_password.password_not_match');
      },
      complexRule() {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
        return pattern.test(newPw1.value) || t('new_password.password_rule');
      },
    };
  },
};
</script>
