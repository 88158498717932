<template>
  <DownloadScheme />
  <DownloadSchemeButton />
  <div class="spinner-page" v-if="areGrantDetailsLoading">
    <q-spinner class="spinner" color="primary" size="3em" />
  </div>
  <div v-else class="grant-details-page" :class="isContrast != 'false' && isContrast != false ? 'high-contrast' : ''">
    <div v-if="hasUser">
      <ShareGrant />
      <SaveScheme />
    </div>
    <div class="navbox-column nav-container-details side-bar">
      <nav class="navigation">
        <ul>
          <li>
            <a href="#introduction" v-if="scheme.introduction">{{ t('share_and_download_schemes.description') }}</a>
            <a href="#body" v-if="scheme.body">{{ t('scheme_details.body') }}</a>
            <a href="#criteria" v-if="scheme.criteria && isVind">{{ t('scheme_details.criteria') }}</a>
            <a href="#bijdrage" v-if="isVind && scheme.financialInformation">{{ t('scheme_details.contribution') }}</a>
            <a href="#customers-schemes" v-if="schemeCustomers.length > 0 && isUserFomo != false">{{ t('scheme_details.customers') }} </a>
            <a href="#docsandlinks" v-if="isVind && ((scheme.schemeLinks && scheme.schemeLinks.length > 0) || (scheme.schemeDocuments && scheme.schemeDocuments.length > 0)) ">
              {{ t('scheme_details.documents_and_links') }}
            </a>
            <a href="#news" v-if="scheme.news && scheme.news.length > 0">{{ t('search_news.news') }}</a>
            <a href="#orgs-list" v-if="organisationsList && organisationsList.length > 0">{{ t('scheme_details.organizations') }}</a>
            <a href="#calls" v-if="isVind &&
              scheme.children &&
              scheme.children.length > 0 &&
              !scheme.isCall &&
              !scheme.isApplicationPeriod"
            >
              {{ t('scheme_details.related_opening') }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <q-dialog v-model="showSavedInfoDialog">
      <q-card>
        <q-card-section class="row items-center">
          <q-icon class="info-icon-searchgrants" size="28px" name="fa-solid fa-circle-info"
            @click="hideScheme(schemeId)">
          </q-icon>
          <span class="q-ml-sm">
            {{ t('search_schemes.saved_item_notification') }} &nbsp; </span><a href="/profile/savedItems">{{
    t('search_schemes.saved_items') }}</a>
        </q-card-section>
        <q-card-section style="padding-top: 0; padding-left: 12px">
          <q-toggle v-model="receiveAlertForSavedItems"
            :label="t('search_schemes.receive_alerts_for_saved_items_question')" />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat class="orange-btn-filled" color="white" :label="t('common.ok')" no-caps
            @click="disableShowSavedInfoDialog()" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <div class="page-details">
      <div class="column pagetop">
        <q-icon name="fa-solid fa-arrow-left" size="18px" class="back-icon" @click="router.go(-1)"></q-icon>

        <div class="column">
          <div class="row heading-scheme">
            <div class="column scheme-name-and-org bold-font">
              <div>
                <span class="title" v-html="translateScheme ? transName : scheme.name"></span>
              </div>
            </div>
          </div>
          <div class="row justify-between" style="width: 65vw">
            <div class="row q-mt-sm icons-row" v-if="isVind">
              <q-icon name="fa-solid fa-floppy-disk" size="20px" class="action-icon"
                @click="enableSaveScheme(scheme.id)">
                <q-tooltip> {{ t('search_schemes.save') }} </q-tooltip></q-icon>
              <q-icon name="fa-solid fa-share-nodes" size="20px" class="action-icon q-ml-md" @click="shareGrant()">
                <q-tooltip> {{ t('search_schemes.share') }} </q-tooltip></q-icon>
              <q-icon name="fa-solid fa-download" size="20px" class="action-icon q-ml-md"
                @click="downloadSchemePopup(scheme.id)">
                <q-tooltip> {{ t('search_schemes.download') }} </q-tooltip></q-icon>

              <div v-if="scheme.isEnglishScheme" class="q-ml-md">
                <country-flag v-if="!translateScheme" country="gb" class="country-flag-icon" />
                <country-flag v-else country="nl" class="country-flag-icon" />
              </div>
              <q-toggle v-show="scheme.isEnglishScheme" v-model="translateScheme" @click="getTranslation(scheme.id)">
                <q-tooltip>
                  <p v-if="!translateScheme">
                    Vertaal deze regeling in het Nederlands
                  </p>
                  <p v-else>{{ t('scheme_details.translate_to_english') }}</p>
                </q-tooltip>
              </q-toggle>
            </div>

            <div class="bold-font">
              <q-btn color="white" class="orange-btn-filled" flat label="Houd me op de hoogte" no-caps
                @click="enableSaveScheme(scheme.id)" />
            </div>
          </div>
        </div>
      </div>
      <div class="column main-page q-mt-md">
        <div class="top-details-bg">
          <div class="topinfo-item">
            <span class="topinfo-item-heading bold-font">
              <q-icon size="20px" class="heading-icon">
                <img class="svg-icon" src="../assets/icons/calendar-days-solid.svg" />
              </q-icon>
              <span class="heading"> {{ t('scheme_details.deadline') }} </span></span>
            <span class="topinfo-item-value">{{ scheme.currentDeadlineText ?? '-' }}</span>
          </div>
          <div v-if="!scheme.isCall && !scheme.isApplicationPeriod" class="topinfo-item">
            <span class="topinfo-item-heading bold-font">
              <q-icon size="20px" class="heading-icon">
                <img class="svg-icon" src="../assets/icons/calendar-lines-pen-solid.svg" />
              </q-icon>
              <span class="heading"> {{ t('scheme_details.application_period') }} </span></span>
            <span class="topinfo-item-value">
              {{
                scheme.applicationTerm !== 'Onbepaald'
                  ? scheme.applicationTerm
                  : scheme.applicationTermText &&
                    scheme.applicationTermText !== ''
                    ? scheme.applicationTermText
                    : scheme.applicationTerm
              }}
            </span>
          </div>
          <div v-if="!scheme.isCall && !scheme.isApplicationPeriod" class="topinfo-item">
            <span class="topinfo-item-heading bold-font">
              <q-icon name="fa-solid fa-location-dot" size="20px" class="heading-icon" />
              <span class="heading"> {{ t('scheme_details.range') }} </span>
            </span>
            <span class="topinfo-item-value">{{ scheme.scope }}</span>
          </div>

          <div class="topinfo-item">
            <span class="topinfo-item-heading bold-font">
              <q-icon size="20px" class="heading-icon">
                <img class="svg-icon" src="../assets/icons/spinner-solid.svg" />
              </q-icon>
              <span class="heading"> {{ t('search_schemes.status') }} </span></span>
            <span class="topinfo-item-value">{{ statusNL ?? '-' }}</span>
          </div>

          <div v-if="!scheme.isCall && !scheme.isApplicationPeriod" class="topinfo-item">
            <span class="topinfo-item-heading bold-font">
              <q-icon size="20px" class="heading-icon">
                <img class="svg-icon" src="../assets/icons/memo-solid.svg" />
              </q-icon>
              <span class="heading"> {{ t('search_schemes.submission_procedure') }} </span>
            </span>
            <span v-if="!translateScheme" class="topinfo-item-value procedure">
              {{ scheme.applicationProcedure ?? '-' }}
            </span>
            <span v-else class="topinfo-item-value procedure">
              {{ transApplicationProcedure ?? '-' }}
            </span>
          </div>
          <div class="topinfo-item">
            <span class="topinfo-item-heading bold-font">
              <q-icon size="20px" class="heading-icon">
                <img class="svg-icon" src="../assets/icons/coin-solid.svg" />
              </q-icon>
              <span class="heading"> {{ t('scheme_details.min_amount') }} </span>
            </span>
            <span class="topinfo-item-value">
              <span v-if="!scheme.minAmount || scheme.minAmount < 1" class="detail-value">
                {{ t('search_schemes.indefinite') }}
              </span>
              <span v-if="scheme.minAmount && scheme.minAmount > 1">{{
    formattedMinAmount ?? '-'
  }}</span>
            </span>
          </div>
          <div class="topinfo-item">
            <span class="topinfo-item-heading bold-font">
              <q-icon size="20px" class="heading-icon">
                <img class="svg-icon" src="../assets/icons/coin-solid.svg" />
              </q-icon>
              <span class="heading"> {{ t('search_schemes.max_budget') }} </span>
            </span>
            <span class="topinfo-item-value">
              <span v-if="!scheme.maxAmount || scheme.maxAmount < 1" class="detail-value">{{
    t('search_schemes.indefinite') }}
              </span>
              <span v-if="scheme.maxAmount && scheme.maxAmount > 1">{{
    formattedMaxAmount ?? '-'
  }}</span>
            </span>
          </div>
          <div class="topinfo-item" v-if="isVind">
            <span class="topinfo-item-heading bold-font">
              <q-icon size="20px" class="heading-icon">
                <img class="svg-icon" src="../assets/icons/clock-solid.svg" />
              </q-icon>
              <span class="heading">{{ t('scheme_details.duration') }} </span>
            </span>
            <span class="topinfo-item-value budget">{{
    scheme.duration ?? '-'
  }}</span>
          </div>
          <div class="topinfo-item">
            <span class="topinfo-item-heading bold-font">
              <q-icon size="20px" class="heading-icon">
                <img class="svg-icon" src="../assets/icons/sack-solid.svg" />
              </q-icon>
              <span class="heading">{{ t('scheme_details.budget') }} </span></span>

            <div v-if="scheme.budget && !showMoreBudget && scheme.budget.length > 130
    " class="topinfo-item-value-budget budget" style="max-width: 320px">
              <div class="topinfo-item-value-budget budget" v-html="scheme.shortBudget"></div>
              <span v-if="!showMoreBudget" @click="toggleShowMoreBudget()" class="show-more">{{
    t('search_news.read_more') }}
                <q-icon size="14px" class="heading-icon">
                  <img class="svg-icon" src="../assets/icons/chevron-right-solid.svg" /> </q-icon></span>
            </div>

            <div v-else-if="scheme.budget && showMoreBudget" class="topinfo-item-value-budget budget"
              style="max-width: 320px">
              <div class="topinfo-item-value-budget budget" v-html="scheme.budget"></div>
              <span v-if="showMoreBudget" @click="toggleShowMoreBudget()" class="show-more">{{
    t('search_news.read_less') }}
                <q-icon size="14px" class="heading-icon">
                  <img class="svg-icon" src="../assets/icons/chevron-left-solid.svg" /> </q-icon></span>
            </div>
            <div v-else-if="scheme.budget && !showMoreBudget && scheme.budget.length < 130
    " class="topinfo-item-value-budget budget">
              <div class="topinfo-item-value-budget budget" v-html="scheme.budget"></div>
            </div>

            <div class="topinfo-item-value budget" v-else>-</div>
          </div>

          <div class="topinfo-item" v-if="scheme.complexityRating != null && scheme.complexityRating > 0
    ">
            <span class="topinfo-item-heading bold-font">
              <q-icon name="speed" size="20px" class="heading-icon" />{{ t('scheme_details.difficulty') }}</span>
            <div style="text-align: left" class="topinfo-item-value">
              <q-linear-progress size="15px" :value="(scheme.complexityRating / 10) * 2" class="progress-bar"
                :color="getRatingColor(scheme.complexityRating)" />

              <q-tooltip anchor="bottom middle" self="bottom middle" class="rating-tooltip"
                style="color: black; background-color: white; font-size: small">
                {{ getComplexityRatingLabel(scheme.complexityRating) }}
              </q-tooltip>
            </div>
          </div>

          <div class="topinfo-item" v-if="scheme.timeForApplicationRating != null &&
    scheme.timeForApplicationRating > 0
    ">
            <span class="topinfo-item-heading bold-font">
              <q-icon name="timer" size="20px" class="heading-icon" />{{ t('scheme_details.time_for_application')
              }}</span>
            <div style="text-align: left" class="topinfo-item-value">
              <q-linear-progress size="15px" :value="(scheme.timeForApplicationRating / 10) * 2" class="progress-bar"
                :color="getRatingColor(scheme.timeForApplicationRating)" />
              <q-tooltip anchor="bottom middle" self="bottom middle" class="rating-tooltip"
                style="color: black; background-color: white; font-size: small">
                {{
    getTimeForApplicationRatingLabel(
      scheme.timeForApplicationRating
    )
  }}
              </q-tooltip>
            </div>
          </div>
          <div class="topinfo-item" v-if="scheme.chanceOfSuccessRating != null &&
    scheme.chanceOfSuccessRating > 0
    ">
            <span class="topinfo-item-heading bold-font">
              <q-icon size="20px" class="heading-icon">
                <img class="svg-icon" src="../assets/icons/circle-check-solid.svg" />
              </q-icon>

              {{ t('scheme_details.chance_of_success') }}</span>
            <div style="text-align: left" class="topinfo-item-value">
              <q-linear-progress size="15px" :value="(scheme.chanceOfSuccessRating / 10) * 2" class="progress-bar"
                :color="getChanceOfSuccessRatingColor(scheme.chanceOfSuccessRating)
    " />
              <q-tooltip anchor="bottom middle" self="bottom middle" class="rating-tooltip"
                style="color: black; background-color: white; font-size: small">
                {{
    getChanceOfSuccessRatingLabel(scheme.chanceOfSuccessRating)
  }}
              </q-tooltip>
            </div>
          </div>
        </div>
        <div class="row page-lower">
          <div class="column">
            <section id="introduction" v-if="scheme.introduction">
              <div class="large-item first-lg">
                <span class="box-title bold-font"> {{ t('share_and_download_schemes.description') }}</span>

                <div class="introduction">
                  <div v-html="translateScheme ? transIntroduction : scheme.introduction
    "></div>
                </div>
              </div>
              <q-separator class="full-width-separator" v-if="scheme.introduction" />
            </section>

            <section id="body" v-if="scheme.body">
              <div class="large-item">
                <span class="box-title bold-font"> {{ t('scheme_details.body') }} </span>
                <div v-html="translateScheme ? transBody : scheme.body" class="introduction"></div>
                <div id="criteria"></div>
                <div class="introduction" v-if="scheme.applicationInformation" v-html="translateScheme
    ? transApplicationInformation
    : scheme.applicationInformation
    "></div>
              </div>
              <q-separator class="full-width-separator" v-if="scheme.body" />
            </section>

            <section id="criteria" v-if="scheme.criteria && isVind">
              <div class="large-item">
                <span class="box-title bold-font"> {{ t('scheme_details.criteria') }} </span>
                <div v-html="translateScheme ? transCriteria : scheme.criteria" class="introduction"></div>
              </div>
            </section>
            <q-separator class="full-width-separator" v-if="scheme.criteria" />

            <section id="bijdrage" v-if="scheme.financialInformation && isVind">
              <div class="large-item">
                <span class="box-title bold-font"> {{ t('scheme_details.contribution') }} </span>
                <div v-if="scheme.financialInformation" v-html="translateScheme
    ? transFinancialinformation
    : scheme.financialInformation
    " class="introduction"></div>
              </div>
            </section>
            <q-separator class="full-width-separator" v-if="isVind &&
    ((scheme.schemeLinks && scheme.schemeLinks.length > 0) ||
      (scheme.schemeDocuments && scheme.schemeDocuments.length > 0))
    " />
            <section id="docsandlinks" v-if="isVind &&
    ((scheme.schemeLinks && scheme.schemeLinks.length > 0) ||
      (scheme.schemeDocuments && scheme.schemeDocuments.length > 0))
    ">
              <div class="large-item lg-item-width">
                <span class="box-title bold-font"> {{ t('scheme_details.external_information') }} </span>
                <div class="scheme-links-sorted" v-if="sortedLinksAndDocs && sortedLinksAndDocs.length > 0">
                  <div v-for="link in sortedLinksAndDocs" :key="link.id" class="links-list-sorted">
                    <div v-if="link.url &&
    link.url.length > 0 &&
    link.documentType == 'Useful links'
    " class="sorted-link row">
                      <i class="fa-solid fa-link q-mt-xs" style="color: #191d96"></i>

                      <span class="doc-type q-ml-sm" v-if="link.documentType == 'Useful links'">
                        {{ t('scheme_details.useful_links') }}
                      </span>
                      <span class="doc-type q-ml-sm" v-else>
                        {{ link.documentType }}
                      </span>
                      <a class="link-item q-ml-md" :href="link.url" target="_blank">
                        {{ link.text }}
                      </a>
                    </div>
                  </div>
                </div>
                <span class="box-title bold-font q-mt-lg">
                  {{ t('scheme_details.other_links_and_files') }}
                </span>
                <div class="scheme-links-sorted" v-if="sortedLinksAndDocs && sortedLinksAndDocs.length > 0">
                  <div v-for="link in sortedLinksAndDocs" :key="link.id" class="links-list-sorted">
                    <div v-if="link.url &&
    link.url.length > 0 &&
    link.documentType != 'Useful links' &&
    link.documentType != 'Visual Ping links' &&
    !link.documentType.includes('Vervallen')
    " class="sorted-link row">
                      <i class="fa-solid fa-link q-mt-xs" style="color: #191d96"></i>
                      <span class="doc-type q-ml-sm">
                        {{ link.documentType }}
                      </span>

                      <a class="link-item q-ml-md" :href="link.url" target="_blank">
                        {{ link.text }}
                      </a>
                    </div>
                    <div v-if="!link.url &&
    link.documentType != 'Useful links' &&
    link.documentType != 'Visual Ping links' &&
    !link.documentType.includes('Vervallen')
    " class="sorted-doc row">
                      <i :class="iconBasedOnExtension(link.extension)" class="link-icon" style="color: #191d96"></i>
                      <span class="doc-type q-ml-sm">
                        {{ link.documentType }}
                      </span>

                      <div class="link-item docname q-ml-md" @click="download(link.id, link.name)">
                        {{ link.name }}
                      </div>
                    </div>
                  </div>
                </div>

                <q-btn @click="updateSeeDeprecatedItems(true)" v-if="!seeDeprecatedItems"
                  class="deprecated-btn row q-mt-sm" flat no-caps :label="t('scheme_details.see_deprecated')"><i
                    class="las la-angle-down q-ml-xs"></i></q-btn>

                <q-btn @click="updateSeeDeprecatedItems(false)" v-else class="deprecated-btn row q-mt-sm" flat no-caps
                  :label="t('scheme_details.hide_deprecated')">
                  <i class="las la-angle-up q-ml-xs"></i></q-btn>
                <div class="scheme-links-sorted" v-if="sortedLinksAndDocs &&
    sortedLinksAndDocs.length > 0 &&
    seeDeprecatedItems
    ">
                  <div v-for="link in sortedLinksAndDocs" :key="link.id" class="links-list-sorted">
                    <div v-if="link.url &&
    link.url.length > 0 &&
    link.documentType != 'Useful links' &&
    link.documentType != 'Visual Ping links' &&
    link.documentType.includes('Vervallen')
    " class="sorted-link row">
                      <i class="fa-solid fa-link q-mt-xs" style="color: #191d96"></i>
                      <span class="doc-type q-ml-sm">
                        {{ link.documentType }}
                      </span>

                      <a class="link-item q-ml-md" :href="link.url" target="_blank">
                        {{ link.text }}
                      </a>
                    </div>
                    <div v-if="!link.url &&
    link.documentType != 'Useful links' &&
    link.documentType != 'Visual Ping links' &&
    link.documentType.includes('Vervallen')
    " class="sorted-doc row">
                      <i :class="iconBasedOnExtension(link.extension)" class="link-icon"></i>
                      <span class="doc-type q-ml-sm">
                        {{ link.documentType }}
                      </span>

                      <div class="link-item docname q-ml-md" @click="download(link.id, link.name)">
                        {{ link.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <q-separator v-if="!isVind && organisationsList && organisationsList.length > 0
    " class="full-width-separator" />

            <section id="orgs-list" v-if="!isVind && organisationsList && organisationsList.length > 0
    ">
              <div class="large-item">
                <span class="box-title bold-font">{{ t('scheme_details.more_information') }}</span>

                <div class="scheme-news">
                  <span class="links-title">{{ t('scheme_details.organization') }}</span>

                  <div v-for="organisation in organisationsList" :key="organisation.id" class="news-list">
                    <div class="column">
                      <span class="organisation-name">
                        {{ organisation.name + ' (' + organisation.type + ')' }}
                      </span>
                      <div class="row">
                        <span v-if="organisation.addresses &&
    organisation.addresses.length > 0
    " class="organisation-address q-ml-md">{{ organisation.addresses[0].addressLine1
                          }}{{
    organisation.addresses[0].addressLine2 != null
      ? `, ${organisation.addresses[0].addressLine2}`
      : ''
  }}{{
      organisation.addresses[0].addressLine3 != null
        ? `, ${organisation.addresses[0].addressLine3}`
        : ''
    }}
                        </span>
                        <span v-if="organisation.phone" class="q-ml-sm org-info">
                          {{ organisation.phone }}
                        </span>
                        <a v-if="organisation.url" class="q-ml-sm org-info" :href="organisation.url">
                          {{ organisation.url }}
                        </a>
                        <span v-if="organisation.email" class="q-ml-sm org-info">
                          {{ organisation.email }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <q-separator class="full-width-separator" />

            <q-separator class="full-width-separator" v-if="scheme.news && scheme.news.length > 0" />

            <section id="news" v-if="scheme.news && scheme.news.length > 0">
              <div class="large-item">
                <span class="box-title bold-font">{{ t('search_news.news') }} </span>

                <div class="scheme-news">
                  <span class="links-title">{{ t('scheme_details.news_last_year') }}</span>

                  <div v-for="news in newsInLastYear" :key="news.id" class="news-list">
                    <div class="news-link-date">
                      <router-link class="news-link" style="text-decoration: underline" :to="{
    name: 'NewsDetails',
    params: { newsId: news.id },
  }" target="_blank">
                        {{ news.title }}
                      </router-link>
                      <span v-if="news.date &&
    news.date.length >= 10
    " class="news-date q-ml-md">
                        <!--   {{ news.date.substring(0, 10) }} -->
                        {{ formatDate(news.date) }}
                        <!--   {{ moment(news.date).format('YYYY-MM-DD') }} -->
                      </span>
                    </div>
                  </div>

                  <div class="older-news-list" v-if="showMore">
                    <span class="older-news-heading">{{ t('scheme_details.older_news') }}</span>
                    <div v-for="news in olderNews" :key="news.id" class="news-list">
                      <div class="news-link-date">
                        <router-link class="news-link" style="text-decoration: underline" :to="{
    name: 'NewsDetails',
    params: { newsId: news.id },
  }" target="_blank">
                          {{ news.title }}
                        </router-link>
                        <span v-if="news.date && news.date.length >= 10" class="news-date q-ml-md">
                          {{ formatDate(news.date.substring(0, 10)) }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <span v-if="!showMore && olderNews && olderNews.length > 0" @click="toggleShowMore()"
                  class="news-btn">{{ t('scheme_details.more_news') }}</span>
                <span v-if="showMore" @click="toggleShowMore()" class="news-btn">{{ t('scheme_details.hide_older_news')
                  }}</span>
              </div>
              <q-separator v-if="isVind && organisationsList && organisationsList.length > 0
    " class="full-width-separator" />
            </section>

            <div class="large-item" v-if="isVind && organisationsList && organisationsList.length > 0">
              <span class="box-title bold-font">{{ t('scheme_details.organizations') }}</span>

              <div class="scheme-news">
                <div v-for="organisation in organisationsList" :key="organisation.id" class="news-list">
                  <div class="column">
                    <router-link :to="{ name: 'SearchGrants' }" target="_blank">
                      <p class="organisation-name" @click="getClickedOrganisation(organisation.id)">
                        {{ organisation.name + ' (' + organisation.type + ')' }}
                        <q-tooltip>
                          {{ t('scheme_details.view_all_schemes_of_this_organization') }}
                        </q-tooltip>
                      </p>
                    </router-link>

                    <div class="row">
                      <span v-if="organisation.addresses &&
    organisation.addresses.length > 0
    " class="organisation-address q-ml-md">{{ organisation.addresses[0].addressLine1
                        }}{{
    organisation.addresses[0].addressLine2 != null
      ? `, ${organisation.addresses[0].addressLine2}`
      : ''
  }}{{
      organisation.addresses[0].addressLine3 != null
        ? `, ${organisation.addresses[0].addressLine3}`
        : ''
    }}
                      </span>

                      <div class="address-info">
                        <span v-if="organisation.phone" class="org-info blue-text">
                          {{ organisation.phone }}
                        </span>
                        <a v-if="organisation.url" class="q-ml-sm org-info" :href="organisation.url" target="_blank">
                          {{ organisation.url }}
                        </a>
                        <span v-if="organisation.email" class="q-ml-sm org-info">
                          {{ organisation.email }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <q-separator class="full-width-separator" />
            <section id="customers-schemes" v-if="schemeCustomers.length > 0 && isUserFomo != false">
              <div class="large-item scheme-customers-card-table">
                <span class="box-title bold-font q-mb-md">{{ t('scheme_details.customers') }}</span>

                <q-table class="my-sticky-header-table" flat dense :rows="schemeCustomers"
                  :columns="schemeCustomersColumns" style="max-width: fit-content" />
              </div>
              <q-separator class="q-mt-md full-width-separator" />
            </section>
            <div id="calls"></div>
            <div v-if="isVind &&
              scheme.children &&
              scheme.children.length > 0 &&
              !scheme.isCall &&
              !scheme.isApplicationPeriod" 
              class="large-item">
              <span class="box-title bold-font q-pb-md">{{ t('scheme_details.related_opening') }}</span>
              <Calls />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="circle-container">
      <div id="pagetop" class="top-scroll" v-if="scY > 300">
        <i class="las la-2x la-angle-up up-icon" @click="toTop">
          <q-tooltip self="center left" :offset="[10, 10]">{{ t('search_schemes.upwards') }}</q-tooltip>
        </i>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { onMounted, computed, ref, provide, onBeforeMount } from "vue";
import moment from "moment";
import GrantsSevice from "../services/grants.service";
import store from "../store/index";
import grantsService from "../services/grants.service";
import ShareGrant from "../components/share-components/ShareScheme.vue";
import SaveScheme from "../components/SaveScheme.vue";
import Calls from "../components/Calls";
import SchemeUtils from "../utils/schemes";
import OrganisationsUtils from "../utils/organisations";
import DownloadSchemeButton from "../components/share-components/DownloadSchemeButton";
import DownloadScheme from "../components/share-components/DownloadScheme.vue";
import UserService from "../services/userprofile.service";
import { date } from "quasar";
import { useI18n } from 'vue-i18n';

export default {
  components: {
    ShareGrant,
    SaveScheme,
    Calls,
    DownloadScheme,
    DownloadSchemeButton,
  },
  setup() {
    const { t, locale } = useI18n();
    const route = useRoute();
    const router = useRouter();
    onBeforeMount(() => {
      if (!isVind.value) {
        router.push({
          name: 'PortalSchemeDetails',
          params: {
            grantId: route.params.grantId,
          },
        });
      }
    });

    onMounted(async () => {
      await UserService.getProfileConfiguration();
      const id = route.params.grantId;
      await GrantsSevice.getSchemeById(id, true, locale);
      statusNL.value = SchemeUtils.translateStatus(scheme.value.status);
      OrganisationsUtils.generateOrganisationsForDetailsPage();
      window.addEventListener('scroll', handleScroll);
      window.scrollTo(0, 0);
      localStorage.setItem('schemeOrganisationClicked', false);
      await GrantsSevice.getSchemeCustomers(route.params.grantId);
      await UserService.getUserRoles();
      store.commit('grantItems/updateTranslateScheme', false);
    });

    const translateScheme = computed({
      get: () => store.state.grantItems.translateScheme,
      set: (val) => store.commit('grantItems/updateTranslateScheme', val),
    });
    const scheme = computed({
      get: () => store.state.grantItems.selectedGrant,
    });

    const shortIntro = computed({
      get: () => store.state.grantItems.shortIntro,
    });

    const schemeCustomers = computed({
      get: () => store.state.grantItems.schemeCustomers,
    });

    const isUserFomo = computed({
      get: () => store.state.user.userFomo,
    });
    const showMore = ref(false);

    const columns = [
      {
        name: 'year',
        label: 'Year',
        field: 'year',
        sortable: true,
        align: 'left',
      },
      {
        name: 'amount',
        label: 'Amount (€)',
        field: 'amount',
        sortable: true,
        align: 'left',
      },
    ];

    const schemeCustomersColumns = [
      {
        name: 'customer',
        label: 'Ontvanger',
        field: 'customer',
        align: 'left',
      },
      {
        name: 'applicationSubject',
        label: 'Onderwerp van de aanvraag',
        field: 'applicationSubject',
        align: 'left',
      },
      {
        name: 'year',
        label: 'Jaar',
        field: 'dateTimeGranted',
        align: 'left',
        format: (val) => date.formatDate(val, 'YYYY'),
      },
      {
        name: 'amount',
        label: 'Bedrag',
        field: 'amount',
        align: 'left',
        format: (val) => {
          var amount = Math.floor(val);
          return amount >= 1000000
            ? `€ ${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
            : `€ ${amount}`;
        },
      },
    ];

    const columnsOrganisations = [
      {
        name: 'name',
        label: 'Name',
        field: 'name',
        sortable: true,
        align: 'left',
      },
    ];
    const scTimer = ref(0);
    const scY = ref(0);
    const handleScroll = () => {
      if (scTimer.value) return;
      scTimer.value = setTimeout(() => {
        scY.value = window.scrollY;
        clearTimeout(scTimer.value);
        scTimer.value = 0;
      }, 100);
    };

    const toTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    const scrollToIt = (el) => {
      const yOffset = -80;
      const element = document.getElementById(el);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    };

    const download = async (documentId, documentName) => {
      await grantsService.downloadDocument(documentId).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        var a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.download = documentName;
        a.href = fileURL;
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);
      });
    };

    const getClickedOrganisation = (organisationId) => {
      localStorage.setItem('schemeOrganisation', organisationId);
      localStorage.setItem('schemeOrganisationClicked', true);
    };
    const schemeDeadline = computed({
      get: () =>
        store.state.grantItems.selectedGrant.currentDeadline != null
          ? moment(store.state.grantItems.selectedGrant.currentDeadline).format(
            'DD-MM-YYYY'
          )
          : null,
    });

    const seeMoreNews = (title) => {
      store.commit('newsSearch/updateSearchText', title);

      router.push({
        name: 'News',
      });
    };

    const toggleShowMore = () => (showMore.value = !showMore.value);

    const shareGrant = () => {
      store.commit('grantItems/enableShareGrant', scheme);
    };

    const enableSaveScheme = (id) => {
      store.commit('grantItems/addSelected', id);
      store.commit('user/enableSaveScheme');
    };

    const formattedMaxAmount = computed({
      get: () => store.state.grantItems.selectedGrantMax,
    });

    const formattedMinAmount = computed({
      get: () => store.state.grantItems.selectedGrantMin,
    });

    const newsInLastYear = computed({
      get: () => store.state.grantItems.newsInLastYear,
    });
    const olderNews = computed({
      get: () => store.state.grantItems.olderNews,
    });

    const areGrantDetailsLoading = computed({
      get: () => store.state.grantItems.areGrantDetailsLoading,
    });

    const downloadSchemePopup = (id) => {
      store.commit('grantItems/enableDownloadSchemeReport', id);
    };

    const formatDate = (stringDate) => {
      const dateN = new Date(stringDate);
      return moment(dateN).format('DD-MM-YYYY');
    };

    const openDetailsInNewTab = computed({
      get: () => store.state.user.openDetailsInNewTab,
    });

    const goToParent = () => {
      router.push({
        name: 'GrantDetails',
        params: { grantId: scheme.value.parentSchemeId },
      });
    };
    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });
    const hasUser = computed({
      get: () => store.state.auth.env !== 50 && store.state.auth.env !== 60,
    });
    const statusNL = ref('');
    const organisationsList = computed({
      get: () => store.state.grantItems.organisationsList,
    });

    const sortedLinksAndDocs = computed({
      get: () => store.state.grantItems.sortedLinksAndDocs,
    });

    const seeDeprecatedItems = ref(false);

    const updateSeeDeprecatedItems = (val) => {
      seeDeprecatedItems.value = val;
    };

    const isContrast = computed({
      get: () => store.state.user.isSelectedThemeContrast,
    });

    provide('isVind', isVind.value);
    provide('hasUser', hasUser.value);

    const showMoreBudget = ref(false);

    const toggleShowMoreBudget = () => {
      showMoreBudget.value = !showMoreBudget.value;
    };

    window.addEventListener('scroll', navHighlighter);

    function navHighlighter() {
      let scrollY = window.pageYOffset;
      const sections = document.querySelectorAll('section[id]');
      sections.forEach((current) => {
        if (current != null && current) {
          const sectionHeight = current.offsetHeight;
          const sectionTop = current.offsetTop - 60;
          const sectionId = current.getAttribute('id');

          if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
            document
              .querySelector('.navigation a[href*=' + sectionId + ']')
              .classList.add('active');
          } else {
            document
              .querySelector('.navigation a[href*=' + sectionId + ']')
              .classList.remove('active');
          }
        }
      });
    }

    const showSavedInfoDialog = computed({
      get: () => store.state.user.showSavedInfoDialog,
    });

    const disableShowSavedInfoDialog = () => {
      store.commit('user/disableShowSavedInfoDialog');
    };

    const receiveAlertForSavedItems = computed({
      get: () => store.state.user.receiveAlertForSavedItems,
      set: (val) => {
        store.commit('user/setReceiveAlertForSavedItems', val);

        UserService.updateFolderItemsAlert(
          savedSchemesFolderItemsIds.value,
          val
        );
      },
    });

    const savedSchemesFolderItemsIds = computed({
      get: () => store.state.user.savedSchemesFolderItemsIds,
    });

    const getTranslation = (scheme) => {
      grantsService.translateScheme(scheme);
    };

    const transBody = computed({
      get: () => store.state.grantItems.selectedTransBody,
    });

    const transCriteria = computed({
      get: () => store.state.grantItems.selectedTransCriteria,
    });

    const transName = computed({
      get: () => store.state.grantItems.selectedTransName,
    });

    const transApplicationProcedure = computed({
      get: () => store.state.grantItems.selectedTransApplicationProcedure,
    });

    const transIntroduction = computed({
      get: () => store.state.grantItems.selectedTransIntroduction,
    });

    const transFinancialinformation = computed({
      get: () => store.state.grantItems.selectedTransFinancialinformation,
    });

    const transApplicationInformation = computed({
      get: () => store.state.grantItems.selectedTransApplicationInformation,
    });

    return {
      transApplicationInformation,
      t,
      locale,
      transBody,
      transCriteria,
      transName,
      transApplicationProcedure,
      transIntroduction,
      transFinancialinformation,
      getTranslation,
      translateScheme,
      getClickedOrganisation,
      savedSchemesFolderItemsIds,
      receiveAlertForSavedItems,
      showSavedInfoDialog,
      disableShowSavedInfoDialog,
      showMoreBudget,
      toggleShowMoreBudget,
      isContrast,
      isUserFomo,
      schemeCustomersColumns,
      schemeCustomers,
      updateSeeDeprecatedItems,
      seeDeprecatedItems,
      sortedLinksAndDocs,
      organisationsList,
      isVind,
      hasUser,
      goToParent,
      router,
      openDetailsInNewTab,
      formatDate,
      statusNL,
      //downloadScheme,
      downloadSchemePopup,
      areGrantDetailsLoading,
      newsInLastYear,
      olderNews,
      formattedMaxAmount,
      formattedMinAmount,
      enableSaveScheme,
      shareGrant,
      seeMoreNews,
      schemeDeadline,
      download,
      scrollToIt,
      handleScroll,
      toTop,
      scTimer,
      scY,
      columns,
      route,
      scheme,
      shortIntro,
      showMore,
      toggleShowMore,
      columnsOrganisations,

      iconBasedOnExtension(extension) {
        switch (extension) {
          case '.pdf':
            return 'fa-solid fa-file-pdf';
          case '.doc':
            return 'fa-solid fa-file-word';
          case '.docx':
            return 'fa-solid fa-file-word';
          case '.xls':
            return 'fa-solid fa-file-excel';
          case '.xlsx':
            return 'fa-solid fa-file-excel';
          case '.csv':
            return 'fa-solid fa-file-csv';
          case '.txt':
            return 'fa-solid fa-file';
          default:
            return 'fa-solid fa-file';
        }
      },
      getChanceOfSuccessRatingColor(rating) {
        switch (rating) {
          case 1:
            return 'blue-10';
          case 2:
            return 'blue-9';
          case 3:
            return 'blue-7';
          case 4:
            return 'blue-5';
          case 5:
            return 'blue-3';
          default:
            return 'blue-grey-2';
        }
      },
      getRatingColor(rating) {
        switch (rating) {
          case 1:
            return 'blue-3';
          case 2:
            return 'blue-5';
          case 3:
            return 'blue-7';
          case 4:
            return 'blue-9';
          case 5:
            return 'blue-10';
          default:
            return 'blue-grey-2';
        }
      },
      getTimeForApplicationRatingLabel(rating) {
        switch (rating) {
          case 5:
            return t('scheme_details.long_time');
          case 4:
            return t('scheme_details.slow');
          case 3:
            return t('scheme_details.average');
          case 2:
            return t('scheme_details.fast');
          case 1:
            return t('scheme_details.very_quick');
          default:
            return t('scheme_details.chance_of_success');
        }
      },
      getChanceOfSuccessRatingLabel(rating) {
        switch (rating) {
          case 1:
            return t('scheme_details.very_small');
          case 2:
            return t('scheme_details.small');
          case 3:
            return t('scheme_details.average');
          case 4:
            return t('scheme_details.big');
          case 5:
            return t('scheme_details.very_large');
          default:
            return t('scheme_details.chance_of_success');
        }
      },
      getComplexityRatingLabel(rating) {
        switch (rating) {
          case 5:
            return 'Heel moeilijk';
          case 4:
            return 'Moeilijk';
          case 3:
            return t('scheme_details.average');
          case 2:
            return 'Eenvoudig';
          case 1:
            return 'Heel eenvoudig';
          default:
            return t('scheme_details.difficulty');
        }
      },
    };
  },
};
</script>
]
