<template>
  <div class="user-management-page">
    <q-breadcrumbs class="text-brown navigation-breadcrumbs">
      <template v-slot:separator>
        <q-icon size="1.5em" name="chevron_right"></q-icon>
      </template>

      <q-breadcrumbs-el :label="t('profile.profile')"></q-breadcrumbs-el>
      <q-breadcrumbs-el :label="t('navbar.account_management')"></q-breadcrumbs-el>
      <q-breadcrumbs-el  :label="t('profile.user')"></q-breadcrumbs-el>
    </q-breadcrumbs>
    <div class="text-h4 text-left main-caption">{{ t('navbar.account_management') }}</div>
    <p class="heading2 q-mb-xl">
      {{ t('account_management.view_account_information_of_organization') }}
    </p>
    <div>

      <div >
        <LineGraphUser
          style="margin-left: 60px; max-width: 900px"
          class="line-graph"
          ref="lineGraph"
        />
        <div class="stat-boxes">
          <div class="row">
            <div class="stat-box q-ml-sm">
              <p class="stat-box-label">{{ t('account_management.active_users') }}</p>
              <p class="stat-box-data">
                {{ getGeneralUsersStatsFrontOffice.totalActiveUsersCount }}
              </p>
            </div>
            <div class="stat-box q-ml-sm">
              <p class="stat-box-label negative-data">
                {{ t('account_management.users_with_at_least_1_visit_in_last_30_days') }}
              </p>
              <p class="stat-box-data negative-data">
                {{
                  getGeneralUsersStatsFrontOffice.usersWithVisitInLastMonthCount
                }}
              </p>
            </div>
            <div class="stat-box">
              <p class="stat-box-label negative-data">
                {{ t('account_management.uesrs_without_newsletter') }}
              </p>
              <p class="stat-box-data negative-data">
                {{
                  getGeneralUsersStatsFrontOffice.usersWithoutNewsletterCount
                }}
              </p>
            </div>

            <div class="stat-box q-ml-sm">
              <p class="stat-box-label negative-data">
                {{ t('account_management.usesrs_without_profile') }}
              </p>
              <p class="stat-box-data negative-data">
                {{ getGeneralUsersStatsFrontOffice.usersWithoutProfileCount }}
              </p>
            </div>
          </div>
        </div>
        <div class="table-data">
          <UsersTable />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import store from "../../store/index.js";
import UserService from "../../services/userprofile.service";
import userStatsService from "../../services/userStats.service";
import UsersTable from "../user/UsersTable";
import LineGraphUser from "../user/LineGraphUser.vue";
import { useI18n } from "vue-i18n";

export default {
  components: {
    UsersTable,
    LineGraphUser,
  },
  name: "UserManagement",
  setup() {
    const selectedUserId = ref(null);
    const { locale } = useI18n();

    onMounted(async () => {
      await UserService.getCustomerByUser(locale);
      await userStatsService.getGeneralUserStats(locale);
      await userStatsService.getGeneralStatsFront(locale);
    });
    
    const customer = computed({
      get: () => store.state.user.customer,
    });

    const lineGraph = ref(null);

 
    const getGeneralUsersStatsFrontOffice = computed({  
      get: () => store.state.userStats.userStats,
    });

    const getGeneralStatsFront = computed({
      get: () => store.state.userStats.chartData,
    });


    return {
      selectedUserId,
      getGeneralStatsFront,
      customer,
      lineGraph,
      getGeneralUsersStatsFrontOffice,
      locale
    };
  },
};
</script>
