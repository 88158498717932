<template>
  <div class="sidebar-search-container portal-sidebar-search-container">
    <div class="column sidebar-items">
      <div class="filter-results-title">
        <q-icon size="24px" class="filter-icon">
          <img
            class="svg-icon"
            src="../../assets/icons/filter-list-solid.svg"
          />
        </q-icon>
        <span class="title bold-font"> Filter resultaten </span>
      </div>
      <div class="row results-info-row item-width">
        <p class="results-info-p">
          <span class="results-count">
            {{ resultsCount }}
          </span>
          <span class="text-results"> resultaten</span>
        </p>
      </div>

      <div class="row reset-btns justify-between">
        <span class="active-filters-count"
          >Actieve filters: {{ activeFiltersCount }}</span
        >

        <div class="reset-buttons">
          <button class="reset-button" v-on:click="resetSearch(false)">
            <span class="reset-clear">Reset</span>
          </button>
          <span class="slash"> / </span>

          <button class="reset-button" v-on:click="resetSearch(true)">
            <span class="reset-clear">Wis</span>
          </button>
        </div>
      </div>

      <q-list class="filters-list">
        <q-expansion-item
          label="Moeilijkheid"
          dense
          dense-toggle
          expand-icon="chevron_right"
          id="v-step-0"
        >
          <DifficultyCheckBox
            class="sidebar-margin item-width statuses-box white-bg-box"
          />
        </q-expansion-item>
        <q-expansion-item
          label="Slaagkans"
          dense
          dense-toggle
          expand-icon="chevron_right"
          id="v-step-0"
        >
          <ChanceOfSuccessCheckbox
            class="sidebar-margin item-width statuses-box white-bg-box"
          />
        </q-expansion-item>
        <q-expansion-item
          label="Tijdsduur aanvraag"
          dense
          dense-toggle
          expand-icon="chevron_right"
          id="v-step-0"
        >
          <ApplicationTimeCheckbox
            class="sidebar-margin item-width statuses-box white-bg-box"
          />
        </q-expansion-item>
        <q-expansion-item
          label="Status"
          dense
          dense-toggle
          expand-icon="chevron_right"
          id="v-step-0"
        >
          <FilterCheckBox
            class="sidebar-margin item-width statuses-box white-bg-box"
          />
        </q-expansion-item>

        <q-expansion-item
          label="Bijdrage"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <div class="sidebar-margin dateselect-box no-margin-left">
            <div class="row justify-end">
              <i
                class="las la-2x la-times-circle clear-filter-icon"
                @click="resetBudgets()"
              >
                <q-tooltip>Filter leegmaken </q-tooltip></i
              >
            </div>

            <div class="row start">
              <q-input
                v-model.number="min"
                type="number"
                min="0"
                label="Minimaal €"
                class="budget-input"
                bg-color="white"
                borderless
                dense
              />
            </div>

            <div class="row start">
              <q-input
                borderless
                v-model.number="max"
                type="number"
                min="0"
                label="Maximaal €"
                bg-color="white"
                style="width: 100%"
                dense
              />
            </div>
          </div>
        </q-expansion-item>

        <AdvancedFilters class="adv-filters-box" ref="advancedFiltersBox" />
      </q-list>

      <q-dialog v-model="showProfileNotSetWarning" class="profile-popup">
        <q-card>
          <q-card-section class="column">
            <span class="q-ml-sm bold-font popup-text-error">
              Er is geen profiel ingesteld!
            </span>
            <q-icon
              name="error"
              size="32px"
              color="indigo-10"
              class="info popup-icon error-icon q-mt-sm"
            ></q-icon>
          </q-card-section>

          <q-card-actions align="center" class="q-mb-xs">
            <router-link
              :to="{ name: 'UserProfile', params: { tab: 'newsletter' } }"
              style="margin-right: 10px; text-decoration: none"
            >
              <q-btn
                class="orange-btn-filled bold-font q-mr-md q-mb-sm btn-profile"
                label="Ga naar profiel"
                no-caps
                dense
                @click="disableShowProfileNotSetWarning()"
              />
            </router-link>
            <q-btn
              label="Ok"
              class="orange-btn bold-font q-mr-md q-mb-sm"
              style="width: 60px"
              dense
              @click="disableShowProfileNotSetWarning()"
            />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import { mapState } from 'vuex';
import { computed } from 'vue';
import store from '../../store/index.js';
import FilterCheckBox from '../search-components/StatusesCheckBox.vue';
import AdvancedFilters from '../search-components/AdvancedFilters.vue';
import FiltersService from '../../services/filters.service';
import SchemeUtils from '../../utils/schemes';
import UserService from '../../services/userprofile.service';
import DifficultyCheckBox from '../portals-components/DifficultyCheckbox.vue';
import ChanceOfSuccessCheckbox from '../portals-components/ChanceOfSuccessCheckbox.vue';
import ApplicationTimeCheckbox from '../portals-components/ApplicationTimeCheckbox.vue';
import { useI18n } from "vue-i18n";

export default {
  components: {
    FilterCheckBox,
    AdvancedFilters,
    DifficultyCheckBox,
    ChanceOfSuccessCheckbox,
    ApplicationTimeCheckbox,
  },
  setup() {
    onMounted(async () => {
      await FiltersService.getDefaultFilters();
    });
    const { locale } = useI18n();
    const showProfileNotSetWarning = computed({
      get: () => store.state.user.showProfileNotSetWarning,
    });

    const min = computed({
      get: () =>
        store.state.advancedSearch.minBudget != null
          ? store.state.advancedSearch.minBudget
          : Number(sessionStorage.getItem('minBudget')),
      set: async (value) => {
        store.commit('advancedSearch/enableFiltersChange');
        store.commit('advancedSearch/updateMinBudget', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const max = computed({
      get: () =>
        store.state.advancedSearch.maxBudget != null
          ? store.state.advancedSearch.maxBudget
          : Number(sessionStorage.getItem('maxBudget')),
      set: async (value) => {
        store.commit('advancedSearch/enableFiltersChange');
        store.commit('advancedSearch/updateMaxBudget', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });

    const resultsCount = computed({
      get: () => store.state.advancedSearch.numberOfResults,
    });

    const profileSearchFlag = computed({
      get: () => store.state.user.isProfileSearch,
    });

    const advancedFiltersBox = ref(null);

    const resetSearch = async (isClear) => {
      store.commit('advancedSearch/updateIsResetSearch', true);
      store.commit('advancedSearch/resetSearchFilter', isClear);

      advancedFiltersBox.value.resetValues();

      if (isClear) {
        UserService.setApplyFilter(false);
        store.commit('user/setIsApplyingFilters', false);
      }

      if (!isClear) {
        store.commit('advancedSearch/updateIsApplyingFiltersReset', true);
      }
      store.commit('advancedSearch/updateIsResetSearch', false);
    };

    const resetBudgets = async () => {
      store.commit('advancedSearch/updateMinBudget', null);
      store.commit('advancedSearch/updateMaxBudget', null);
      if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
    };

    const showAdvancedFilters = computed({
      get: () => store.state.advancedFilters.showAdvancedFilters,
    });

    const activeFiltersCount = computed({
      get: () => store.state.advancedSearch.activeFiltersCount,
    });

    const activeFiltersLabel = ref(
      'Actieve filters: ' + activeFiltersCount.value
    );

    watch(activeFiltersCount, () => {
      activeFiltersLabel.value = 'Actieve filters: ' + activeFiltersCount.value;
    });

    const isSearchPaused = computed({
      get: () => store.state.advancedSearch.applySavedSearchPause,
    });
    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });

    const drawer = ref(true);
    return {
      locale,
      drawer,
      isVind,
      isSearchPaused,
      showProfileNotSetWarning,
      activeFiltersCount,
      activeFiltersLabel,
      resetBudgets,
      advancedFiltersBox,

      resetSearch,
      resultsCount,
      store,
      min,
      max,

      statusOpen: ref(true),
      statusFuture: ref(true),
      statusClosed: ref(false),
      profileSearchFlag,
      //This is just hidden for now, don't remove it
      //favoriteIndustries,
      showAdvancedFilters,

      nodes: computed(() => store),
      computed: {
        ...mapState({
          filterActivities: (state) => state.advancedSearch.filterActivities,
        }),
      },
      methods: {
        updateFilter: function () {
          store.commit('advancedSearch/updateFilterActivities');
        },
        updateTreeData: function () {},
      },
    };
  },
};
</script>
