<template v-slot:body="props">
  <q-dialog v-model="downloadFolderReport" class="share-grants-page">
    <q-card class="share-dialog">
      <q-card-section>
        <div class="text-h5 text-main bold-font">
          {{ t('share_and_download_schemes.download_selected_items_as_a_report') }}
        </div>
      </q-card-section>
      <q-card-section>
        <div class="row justify-between top-section-responsive">
          <div class="column column-width">
            <span class="input-heading"> {{ t('share_and_download_schemes.report_title') }} * </span>
            <q-input
              class="q-mt-sm bg-white"
              v-model="reportTitle"
              dense
              
              :rules="requiredFieldRule"
            />

            <span class="input-heading q-mt-md "> {{ t('share_and_download_schemes.description') }} </span>
            <q-input
              class="q-mt-sm bg-white"
              v-model="description"
              type="textarea"
            />
          </div>
        </div>
      </q-card-section>

      <q-card-section class="report-type">
        <q-select
          square
          v-model="reportType"
          :options="options"
          :label="t('share_and_download_schemes.type_of_report')"
          style="width: 47%; "
          class="bg-white"
        />
      </q-card-section>

      <q-card-actions class="q-mt-sm" align="left">
        <q-btn
          flat
          no-caps
          :label="t('search_schemes.download')"
          class="orange-btn-filled"
          color="white"
          @click="
            generateFolderReport(
              reportTitle,
              description,
              customerName,
              reportType
            )
          "
        />
        <q-btn
          no-caps
          flat
          :label="t('common.cancel')"
          class="orange-btn-cancel"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, ref } from "vue";
import store from "../../store/index";
import authHeader from "../../helpers/auth-header";
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t, locale } = useI18n();
    const getFolderToDownload = computed({
      get: () => store.state.grantItems.folderToDownload,
    });

    const reportTitle = computed({
      get: () => store.state.grantItems.nameOfDownloadFolder,
      set: (value) => store.commit("grantItems/nameOfDownloadFolder", value),
    });

    const downloadFolderReport = computed({
      get: () => store.state.grantItems.downloadFolderReport,
      set: (val) => store.commit("grantItems/disableDownloadFolderReport", val),
    });
    const disableDownloadFolderReport = () => {
      store.commit("grantItems/disableDownloadFolderReport");
    };

    const generateFolderReport = async (
      reportTitle,
      description,
      customerName,
      reportType
    ) => {
      let form = document.createElement("form");
      form.method = "post";
      form.target = "_blank";
      form.action = `../api/download/generateFolderReport?folderId=${getFolderToDownload.value}&culture=${locale.value}`;
      form.innerHTML = `'<input type="hidden" name="jwtToken" value="${
        authHeader().Authorization
      }">
                         <input type="hidden" name="reportType" value="${
                           reportType.value
                         }">
                         <input type="hidden" name="reportTitle" value="${reportTitle}">
                         <input type="hidden" name="reportDescription" value="${description}">
                         <input type="hidden" name="customerName" value="${customerName}">'`;
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
      store.commit("grantItems/disableDownloadFolderReport");
    };

    const description = ref("");
    const customerName = ref("");
    
    const reportType = ref({
      label: computed(() => t('share_and_download_schemes.full_report')),
      value: 0,
    });

    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });
    const custid = computed({
      get: () => store.state.auth.custid,
    });
    
    const reportOptions = ref([
      {
        label: computed(() => t('share_and_download_schemes.full_report')),
        value: 0,
      },
      {
        label: computed(() => t('share_and_download_schemes.only_core_data')),
        value: 10,
      },
      {
        label: computed(() => t('share_and_download_schemes.key_data_and_summaries')),
        value: 20,
      },
    ]);
    
    if (isVind.value && custid.value == 2) {
      reportOptions.value.push({
        label: t('share_and_download_schemes.screening_report'),
        value: 30,
      });
    }

    return {
      t,
      locale,
      downloadFolderReport,
      disableDownloadFolderReport,
      generateFolderReport,
      getFolderToDownload,
      options: reportOptions.value,
      isVind,
      custid,
      customerName,
      description,
      reportTitle,
      reportType,

      requiredFieldRule: [
        (val) => (val && val.length > 0) || t('common.required_field')
,
      ],
      isValidEmail(val) {
        const emailPattern =
          /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(val) || t('common.invalid_email_address');
      },
    };
  },
};
</script>
