<template>
  <ErrorModal />
  <q-dialog v-model="show" persistent class="save-scheme-main" v-if="!isSKS">
    <q-card style="min-width: 350px" class="save-dialog-scheme">
      <q-form @submit="onSubmit">
        <q-card-section>
          <div
            style="color: #0a108f; font-size: 22px"
            class="text-main bold-font"
          >
            Vul uw profielgegevens in
          </div>
          <p class="q-mt-md">
            Door deze gegevens in te vullen zie je alleen de voor jou relevante
            regelingen. <br />
            Zo vind je nog sneller de best passende subsidie!
          </p>
        </q-card-section>

        <q-separator />
        <q-card-section class="q-pt-none q-pa-md">
          <div class="column q-mb-md check-organisation">
            <q-select
              borderless
              bg-color="white"
              v-model="searchInterest"
              :options="searchInterests"
              option-label="name"
              label="Voor wie zoekt u?"
              style="width: 263px"
              lazy-rules
              :rules="requiredFieldRule"
              dense
            >
            </q-select>
            <q-input
              v-if="searchInterest != 'Individu'"
              dense
              bg-color="white"
              v-model="portalOrganisation"
              label="Organisatienaam of KvK"
              borderless
              lazy-rules
              :rules="requiredFieldRule"
              style="width: 263px"
            />
            <q-select
              v-if="searchInterest != 'Individu'"
              borderless
              bg-color="white"
              v-model="themeType"
              :options="portalThemeTypes"
              option-label="name"
              label="Kies onderwerp"
              style="width: 263px"
              lazy-rules
              dense
            >
            </q-select>
          </div>
        </q-card-section>

        <q-separator />

        <q-card-actions class="bold-font q-ml-sm" align="center">
          <q-btn
            color="white"
            class="orange-btn-filled"
            flat
            :label="t('common.ok')"
            style="width: 90px"
            no-caps
            type="submit"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import store from '../../store/index';
import authService from '../../services/auth.service';
import UserService from '../../services/userprofile.service';
import SchemeUtils from '../../utils/schemes';
import NewsUtils from '../../utils/news';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const show = ref(false);
    const { t, locale } = useI18n();
    onMounted(async () => {
      authService.getPortalTypes();
      authService.getSearchInterests();
      await UserService.getUser();
      if (isProfileComplited.value) show.value = false;
      else show.value = true;
    });

    const portalThemeTypes = computed({
      get: () => store.state.auth.portalThemeTypes,
    });

    const searchInterests = computed({
      get: () => store.state.auth.searchInterests,
    });

    const themeType = computed({
      get: () => store.state.user.user.themeType,
      set: (val) => store.commit('user/updateThemeType', val),
    });
    const searchInterest = computed({
      get: () => store.state.user.user.searchInterest,
      set: (val) => store.commit('user/updateSearchInterest', val),
    });
    const portalOrganisation = computed({
      get: () => store.state.user.user.person.portalOrganisation,
      set: (value) => store.commit('user/updatePortalOrganisation', value),
    });

    const isProfileComplited = computed({
      get: () => store.state.user.isProfileComplited,
    });
    const isLoggedIn = computed({
      get: () => store.state.auth.isLoggedIn,
    });

    const onSubmit = async () => {
      if (
        themeType.value != '' &&
        portalOrganisation.value != '' &&
        searchInterest.value != '' &&
        searchInterest.value != 'Individu'
      ) {
        await UserService.updateThemeType(themeType.value);
        await UserService.updateSearchInterest(searchInterest.value);
        await UserService.editUserPortalOrganisation(portalOrganisation.value);
        await SchemeUtils.searchSchemes(locale);
        await NewsUtils.searchNews(locale);
        store.commit('user/updateIsProfileComplited', true);
        show.value = false;
      } else if (searchInterest.value == 'Individu') {
        await UserService.updateSearchInterest(searchInterest.value);
        store.commit('user/updateIsProfileComplited', true);
        await SchemeUtils.searchSchemes(locale);
        await NewsUtils.searchNews(locale);
        show.value = false;
      } else {
        store.commit('user/updateIsProfileComplited', false);
        show.value = true;
      }
    };

    const isSKS = computed({
      get: () => store.state.auth.env === 30,
    });

    return {
      t,
      locale,
      isSKS,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || t('common.required_field')
,
      ],
      isLoggedIn,
      onSubmit,
      isProfileComplited,
      portalOrganisation,
      show,
      themeType,
      searchInterest,
      searchInterests,
      portalThemeTypes,
    };
  },
};
</script>
