<template>
  <div class="public-news-page">
    <form id="form1" runat="server">
      <div class="wrapper">
        <!-- Header -->
        <table border="0" cellpadding="0" cellspacing="0" style="width: 700px" bgcolor="#292929">
          <tr>
            <td valign="top">
              <img src="https://content.vindsubsidies.nl/nieuwsbrief/images/IgniteGroup-mailing-header.jpg" width="700"
                height="176" alt="Ignite Group" style="display: block; margin: 0; max-width:700px;" id="headerImage" />
            </td>
          </tr>
        </table>
        <!-- Header Title -->
        <table border="0" cellpadding="0" cellspacing="0" style="width: 700px;" bgcolor="#1E063D">
          <tr>
            <td valign="top"
              style="padding-top:16px; padding-right:24px; padding-bottom:22px; padding-left:24px; color: #0AEBBF; font-size: 20px; line-height: 28px; font-family: 'Space Grotesk', 'Trebuchet', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';"
              id="headerTitle">
              <h1
                style="line-height: 1rem !important; font-size: 1.25em !important;font-weight: 500 !important; color: #0AEBBF !important;">
                {{
                  title }}</h1>
            </td>
            <td valign="top"
              style="padding-top:22px; padding-right:24px; padding-bottom:22px; padding-left:24px; text-align: right; color: #0AEBBF; font-family: 'Space Grotesk', 'Trebuchet', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';"
              id="headerSocials">
              <table border="0" cellpadding="0" cellspacing="0" align="right">
                <tr>
                  <td align="right" valign="top">
                    <a href="https://nl.linkedin.com/company/ignite-group-nl" style="text-decoration: none;">
                      <img
                        src="https://content.vindsubsidies.nl/nieuwsbrief/images/IgniteGroup-mailing-icon-linkedin.png"
                        width="21" height="21" alt="LinkedIn" />
                    </a>
                  </td>
                  <td align="right" valign="top" width="16">&nbsp;</td>
                  <td align="right" valign="top">
                    <a href="https://twitter.com/ignite-group" style="text-decoration: none;">
                      <img
                        src="https://content.vindsubsidies.nl/nieuwsbrief/images/IgniteGroup-mailing-icon-twitter-x.png"
                        width="21" height="21" alt="Twitter" />
                    </a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

        <div style="width: 700px;">
          <article class="detail__page">
            <div class="news-items-list-onze">
              <div class="news-item-onze q-pt-md" v-for="item in newsItems" :key="item.title">
                <table>
                  <tr>
                    <td align="left" valign="top" width="24"><img
                        src="https://content.vindsubsidies.nl/nieuwsbrief/images/IgniteGroup-mailing-icon-mail-blue.png"
                        width="21" height="21" alt="" /></td>
                    <td align="left" valign="top" width="8"></td>
                    <td align="left" valign="top">
                      <div class="row q-pb-sm">
                        <span class="title">
                          <a :href="item.link" target="_blank" class="black">{{ item.title }}</a>
                        </span>
                      </div>
                      <div class="row">
                        <span class="intro q-mt-xs">
                          {{ item.introduction }}
                        </span>
                      </div>
                      <div class="row">
                        <div style="margin-left: auto; margin-right: 0;">
                          <a :href="item.link" target="_blank" class="black">{{ t('search_news.read_more') }}</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                <q-separator class="q-mb-lg"></q-separator>
              </div>
            </div>
          </article>
        </div>
        <table border="0" cellpadding="0" cellspacing="0" style="width: 700px;" bgcolor="#1E063D">
          <tr>
            <td valign="top"
              style="padding-top: 32px; padding-bottom: 32px; padding-left: 24px; color: #0AEBBF; font-size: 14px; line-height: 22px; font-family: 'Space Grotesk', 'Trebuchet', 'Helvetica Neue', Helvetica, Arial, 'sans-serif'; "
              id="mailFooterContent">
              <table border="0" cellpadding="0" cellspacing="0" style="width: 100%;">
                <tr heigth="30px" style="height:30px;">
                  <td style="width: 24px" width="24px">
                    <a href="https://www.ignite-group.com"
                      style="text-decoration: none; font-size: 14px; color: #0AEBBF; font-family: 'Space Grotesk', 'Trebuchet', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';">
                      <img
                        src="https://content.vindsubsidies.nl/nieuwsbrief/images/IgniteGroup-mailing-icon-website.png"
                        width="18" height="18" style="" alt="website" />

                    </a>

                  </td>
                  <td style="line-height: 16px; vertical-align: middle;">
                    <a href="https://www.ignite-group.com"
                      style="color:#0AEBBF !important;text-decoration:none !important;">
                      www.ignite-group.com
                    </a>
                  </td>
                </tr>
                <tr heigth="30px" style="height:30px;">
                  <td style="width: 24px" width="24px">
                    <a href="tel:+31882020400"
                      style="text-decoration: none; font-size: 14px; color: #0AEBBF; font-family: 'Space Grotesk', 'Trebuchet', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';">
                      <img
                        src="https://content.vindsubsidies.nl/nieuwsbrief/images/IgniteGroup-mailing-icon-general-phone.png"
                        width="18" height="18" alt="phone-number" />

                    </a>

                  </td>
                  <td style="line-height: 16px; vertical-align: middle;">
                    <a href="tel:+31882020400" style="color:#0AEBBF !important;text-decoration:none !important;">
                      088 - 2020400
                    </a>
                  </td>
                </tr>
                <tr heigth="30px" style="height:30px;">
                  <td style="width: 24px" width="24px">
                    <a href="mailto:info@ignite-group.nl"
                      style="color:#0AEBBF !important;text-decoration:none !important;">
                      <img src="https://content.vindsubsidies.nl/nieuwsbrief/images/IgniteGroup-mailing-icon-mail.png"
                        width="18" height="18" alt="e-mail" />

                    </a>

                  </td>
                  <td style="line-height: 16px; vertical-align: middle; ">
                    <a href="mailto:info@ignite-group.nl"
                      style="color:#0AEBBF !important;text-decoration:none !important;">
                      info@ignite-group.nl
                    </a>
                  </td>
                </tr>
              </table>
            </td>
            <td valign="top" align="right"
              style="padding-top:32px; padding-right:24px; padding-bottom:32px; padding-left:24px; color: #0AEBBF; font-size: 14px; font-family: 'Space Grotesk', 'Trebuchet', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';"
              id="mailFooterSocials">
              Social Media
              <table border="0" cellpadding="0" cellspacing="0">
                <tr>
                  <td colspan="3" height="8" />
                </tr>
                <tr>
                  <td align="right" valign="top">
                    <a href="https://nl.linkedin.com/company/ignite-group-nl" style="text-decoration: none;">
                      <img
                        src="https://content.vindsubsidies.nl/nieuwsbrief/images/IgniteGroup-mailing-icon-linkedin.png"
                        width="21" height="21" alt="LinkedIn" />
                    </a>
                  </td>
                  <td align="right" valign="top" width="16">&nbsp;</td>
                  <td align="right" valign="top">
                    <a href="https://twitter.com/ignite-group" style="text-decoration: none;">
                      <img
                        src="https://content.vindsubsidies.nl/nieuwsbrief/images/IgniteGroup-mailing-icon-twitter-x.png"
                        width="21" height="21" alt="Twitter" />
                    </a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </form>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue';
import store from '../store/index';
import newsService from '../services/news.service';

export default {
  name: 'OnzePublicNewsList',
  setup() {
    onMounted(async () => {
      await newsService.getOnzeNewsItemsList();
    });

    const title = computed({
      get: () => store.state.publicNews.onzeNewsListTitle,
    });
    const newsItems = computed({
      get: () => store.state.publicNews.onzeNewsList,
    });

    return {
      newsItems,
      title
    };
  },
};
</script>

<style>
body {
  background: #f1f1f1;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, 'sans-serif' !important;
  line-height: 1.5;
}

.public-news-page {
  text-align: left !important;
  margin-top: 0 !important;
}

.top-margin {
  margin-top: 40px !important;
}

a,
a:visited {
  text-decoration: none;
  color: #1938e8;
}

a:hover,
a:focus {
  color: #000000;
  text-decoration: underline;
}

a.black {
  color: #191d96 !important;
}

h1 {
  font-weight: 500 !important;
  font-size: 1.866em;
  line-height: 4rem !important;
  color: #000 !important;
}

h2 {
  font-weight: 500;
  font-size: 1.3em;
  margin: 1.3em 0 0;
}

@media screen and (min-width: 30rem) {
  h2 {
    font-size: 1.5em;
    margin: 1.5em 0 0;
  }
}

.button {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  background: #191d96;
  color: #f1f1f1;
  white-space: nowrap;
}

.button:hover,
.button:focus {
  text-decoration: none;
  background: #f1f1f1;
  color: #191d96;
  transition: all 0.2s ease-in-out;
}

div.wrapper {
  position: relative;
  width: 100%;
  max-width: 46.75rem;
  margin: 0 auto;
  background: #ffffff;
}

header img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
}

header .header__info {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;
  background: #292929;
  padding: 0 1.5rem;
}

@media screen and (min-width: 30rem) {
  header .header__info {
    flex-flow: row nowrap;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

header .header__info::before {
  content: '';
  position: absolute;
  z-index: 1;
  top: -0.975rem;
  left: 0;
  right: 0;
  padding-top: 1rem;
  background: #292929;
  -webkit-clip-path: polygon(0 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 100%, 0% 100%);
}

header .header__title {
  flex-grow: 1;
  padding: 1rem 0 1rem;
}

@media screen and (min-width: 30rem) {
  header .header__title {
    padding-bottom: 1.25rem;
    min-height: 1.875rem;
  }
}

header .header__title h1 {
  margin: 0 !important;
  color: #ffffff !important;
  font-size: 1.25em !important;
}

header .header__title a {
  color: #ffffff !important;
  font-size: 0.875rem !important;
}

header .header__title a span.icon {
  font-size: 1.5em !important;
  line-height: 1 !important;
  margin-right: 0.25rem !important;
}

header .header__title a:hover,
header .header__title a:focus {
  text-decoration: none;
  color: #191d96;
}

header .header__socials {
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  order: -1;
}

@media screen and (min-width: 30rem) {
  header .header__socials {
    order: 0;
    padding: 1.25rem 0;
    margin-left: 0.5rem;
  }
}

header .header__socials a:not(:last-child) {
  margin-right: 1rem;
}

/* main {
  padding: 0.5rem 1.5rem 0.5rem;
} */

@media screen and (min-width: 30rem) {
  main {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

article.news__item a {
  position: relative;
  display: block;
  padding: 1.5rem 0 1.5rem 2rem;
}

@media screen and (min-width: 30rem) {
  article.news__item a {
    padding-left: 2.5rem;
  }
}

article.news__item a::before {
  content: '';
  position: absolute;
  top: 1.75rem;
  left: 0;
  width: 1.5rem;
  height: 0.875rem;
  background: url('/public-news-details/news-item.png') 0 0 no-repeat;
  display: block;
}

article.news__item a {
  color: #000000;
}

article.news__item a:hover,
article.news__item a:focus {
  text-decoration: none;
}

article.news__item a:hover .news__title,
article.news__item a:focus .news__title {
  color: #191d96;
  text-decoration: underline;
}

article.news__item .news__title {
  font-size: 1em;
  font-weight: normal;
  margin-bottom: 0.25rem;
}

article.news__item .news__intro {
  font-size: 0.875em;
  color: #666666;
}

article.news__item:not(:last-child) {
  border-bottom: 0.125rem solid #f1f1f1;
}

article.detail__page {
  padding: 0.5rem 0 2rem;
  font-size: 0.9375em;
}

.detail__footer {
  margin-top: 2rem;
}

.footer-news-details {
  display: flex;
  flex-flow: column nowrap;
  background: #292929;
  color: #ffffff !important;
  padding: 0 1.5rem;
  font-size: 0.875em !important;
}

@media screen and (min-width: 30rem) {
  .footer-news-details {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

address {
  color: white;
  font-style: normal;
  font-size: 1em;
  font-family: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial',
    'sans-serif' !important;
  display: flex;
  flex-direction: column;
}

address>p {
  margin: 0;
  padding: 16px 0;
}

.footer address,
.footer__socials {
  margin-top: 16px;
}

.footer a {
  color: #ffffff;
  text-decoration: none !important;
}

.footer a:hover,
.footer a:focus {
  color: #191d96;
}

.footer p {
  margin: 0;
}

.footer address {
  flex-grow: 1;
  font-style: normal !important;
}

.footer-socials__header {
  margin-bottom: 0.5rem;
  font-size: 1em !important;
}

.footer__socials a:not(:last-child) {
  margin-right: 1rem;
}

.footer__socials a:hover,
.footer__socials a:focus {
  text-decoration: none;
}

article.news__cancel {
  padding-top: 20px;
}

article.news__cancel a {
  color: #222222;
  font-weight: normal;
  text-decoration: none;
  font-size: 12px;
  font-family: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial',
    'sans-serif' !important;
}
</style>
