<template>
  <main class="main-tag">
    <div class="spinner-page" v-if="isNewsDetailsLoading">
      <q-spinner class="spinner" color="primary" size="3em" />
    </div>
    <div v-else class="news-details-page" :class="isContrast != 'false' && isContrast != false ? 'high-contrast' : ''">
      <ShareNewsDetails v-if="hasUser" />

      <div class="column pagetop">
        <router-link :to="{
      name: 'News',
    }" style="text-decoration: none">
          <span :class="!isVind ? 'portalnews-nav' : ''" class="curr-nav row">{{ t('navbar.search_news') }} &#62; {{
      title }}</span>
        </router-link>
        <div class="row heading-scheme justify-between">
          <div class="column">
            <span class="bold-font title">
              <div class="row" style="width: 80vw; justify-content: space-between">
                <span class="news-title" v-html="title"></span>
                <q-btn v-if="!isVind && hasUser" dense no-caps class="orange-btn bold-font q-mt-sm" color="green" flat
                  @click="shareNewsItem()" style="width: 147px; height: 36px">
                  <q-icon name="fa-solid fa-share-nodes" left="true" class="q-mr-sm" size="18px">
                  </q-icon>
                  {{ t('search_schemes.share') }}</q-btn>
              </div>
              <div class="topinfo-item">
                <span class="topinfo-item-heading">{{ t('search_news.published_on') }} </span>
                <span class="topinfo-item-value q-ml-sm">{{
      formatDate(date)
    }}</span>
              </div>
              <div v-if="isVind" class="icon-container row">
                <q-icon name="share" size="18px" class="action-icon q-mt-md" @click="shareNewsItem()">
                  <q-tooltip>{{ t('search_schemes.share') }}</q-tooltip>
                </q-icon>
              </div>
            </span>
          </div>
        </div>
      </div>

      <div class="row page-lower">
        <div class="column">
          <div id="introduction"></div>

          <div class="large-item">
            <div class="introduction">
              <div v-html="introduction"></div>
            </div>
            <div class="introduction">
              <div v-html="body"></div>
            </div>
            <div id="body"></div>
          </div>

          <q-separator class="full-width-separator" v-if="isVind && schemes != null && schemes.length > 0" />
          <div class="large-item" v-if="isVind && schemes != null && schemes.length > 0">
            <span class="box-title bold-font"> {{ t('news_details.related_arrangements') }}</span>
            <div class="scheme-news">
              <div v-for="scheme in schemes" :key="scheme.id">
                <router-link class="news-link" style="text-decoration: underline; color: #0a108f"
                  :to="{ name: 'GrantDetails', params: { grantId: scheme.id } }" target="_blank">
                  {{ scheme.name }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="circle-container">
        <div id="pagetop" class="top-scroll" v-if="scY > 300">
          <i class="las la-2x la-angle-up up-icon" @click="toTop">
            <q-tooltip self="center left" :offset="[10, 10]">{{ t('search_schemes.upwards') }}</q-tooltip>
          </i>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { onMounted, computed, ref, provide } from "vue";
import NewsService from "../services/news.service";
import { useRoute, useRouter } from "vue-router";
import store from "../store/index";
import moment from "moment";
import ShareNewsDetails from "../components/share-components/ShareNewsDetails.vue";
export default {
  components: {
    ShareNewsDetails,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const title = computed({
      get: () => store.state.news.selectedTitle,
    });

    const hasUser = computed({
      get: () => store.state.auth.env !== 50 && store.state.auth.env !== 60,
    });
    const status = computed({
      get: () => store.state.news.selectedNewsStatus,
    });

    const body = computed({
      get: () => store.state.news.selectedBody,
    });

    const introduction = computed({
      get: () => store.state.news.selectedIntroduction,
    });
    const scope = computed({
      get: () => store.state.selectedNewsScopeTgas,
    });
    const date = computed({
      get: () =>
        store.state.news.selectedNewsDate != null
          ? moment(store.state.news.selectedNewsDate).format("YYYY-MM-DD")
          : null,
    });
    const organisations = computed({
      get: () => store.state.news.newsOrganisations,
    });
    const schemes = computed({
      get: () => store.state.news.selectedNewsSchemes,
    });
    const tags = computed({
      get: () => store.state.news.selectedNewsTags,
    });

    onMounted(async () => {
      window.scrollTo(0, 0);

      const id = route.params.newsId;
      window.addEventListener("scroll", handleScroll);

      var statisticType = store.state.user.hasComeFromNewsletter
        ? "NewsletterClick"
        : "NewsClick";

      await NewsService.getNewsById(id, statisticType);
    });

    const orgColumns = [
      {
        name: "Organisation name",
        label: "Organisation name",
        field: "name",
        sortable: true,
        align: "left",
      },
    ];

    const schemeColumns = [
      {
        name: "Scheme name",
        label: "Scheme name",
        field: "name",
        sortable: true,
        align: "left",
      },
    ];

    const scTimer = ref(0);
    const scY = ref(0);
    const handleScroll = () => {
      if (scTimer.value) return;
      scTimer.value = setTimeout(() => {
        scY.value = window.scrollY;
        clearTimeout(scTimer.value);
        scTimer.value = 0;
      }, 100);
    };

    const toTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const formatDate = (stringDate) => {
      const date = new Date(stringDate);
      return moment(date).format("DD-MM-YYYY");
    };

    const newsItem = computed({
      get: () => store.state.news.selectedNewsItem,
    });

    const isNewsDetailsLoading = computed({
      get: () => store.state.news.isNewsDetailsLoading,
    });

    const selectedNewsItem = computed({
      get: () => store.state.news.selectedNewsItem,
    });

    const shareNewsItem = () => {
      store.commit("news/enableShareNews", selectedNewsItem);
    };
    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });
    const isContrast = computed({
      get: () => store.state.user.isSelectedThemeContrast,
    });

    provide('hasUser', hasUser.value);
    return {
      isContrast,
      isVind,
      hasUser,
      isNewsDetailsLoading,
      newsItem,
      formatDate,
      router,
      handleScroll,
      toTop,
      scTimer,
      scY,
      organisations,
      schemes,
      tags,
      title,
      orgColumns,
      schemeColumns,
      body,
      scope,
      status,
      introduction,
      date,
      shareNewsItem,
    };
  },
};
</script>
