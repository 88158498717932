<template>
  <q-breadcrumbs class="text-brown navigation-breadcrumbs">
    <template v-slot:separator>
      <q-icon size="1.5em" name="chevron_right"></q-icon>
    </template>

    <q-breadcrumbs-el label="Profiel"></q-breadcrumbs-el>
    <q-breadcrumbs-el :label="t('navbar.account_management')"></q-breadcrumbs-el>
    <q-breadcrumbs-el label="Gebruik"></q-breadcrumbs-el>
  </q-breadcrumbs>
  <div class="user-management-page">
    <div class="text-h4 text-left main-caption">{{ t('navbar.account_management') }}</div>
    <p class="heading2 q-mb-xl">
      {{ t('account_management.view_account_information_of_organization') }}    
    </p>
    <div class="customersBasicDetails">
      <div class="text-h4 text-left tab-heading-colors">{{ t('account_management.account_information') }}</div>

      <div class="content-tab">
        <div class="emailAndName row no-wrap">
          <div class="input-fields">
            <label>{{ t('share_and_download_schemes.name') }}</label>
            <q-input
              type="text"
              lazy-rules
              dense
              :disable="!enableEditMode"
              v-model="customerName"
              borderless
            ></q-input>
          </div>
          <div class="input-fields email">
            <label>Emailextensie</label>
            <q-input
              type="text"
              lazy-rules
              dense
              disable
              borderless
              v-model="customer.emailSuffix"
            >
            </q-input>
          </div>
        </div>
        <div class="input-fields">
          <label class="q-mt-md licenties">Aantal licenties</label>
          <q-input
            type="text"
            lazy-rules
            dense
            disable
            borderless
            v-model="customer.numberOfLicences"
          >
          </q-input>
        </div>
      </div>
      <!-- 
        <div class="on-right">
          <div
            v-if="enableEditMode == false"
            class="button-accountgegevens float-left"
          >
            <q-btn
              color="orange-8"
              no-caps
              label="Account bewerken"
              icon="edit"
              class="material-icons-outlined outlined editbtn"
              @click="enableEditMode = true"
            >
              <q-tooltip>Bewerk</q-tooltip>
            </q-btn>
          </div>
          <div v-if="enableEditMode == true" class="q-ma-sm float-left">
            <q-btn
              color="orange-8"
              icon="save"
              class="material-icons-outlined outlined"
              @click="saveChanges"
            >
              <q-tooltip>Opslaan</q-tooltip>
            </q-btn>
          </div>
        </div> -->
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import store from "../../store/index.js";
import UserService from "../../services/userprofile.service";
import { useI18n } from "vue-i18n";

export default {
  name: "CustomersBasicDetails",
  setup() {
    const enableEditMode = ref(false);
    const { locale } = useI18n();

    onMounted(async () => {
      await UserService.getCustomerByUser(locale);
      });
  
    const customer = computed({
      get: () => store.state.user.customer,
    });

    const customerName = computed({
      get: () => {
        return store.state.user.customer.name;
      },
      set: (value) => store.commit("user/updateCustomerName", value),
    });
    return {
      enableEditMode,
      customerName,
      customer,
      locale
    };
  },
};
</script>
