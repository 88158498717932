<template>
  <div class="filter-box statuses-checkbox">
    <q-checkbox
      dense
      v-model="verySmall"
      :label="t('scheme_details.very_small')"
      class="status-checkbox"
    />
    <q-checkbox dense v-model="small" :label="t('scheme_details.small')" class="status-checkbox" />
    <q-checkbox
      dense
      v-model="average"
      :label="t('scheme_details.average')"
      class="status-checkbox"
    />
    <q-checkbox dense v-model="big" :label="t('scheme_details.big')" class="status-checkbox" />
    <q-checkbox
      dense
      v-model="veryBig"
      :label="t('scheme_details.very_large')"
      class="status-checkbox"
    />
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import store from '../../store/index';
import SchemeUtils from '../../utils/schemes';
import { useI18n } from "vue-i18n"

export default {
  setup() {
    onMounted(() => {});
    const { locale } = useI18n();
    const verySmall = computed({
      get: () =>
        sessionStorage.getItem('showVerySmallChance') == null ||
        store.state.advancedSearch.showVerySmallChance ===
          (sessionStorage.getItem('showVerySmallChance') === 'true')
          ? store.state.advancedSearch.showVerySmallChance
          : sessionStorage.getItem('showVerySmallChance') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateShowVerySmallChance', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const small = computed({
      get: () =>
        sessionStorage.getItem('showSmallChance') == null ||
        store.state.advancedSearch.showSmallChance ===
          (sessionStorage.getItem('showSmallChance') === 'true')
          ? store.state.advancedSearch.showSmallChance
          : sessionStorage.getItem('showSmallChance') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateShowSmallChance', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const average = computed({
      get: () =>
        sessionStorage.getItem('showAverageChance') == null ||
        store.state.advancedSearch.showAverageChance ===
          (sessionStorage.getItem('showAverageChance') === 'true')
          ? store.state.advancedSearch.showAverageChance
          : sessionStorage.getItem('showAverageChance') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateAverageChance', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const big = computed({
      get: () =>
        sessionStorage.getItem('showBigChance') == null ||
        store.state.advancedSearch.showBigChance ===
          (sessionStorage.getItem('showBigChance') === 'true')
          ? store.state.advancedSearch.showBigChance
          : sessionStorage.getItem('showBigChance') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateBigChance', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const veryBig = computed({
      get: () =>
        sessionStorage.getItem('showVeryBigChance') == null ||
        store.state.advancedSearch.showVeryBigChance ===
          (sessionStorage.getItem('showVeryBigChance') === 'true')
          ? store.state.advancedSearch.showVeryBigChance
          : sessionStorage.getItem('showVeryBigChance') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateVeryBigChance', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });

    const resetSelected = () => {
      store.commit('advancedSearch/updateShowOpen', false);
      store.commit('advancedSearch/updateShowClosed', false);
      store.commit('advancedSearch/updateShowForthcoming', false);
      store.commit('advancedSearch/updateShowDiscontinued', false);
      if (!isSearchPaused.value) SchemeUtils.searchSchemes(locale);
    };
    const showDiscontinuedCheckbox = store.state.auth.env !== 20;
    const isSearchPaused = computed({
      get: () => store.state.advancedSearch.applySavedSearchPause,
    });
    return {
      locale,
      isSearchPaused,
      resetSelected,
      group: ref([]),
      verySmall,
      big,
      closed,
      veryBig,
      showDiscontinuedCheckbox,
      average,
      small,
    };
  },
};
</script>

<style></style>
