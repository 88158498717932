<template>
  <div class="hidden-schemes-page">
    <q-breadcrumbs class="text-brown navigation-breadcrumbs">
      <template v-slot:separator>
        <q-icon size="1.5em" name="chevron_right"></q-icon>
      </template>

      <q-breadcrumbs-el label="Profiel"></q-breadcrumbs-el>
      <q-breadcrumbs-el label="Verborgen items"></q-breadcrumbs-el>
    </q-breadcrumbs>
    <div class="text-h4 text-left main-caption">{{ t('navbar.hidden_schemes') }}</div>
    <p class="heading2">
      {{ t('saved_and_hidden_items.hidden_items_subtitle') }}
    </p>
    <div class="hiddenItems">
      <div class="desktop-view-hiddenItems" id="hidden">
        <q-table
          class="hiddenSchemesTable"
          :rows="hiddenSchemesItems"
          :columns="hiddenSchemesColumns"
          row-key="id"
          :filter="filterSchemes"
          :filter-method="schemesFilter"
          :rows-per-page-label="t('search_news.results_per_page')"
        >
          <template v-slot:top>

            <q-input
              borderless
              dense
              debounce="300"
              color="primary"
              :placeholder="t('search_schemes.search')"
              v-model="filterSchemes"
              class="search-input"
            >
              <i v-if="filterSchemes == ''" 
              class="las la-search search-icon"></i>
            </q-input>
          
            <div class="row absolute-right  input-button">
               <p>Alles terugzeten:</p>
            <q-btn
                class="history-button q-gutter-xs"
                size="sm"
                style="margin-left: 0%; height:35px"
                dense
              >
                <q-icon
                  @click="unhideAllSchemes()"
                  class="q-mb-xs"
                  name="fa-solid fa-rotate-left"
                  size="16px"
                />
                <q-tooltip> {{ t('saved_and_hidden_items.restore') }} </q-tooltip>
              </q-btn>
            </div>
           
            
            <q-space />
          </template>
          <template v-slot:header-cell="props">
            <q-th :props="props">
              {{ props.col.label }}
         
            </q-th>
          </template>
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="name" class="name" :props="props"><router-link  style="text-decoration: none"
                                :to="{
                                  name: 'GrantDetails',
                                  params: { grantId: props.row.id },
                                }">{{ props.row.name }}</router-link></q-td>
              <q-td key="itemTypeString" :props="props">{{ props.row.itemTypeString }}</q-td>
              <q-td key="status" :props="props"></q-td>
              <q-td key="statusNl" :props="props">{{ props.row.statusNl }}</q-td>
              <q-td key="actions" :props="props">
                <q-btn
                  class="history-button q-gutter-xs"
                  @click="unhideScheme(props.row.id)"
                  size="sm"
                  no-caps
                  dense
                >
                  <q-icon
                    name="fa-solid fa-rotate-left"
                    size="16px"
                    style="padding-bottom: 3px"
                  />
                  <q-tooltip> {{ t('saved_and_hidden_items.restore') }} </q-tooltip>
                </q-btn>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>

      <div class="column mobile-view-hiddenItems">
        <div class="row input-hideItems">
          <q-input
            borderless
            dense
            debounce="300"
            color="primary"
            :placeholder="t('search_schemes.search')"
            v-model="filterSchemes"
            class="search-input"
            @update:model-value="
              schemesFilter(hiddenSchemesItems, filterSchemes)
            "
          >
            <q-icon class="search-icon" name="search" size="20px"></q-icon>
          </q-input>

          

          <q-btn
            class="history-button q-gutter-xs"
            size="sm"
            style="margin-left: 0%"
          >
            <q-icon
              @click="unhideAllSchemes()"
              class="q-mb-xs unhideSchemes"
              name="fa-solid fa-rotate-left"
              size="16px"
              dense
              style="padding-top: 2px"
            />
            <q-tooltip> {{ t('saved_and_hidden_items.restore') }} </q-tooltip>
          </q-btn>
        </div>

        <div class="expansion-hiddenItems">
          <q-list v-for="x in hiddenSchemesFilter" :key="x.id" padding>
            <q-expansion-item
              v-if="x"
              :label="x.name"
              densedense-toggle
              expand-separator
            >
              <div class="column q-mx-md content-for-searches">
                <div class="flex justify-between about-scheme">
                  <span class="spans">{{ t('search_schemes.status') + ':' }}</span>
                  <p class="status">
                    {{ x.status }}
                  </p>
                </div>
                <div class="flex justify-between about-scheme">
                  <span class="spans">{{ t('saved_and_hidden_items.actions') }}</span>
                  <q-btn
                    class="activity-button"
                    @click="unhideScheme(x.id)"
                    size="sm"
                    no-caps
                  >
                    <q-icon name="fa-solid fa-rotate-left" size="16px" />
                    <q-tooltip> {{ t('saved_and_hidden_items.restore') }} </q-tooltip>
                  </q-btn>
                </div>
              </div>
            </q-expansion-item>
          </q-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import store from "../../store/index.js";
import GrantsService from "../../services/grants.service";
import { useI18n } from 'vue-i18n';

export default {
  name: "HiddenSchemes",
  setup() {
    const { t, locale } = useI18n();

    const hiddenSchemesColumns = [
      {
        name: "name",
        align: "left",
        label: 'Naam',
        field: "name",
        sortable: true,
      },
      {
        name: 'itemTypeString',
        align: 'left',
        label: 'Soort',
        field: 'itemTypeString',
        sortable: true,
      },
      {
        label: 'Status',
        name: 'statusNl',
        field: 'statusNl',
        sortable: true,
      },
      {
        name: "actions",
       
      }
    ];

    const filterSchemes = ref("");
    const loading = ref(false);

    const hiddenSchemesItems = computed({
      get: () => store.state.user.hiddenSchemesList,
      
    });

    const hiddenSchemesFilter = computed({
      get: () => store.state.user.hiddenSchemesFilter,
    });

    const unhideScheme = (schemeId) => {
      GrantsService.unhideScheme(schemeId, locale);
    };

    const unhideAllSchemes = () => {
      GrantsService.unhideAllSchemes();
    };

    return {
      t,
      locale,
      unhideAllSchemes,
      hiddenSchemesColumns,
      hiddenSchemesItems,
      filterSchemes,
      hiddenSchemesFilter,
      loading,
      unhideScheme,
      schemesFilter(rows, terms) {
        // rows contain the entire data
        // terms contains whatever you have as filter
        const lowerFilter = terms ? terms.toLowerCase() : "";
        if (lowerFilter != "") {
          var filtered = rows.filter(
            (row) => row.name.toLowerCase().indexOf(lowerFilter) > -1
          );
          store.commit("user/updateHiddenSchemesListFilter", filtered);
          return filtered;
        } else {
          store.commit("user/updateHiddenSchemesListFilter", rows);
          return rows;
        }
      },
    };
  },
};
</script>
