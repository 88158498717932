<template>
  <div class="user-management-page">
    <q-breadcrumbs class="text-brown navigation-breadcrumbs">
      <template v-slot:separator>
        <q-icon size="1.5em" name="chevron_right"></q-icon>
      </template>

      <q-breadcrumbs-el :label="t('profile.profile')"></q-breadcrumbs-el>
      <q-breadcrumbs-el
        :label="t('navbar.account_management')"
      ></q-breadcrumbs-el>

      <q-breadcrumbs-el
        :label="t('account_management.users')"
      ></q-breadcrumbs-el>
    </q-breadcrumbs>
    <div class="text-h4 text-left main-caption">
      {{ t("navbar.account_management") }}
    </div>
    <p class="heading2 q-mb-xl">
      {{ t("account_management.view_account_information_of_organization") }}
    </p>
    <div class="users">
      <div class="users-for-desktop">
        <q-table
          id="qtable"
          class="sticky-header"
          table-header-class="text-white"
          :rows="customerUsers"
          :columns="usersTableColumns"
          row-key="id"
          :filter="filterUsers"
          :filter-method="usersFilter"
          :rows-per-page-label="t('search_news.results_per_page')"
          :pagination="pagination"
        >
          <template v-slot:top>
            <div style="display: flex; flex-direction: column">
              <div class="row">
                <div class="text-h6 q-mb-md text-left tab-heading-colors">
                  {{ t("account_management.users") }}
                </div>
                <q-icon
                  id="excel-download"
                  class="q-mt-md q-ml-md"
                  name="fa-solid fa-download"
                  @click="exportExcel"
                >
                  <q-tooltip> {{ t("search_schemes.download") }} </q-tooltip>
                </q-icon>
              </div>

              <div class="search-button">
                <q-input
                  borderless
                  style="width: 582px"
                  dense
                  debounce="300"
                  color="primary"
                  :placeholder="t('search_schemes.search')"
                  v-model="filterUsers"
                >
                  <q-icon
                    v-if="filterUsers == ''"
                    class="search"
                    name="search"
                    size="16px"
                  ></q-icon>
                </q-input>
                <q-space></q-space>
                <q-btn
                  :disable="loading"
                  @click="enableAddUser"
                  color="orange-8"
                  dense
                  no-caps
                >
                  <div class="button-icon">
                    <i class="las la-lg la-plus-circle"> </i>
                    <span class="q-ml-xs button-span">{{
                      t("account_management.add_new_user")
                    }}</span>
                  </div>
                </q-btn>
              </div>
            </div>
          </template>

          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="firstName" :props="props">{{
                props.row.firstName
              }}</q-td>
              <q-td key="lastName" :props="props">{{
                props.row.lastName
              }}</q-td>
              <q-td key="email" :props="props">{{ props.row.email }}</q-td>
              <q-td key="isActive" :props="props">
                <i
                  v-if="!props.row.hasPasswordToken"
                  class="las la-2x la-exclamation"
                  style="color: orange"
                >
                </i>
                <i
                  v-else-if="props.row.hasPasswordToken && props.row.isActive"
                  class="las la-2x la-check"
                  style="color: green"
                ></i>
                <i
                  v-else-if="props.row.hasPasswordToken && !props.row.isActive"
                  class="las la-2x la-times"
                  style="color: red"
                ></i>
              </q-td>
              <q-td key="startDate" :props="props">
                {{ formatDate(props.row.createdDate) }}
              </q-td>
              <q-td key="department" :props="props">
                {{ props.row.department }}
              </q-td>

              <q-td key="actions" :props="props" class="q-gutter-xs">
                <q-btn
                  color="info"
                  size="sm"
                  no-caps
                  @click="sendPasswordSetupMail(props.row.userId)"
                >
                  <i class="las la-2x la-mail-bulk la-fw"></i>
                  <q-tooltip>
                    {{ t("account_management.recover_password") }}
                  </q-tooltip>
                </q-btn>
                <q-btn
                  v-if="props.row.isActive"
                  color="red"
                  @click="
                    (confirmDeactivation = true),
                      (selectedUserId = props.row.userId)
                  "
                  size="sm"
                  no-caps
                >
                  <i class="las la-2x la-ban la-fw"></i>
                  <q-tooltip>
                    {{ t("account_management.deactivate") }}
                  </q-tooltip>
                </q-btn>
                <q-btn
                  v-if="!props.row.isActive"
                  color="positive"
                  @click="
                    (confirmReactivation = true),
                      (selectedUserId = props.row.userId)
                  "
                  size="sm"
                  no-caps
                >
                  <i class="las la-2x la-sync la-fw"></i>
                  <q-tooltip>
                    {{ t("account_management.reactivate") }}
                  </q-tooltip>
                </q-btn>
                <q-btn
                  class="edit"
                  size="sm"
                  no-caps
                  @click="enableEditUser(props.row)"
                >
                  <i class="las la-2x la-edit la-fw"></i>
                  <q-tooltip> {{ t("account_management.edit") }} </q-tooltip>
                </q-btn>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
      <div class="column users-for-mobile">
        <div class="column first-div">
          <div class="column content-for-searches">
            <span class="text-left heading">
              {{ t("account_management.users") }}
            </span>
            <div class="inputAndButton">
              <div class="search-users">
                <q-input
                  borderless
                  dense
                  debounce="300"
                  color="primary"
                  :placeholder="t('search_schemes.search')"
                  v-model="filterUsers"
                  @update:model-value="usersFilter(customerUsers, filterUsers)"
                >
                  <q-icon class="search" name="search" size="16px"></q-icon>
                </q-input>
                <q-space></q-space>
              </div>
              <q-btn
                class="button-add"
                :disable="loading"
                @click="enableAddUser"
                color="orange-8"
                dense
                no-caps
                style="margin-left: 22px; margin-top: 10px; margin-right: 70px"
              >
                <div class="button-icon">
                  <i class="las la-lg la-plus-circle"> </i>
                  <span class="q-ml-xs button-span">{{
                    t("account_management.add_new_user")
                  }}</span>
                </div>
              </q-btn>
            </div>
          </div>
          <div class="expansion-list">
            <q-list v-for="x in customersFilter" :key="x.id" padding>
              <q-expansion-item
                :label="x.firstName + ' ' + x.lastName"
                dense
                dense-toggle
                expand-separator
              >
                <div class="flex justify-between about-user">
                  <span class="spans">{{
                    t("account_management.e_mail") + ":"
                  }}</span>
                  <p>{{ x.email }}</p>
                </div>

                <div class="flex justify-between about-user">
                  <span class="spans">{{
                    t("account_management.active") + ":"
                  }}</span>
                  <i
                    v-if="x.isActive"
                    class="q-mr-md q-mt-xs las la-2x la-check"
                    style="color: green"
                  ></i>
                  <i
                    v-if="!x.isActive"
                    class="q-mr-md q-mt-xs las la-2x la-times"
                    style="color: red"
                  ></i>
                </div>

                <div class="flex justify-between about-user">
                  <span class="spans">{{
                    t("saved_and_hidden_items.actions")
                  }}</span>
                  <div class="buttons q-mr-none">
                    <q-btn
                      color="info"
                      size="xs"
                      no-caps
                      @click="sendPasswordSetupMail(x.userId)"
                    >
                      <i class="las la-2x la-mail-bulk la-fw"></i>
                      <q-tooltip>
                        {{
                          t("account_management.recover_password")
                        }}</q-tooltip
                      >
                    </q-btn>
                    <q-btn
                      v-if="x.isActive"
                      color="red"
                      @click="
                        (confirmDeactivation = true),
                          (selectedUserId = x.userId)
                      "
                      size="xs"
                      no-caps
                    >
                      <i class="las la-2x la-ban la-fw"></i>
                      <q-tooltip>
                        {{ t("account_management.deactivate") }}
                      </q-tooltip>
                    </q-btn>
                    <q-btn
                      v-if="!x.isActive"
                      color="positive"
                      @click="
                        (confirmReactivation = true),
                          (selectedUserId = x.userId)
                      "
                      size="xs"
                      no-caps
                    >
                      <i class="las la-2x la-sync la-fw"></i>
                      <q-tooltip>
                        {{ t("account_management.reactivate") }}
                      </q-tooltip>
                    </q-btn>
                    <q-btn
                      class="edit"
                      size="xs"
                      no-caps
                      @click="enableEditUser(x)"
                    >
                      <i class="las la-2x la-edit la-fw"></i>
                      <q-tooltip>
                        {{ t("account_management.edit") }}
                      </q-tooltip>
                    </q-btn>
                  </div>
                </div>
              </q-expansion-item>
              <q-separator inset />
            </q-list>
          </div>
        </div>
      </div>
    </div>
  </div>

  <q-dialog v-model="confirmDeactivation" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <i class="las la-2x la-ban la-fw"></i>
        <span class="q-ml-sm">{{
          t("account_management.deactivate_user_question")
        }}</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          style="border-radius: 5px"
          no-caps
          flat
          :label="t('common.cancel')"
          class="orange-btn-filled"
          v-close-popup
          dense
        />
        <q-btn
          no-caps
          class="orange-btn"
          flat
          :label="t('account_management.deactivate')"
          v-close-popup
          @click="deactivateUser(selectedUserId)"
          dense
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="confirmReactivation" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <i class="las la-2x la-sync la-fw"></i>
        <span class="q-ml-sm">{{
          t("account_management.activate_user_question")
        }}</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          no-caps
          flat
          :label="t('common.cancel')"
          class="orange-btn-filled"
          v-close-popup
          dense
        />
        <q-btn
          class="orange-btn"
          no-caps
          flat
          :label="t('account_management.reactivate')"
          v-close-popup
          @click="reactivateUser(selectedUserId)"
          dense
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="showEmailSentDialog" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <i class="las la-2x la-check la-fw"></i>
        <span class="q-ml-sm">{{ t("account_management.email_sent") }}</span>
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          outline
          :label="t('common.ok')"
          color="primary"
          @click="disableEmailSentDialog()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog class="user-create-dialog" v-model="userCreatedDialog" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <i class="las la-2x la-check la-fw"></i>
        <span class="q-ml-sm">{{
          t("account_management.activation_invitation_has_been_sent")
        }}</span>
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          class="orange-btn-filled"
          :label="t('common.ok')"
          @click="disableUserCreatedDialog()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <AddNewUser />
  <EditUser v-if="showEditUserComponent" />
</template>
<script>
import { ref, onMounted, computed } from "vue";
import store from "../../store/index.js";
import UserService from "../../services/userprofile.service";
import * as XLSX from "xlsx";
import moment from "moment";
import AddNewUser from "./AddNewUser.vue";
import EditUser from "./EditUser.vue";
import { useI18n } from "vue-i18n";

export default {
  components: {
    AddNewUser,
    EditUser,
  },
  name: "UsersTableManagement",
  setup() {
    const { t, locale } = useI18n();
    const usersTableColumns = ref([
      {
        name: "firstName",
        align: "left",
        label: computed(() => t("account_management.first_name")),
        field: "firstName",
        sortable: true,
      },
      {
        name: "lastName",
        align: "left",
        label: computed(() => t("profile.last_name")),
        field: "lastName",
        sortable: true,
      },

      {
        name: "email",
        align: "left",
        label: computed(() => t("account_management.e_mail")),
        field: "email",
        sortable: true,
      },
      {
        name: "isActive",
        align: "center",
        label: computed(() => t("account_management.is_active")),
        field: "isActive",
        sortable: true,
      },
      {
        name: "startDate",
        align: "left",
        label: computed(() => t("account_management.active_since")),
        field: "createdDate",
        sortable: true,
      },
      {
        name: "department",
        align: "left",
        label: computed(() => t("account_management.department")),
        field: "department",
        sortable: true,
      },
      {
        name: "actions",
        align: "center",
        label: "",
      },
      {
        name: "hasPasswordToken",
        field: "hasPasswordToken",
      },
    ]);
    const filterUsers = ref("");
    const confirmDeactivation = ref(false);
    const confirmReactivation = ref(false);
    const selectedUserId = ref(null);

    onMounted(async () => {
      await UserService.getCustomerByUser(locale);
      await UserService.getUser(locale);
    });
    const customerUsers = computed({
      get: () => store.state.user.customer.persons,
    });
    const customersFilter = computed({
      get: () => {
        return store.state.user.customersFilter;
      },
    });

    const pagination = {
      page: 1,
      rowsPerPage: 20,
    };

    const formatDate = (date) => {
      return moment(date).format("DD-MM-YYYY");
    };

    const showEditUserComponent = computed({
      get: () => store.state.user.showEditUserDialog,
    });

    const showEmailSentDialog = computed({
      get: () => store.state.user.showEmailSentDialog,
    });

    const disableEmailSentDialog = () => {
      store.commit("user/disableEmailSentDialog");
    };
    const disableUserCreatedDialog = () => {
      store.commit("user/disableUserCreatedDialog");
    };
    const userCreatedDialog = computed({
      get: () => store.state.user.showUserCreatedDialog,
    });

    const sendPasswordSetupMail = async (userId) => {
      await UserService.sendPasswordSetupMail(userId, locale);
    };
    const reactivateUser = async (selectedUserId) => {
      await UserService.reactivateUser(selectedUserId, locale);
      confirmReactivation.value = false;
    };
    const deactivateUser = async (selectedUserId) => {
      await UserService.deactivateUser(selectedUserId, locale);
      confirmDeactivation.value = false;
    };

    const enableAddUser = () => {
      store.commit("user/enableAddUser");
    };

    const enableEditUser = (row) => {
      store.commit("user/enableEditUser", row);
    };

    const exportExcel = () => {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([]);
      ws["!cols"] = Array.from(
        { length: usersTableColumns.value.length },
        () => ({
          wch: 30,
        })
      );
      const headerRow = ref([
        t("account_management.first_name"),
        t("profile.last_name"),
        t("account_management.e_mail"),
        t("account_management.is_active"),
        t("account_management.active_since"),
        t("account_management.department"),
      ]);
      const sheetData = [
        headerRow.value,
        ...customerUsers.value.map((row) =>
          usersTableColumns.value.map((col) => row[col.name])
        ),
      ];

      XLSX.utils.sheet_add_aoa(ws, sheetData, { origin: "A1" });
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });
      const fileName = "Gebruikers.xlsx";

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    };
    return {
      t,
      locale,
      usersTableColumns,
      customerUsers,
      customersFilter,
      filterUsers,
      pagination,
      formatDate,
      exportExcel,
      userCreatedDialog,
      disableUserCreatedDialog,
      showEmailSentDialog,
      disableEmailSentDialog,
      sendPasswordSetupMail,
      selectedUserId,
      reactivateUser,
      deactivateUser,
      enableEditUser,
      enableAddUser,
      showEditUserComponent,
      confirmDeactivation,
      confirmReactivation,
      usersFilter(rows, terms) {
        // rows - contains the entire data
        // terms - contains whatever you have as filter
        const lowerFilter = terms ? terms.toLowerCase() : "";

        if (lowerFilter !== "") {
          store.commit(
            "user/updateCustomers",
            rows.filter(
              (row) =>
                row.email.toLowerCase().indexOf(lowerFilter) > -1 ||
                row.firstName.toLowerCase().indexOf(lowerFilter) > -1 ||
                row.lastName.toLowerCase().indexOf(lowerFilter) > -1
            )
          );
          return rows.filter(
            (row) =>
              row.email.toLowerCase().indexOf(lowerFilter) > -1 ||
              row.firstName.toLowerCase().indexOf(lowerFilter) > -1 ||
              row.lastName.toLowerCase().indexOf(lowerFilter) > -1
          );
        } else {
          store.commit("user/updateCustomers", rows);
          return rows;
        }
      },
    };
  },
};
</script>
