<template>
  <ErrorModal />
  <q-dialog v-model="showEditUser" persistent class="edit-user-dialog">
    <q-card style="min-width: 400px">
      <q-card-section>
        <div>
          <span>
            <i class="las la-2x la-edit la-fw"></i>
            Bewerk gebruiker
          </span>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-lg q-ml-xl edit">
        <div class="column justify-around">
          <q-input
            class="q-ma-md"
            dense
            v-model="firstName"
            label="Voornaam"
            stack-label
            borderless
            :rules="requiredFieldRule"
          />
          <q-input
            class="q-ma-md"
            dense
            v-model="lastName"
            label="Achternaam"
            stack-label
            borderless
            :rules="requiredFieldRule"
          />
          <q-input
            class="q-pa-md"
            dense
            v-model="department"
            label="Afdeling"
            stack-label
            borderless
          />
        </div>
        <div class="column justify-around">
          <q-input
            class="q-pa-md"
            v-model="email"
            filled
            type="email"
            label="Email"
            borderless
            stack-label
            :rules="[isValidEmail, requiredFieldRule]"
          >
            <template v-slot:prepend>
              <q-icon name="mail" small />
            </template>
          </q-input>
          <q-checkbox
            v-show="isSuperAdmin"
            class="q-ml-md"
            v-model="isAccountManager"
            label="Accountmanager"
            color="indigo-10"
          >
            <q-tooltip> This user is AccountManager. </q-tooltip>
          </q-checkbox>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="around">
        <q-btn
          class="orange-btn-filled"
          no-caps
          flat
          :label="t('search_schemes.save')"
          @click="editUser()"
        />
        <q-btn
          no-caps
          flat
          class="orange-btn"
          outline
          :label="t('common.cancel')"
          @click="disableEditUser()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { onMounted, computed } from "vue";
import store from "../../store/index.js";
import ErrorModal from "./ErrorModal.vue";
import UserService from "../../services/userprofile.service";
import { useI18n } from 'vue-i18n';

export default {
  components: {
    ErrorModal,
  },
  setup() {
    const { t, locale } = useI18n();
    const showEditUser = computed({
      get: () => store.state.user.showEditUserDialog,
    });

    const userId = computed({
      get: () => store.state.user.selectedPersonForEdit.userId,
    });
    const email = computed({
      get: () => store.state.user.selectedPersonForEdit.email,
      set: (value) => store.commit("user/updateSelectedUserEmail", value),
    });
    const firstName = computed({
      get: () => store.state.user.selectedPersonForEdit.firstName,
      set: (value) => store.commit("user/updateSelectedUserFirstName", value),
    });
    const lastName = computed({
      get: () => store.state.user.selectedPersonForEdit.lastName,
      set: (value) => store.commit("user/updateSelectedUserLastName", value),
    });

    const isAccountManager = computed({
      get: () => store.state.user.selectedPersonForEdit.isAccountManager,
      set: (value) =>
        store.commit("user/updateSelectedUserIsAccountManager", value),
    });
    const department = computed({
      get: () => store.state.user.selectedPersonForEdit.department,
      set: (value) => store.commit("user/updateSelectedUserDepartment", value),
    });

    const isSuperAdmin = computed({
      get: () => store.state.user.isSuperAdmin,
    });
    
    const updatedUser = {
      id: userId,
      email: email,
      firstName: firstName,
      lastName: lastName,
      department: department,
      isAccountManager: isAccountManager,
    };

    onMounted(() => {});

    const disableEditUser = () => {
      store.commit("user/disableEditUser");
    };

    const editUser = async () => {
      await UserService.editFrontOfficeUser(updatedUser, locale);
      disableEditUser();
    };

    return {
      t,
      locale,
      userId,
      email,
      firstName,
      isSuperAdmin,
      lastName,
      department,
      isAccountManager,
      showEditUser,
      disableEditUser,
      editUser,
      updatedUser,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || t('common.required_field'),
      ],
      isValidEmail(val) {
        const emailPattern =
          /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(val) || t('common.invalid_email');
      },
    };
  },
};
</script>
