<template>
    <q-select
      class="language-select"
      v-model="locale"
      :options="allLocales"
      borderless
      dense
      emit-value
      map-options
      options-dense
      @update:model-value="(val) => switchLang(val)"
      > 
      <template v-slot:prepend>
        <q-icon name="fa-solid fa-globe" color="white"/>
      </template>
    </q-select>
</template>
<script>
import { useI18n } from 'vue-i18n';
import { supportedLocales, switchLanguage } from "../i18n";
import schemeUtils from "../utils/schemes";
import { useRoute } from "vue-router";

export default {
  setup() {
    const { locale } = useI18n(/*{ useScope: 'global' }*/);

    const allLocales = supportedLocales;
    const route = useRoute();

    const switchLang = async (language) => {
      const newLocale = language;
      await switchLanguage(newLocale).then(async () => {
        if (route.name === 'SearchGrants')
          await schemeUtils.searchSchemes(locale);
      });
    }

    return {
      locale,
      allLocales,
      switchLang,
    }
  }
}
</script>