<template>
  <q-dialog v-model="downloadScheme" class="share-grant-main">
    <q-card class="share-dialog">
      <q-card-section>
        <div class="text-h5">{{ t('share_and_download_schemes.donwload_item') }}</div>
      </q-card-section>
      <q-card-section>
        <div class="share-text">
          <div class="row">
            <q-icon name="share" style="font-size: 1.4em" />

            <div class="column">
              <span class="share-info bold"> {{ t('share_and_download_schemes.item_to_download') }} </span>

              <span class="share-info">
                {{ grant.name }}
              </span>
            </div>
          </div>
        </div>
      </q-card-section>
      <q-card-section>
        <div class="share-text-sm">
          <span class="share-info"> {{ t('share_and_download_schemes.recipient') }} </span>
          <q-input
            class="q-mt-sm"
            outlined
            v-model="address"
            dense
            type="email"
            :rules="[isValidEmail, requiredFieldRule]"
            :label="t('share_and_download_schemes.add_name_or_email_address')"
          />
        </div>
      </q-card-section>

      <q-card-section>
        <div class="share-text-sm">
          <span class="share-info"> {{ t('share_and_download_schemes.message') }} * </span>
          <q-input
            :rules="requiredFieldRule"
            class="q-mt-sm"
            v-model="message"
            outlined
            type="textarea"
          />
        </div>
      </q-card-section>

      <q-card-actions class="q-mt-sm" align="left">
        <q-btn
          flat
          :label="t('search_schemes.share')"
          class="orange-btn-filled"
          color="white"
          no-caps
          @click="shareSchemeEmit(address, message, grant.id)"
        />
        <q-btn
          flat
          :label="t('common.cancel')"
          no-caps
          class="orange-btn-cancel"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, ref } from 'vue';
import store from '../../store/index';
import MailingService from '../../services/mailing.service';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t, locale } = useI18n();
    const downloadScheme = computed({
      get: () => store.state.grantItems.downloadScheme,
      set: (val) => store.commit('grantItems/disableDownloadScheme', val),
    });

    const grant = computed({
      get: () => store.state.grantItems.grantToShare,
    });

    const shareSchemeEmit = async (address, message, id) => {
      await MailingService.shareScheme(address, message, id, locale);
    };

    const address = ref('');
    const message = ref('');

    return {
      t,
      locale,
      message,
      address,
      shareSchemeEmit,
      downloadScheme,
      grant,

      requiredFieldRule: [
        (val) => (val && val.length > 0) || t('common.required_field')
,
      ],
      isValidEmail(val) {
        const emailPattern =
          /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(val) || t('common.invalid_email_address')
;
      },
    };
  },
};
</script>
