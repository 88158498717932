<template>
  <ErrorModal />
  <ShareScheme />
  <main class="main-tag">
    <div
      class="user-profile-page"
      :class="
        isContrast != 'false' && isContrast != false ? 'high-contrast' : ''
      "
    >
      <div id="q-app" style="min-height: calc(100vh - 310px); height: 81vh">
        <WelcomeDialog
          v-if="
            !localStorageUser.hasCompletedProfileSetup &&
            hasBeenRedirectedToProfile &&
            isVind
          "
        />
        <WarningDialog v-if="showLeavingProfileSetupWarning && isVind" />

        <div class="page-container">
          <q-splitter
            class="splitter-menu-desktop"
            v-model="splitterModel"
            style="
              min-height: calc(100vh - 768px);
              height: 81vh;
              background-color: #f1f1f1;
            "
          >
            <template v-slot:before>
              <q-breadcrumbs
                class="text-grey q-pt-xl"
                :style="isVind ? 'display:none' : 'marginLeft:85px'"
              >
                <template v-slot:separator>
                  <q-icon size="1.5em" name="chevron_right"></q-icon>
                </template>
                <q-breadcrumbs-el :label="t('profile.profile')"></q-breadcrumbs-el>
                <q-breadcrumbs-el>{{ breadcrumbsLabel }}</q-breadcrumbs-el>
              </q-breadcrumbs>
              <div class="avatar">
                <q-avatar class="profile-avatar" rounded size="75px">{{
                  firstName.charAt(0).toUpperCase()
                }}</q-avatar>
              </div>
              <div class="menu-left">
                <q-tabs
                  v-model="tab"
                  vertical
                  :class="!isVind ? 'portal-tab-navigation' : ''"
                >
                  <router-link
                    :aria-label="t('profile.user_profile_navigation_tab')"
                    :to="{
                      name: 'UserProfile',
                      params: { tab: 'myBasicDetails' },
                    }"
                    style="text-decoration: none"
                    v-if="!isSKS"
                  >
                    <q-tab
                      :aria-label="t('profile.user_profile')"
                      name="myBasicDetails"
                      style="justify-content: initial"
                      no-caps
                      class="text-indigo-10"
                      @click="goToTab('myBasicDetails')"
                    >
                      {{ t('navbar.details') }}
                    </q-tab>
                  </router-link>

                  <div class="expansion-items">
                    <q-expansion-item
                      expansion-separator
                      v-model="isClickedNewsletter"
                      v-if="isVind"
                      :label="t('navbar.profile_settings')"
                      style="justify-content: initial"
                      no-caps
                      class="menu-lg text-indigo-10"
                      :active-class="true"
                    >
                      <q-expansion-item
                        :label="audienceLabel"
                        :class="
                          isClickedMenuItemAudiences ? 'currently-selected' : ''
                        "
                        expand-icon="none"
                        @click="goToTab('newsletter')"
                        dense
                        class="menu-lg"
                      >
                      </q-expansion-item>

                      <q-expansion-item
                        expand-icon="none"
                        :label="purposesLabel"
                        :class="
                          isClickedMenuItemPurposes ? 'currently-selected' : ''
                        "
                        @click="goToTab('newsletterPurposes')"
                        dense
                        class="menu-lg"
                      >
                      </q-expansion-item>

                      <q-expansion-item
                        expand-icon="none"
                        :label="locationsLabel"
                        :class="
                          isClickedMenuItemLocations ? 'currently-selected' : ''
                        "
                        @click="goToTab('newsletterLocations')"
                        dense
                        class="menu-lg"
                      >
                      </q-expansion-item>
                    </q-expansion-item>

                    <router-link
                      :aria-label="t('profile.newsletter_navigation_tab')"
                      :to="{
                        name: 'UserProfile',
                        params: { tab: 'nieuwsbrief' },
                      }"
                      style="text-decoration: none"
                    >
                      <q-tab
                        v-if="isVind"
                        name="nieuwsbrief"
                        style="justify-content: initial"
                        :label="t('navbar.newsletter_settings')"
                        class="menu-lg text-indigo-10"
                        no-caps
                        @click="goToTab('nieuwsbrief')"
                      ></q-tab>
                    </router-link>

                    <q-expansion-item
                      v-if="isVind"
                      v-model="isClickedSavedItems"
                      expansion-separator
                      :label="labelSavedItems"
                      style="justify-content: initial; color: #191d96"
                      class="menu-lg text-indigo-10"
                      no-caps
                    >
                      <q-expansion-item
                        expand-icon="none"
                        :label="t('navbar.saved_items')"
                        :header-inset-level="1"
                        :content-inset-level="2"
                        :class="
                          isClickedMenuItemSchemes ? 'currently-selected' : ''
                        "
                        @click="goToTab('savedItemsSchemes')"
                        dense
                        class="menu-lg"
                      >
                      </q-expansion-item>
                      <q-expansion-item
                        expand-icon="none"
                        :label="t('profile.saved_searches')"
                        :header-inset-level="1"
                        :content-inset-level="2"
                        :class="
                          isClickedMenuItemSearches ? 'currently-selected' : ''
                        "
                        @click="goToTab('savedItemsSearches')"
                        dense
                        class="menu-lg"
                      >
                      </q-expansion-item>
                      <!-- <div>
                        <q-expansion-item
                          expand-icon="none"
                          label="Opgeslagen nieuwsitems"
                          :header-inset-level="1"
                          :content-inset-level="2"
                          @click="goToTab('savedItems')"
                          dense
                        >
                        </q-expansion-item>
                      </div> -->
                    </q-expansion-item>

                    <router-link
                      :aria-label="t('profile.favorites_navigation_tab')"
                      :to="{
                        name: 'UserProfile',
                        params: { tab: 'savedItemsSchemes' },
                      }"
                      style="text-decoration: none"
                    >
                      <q-tab
                        v-if="!isVind"
                        name="savedItemsSchemes"
                        style="justify-content: initial"
                        :label="t('navbar.favorites')"
                        class="menu-lg text-indigo-10"
                        no-caps
                        @click="goToTab('savedItemsSchemes')"
                      ></q-tab>
                    </router-link>

                    <router-link
                      :aria-label="t('profile.hidden_items_navigation_tab')"
                      :to="{
                        name: 'UserProfile',
                        params: { tab: 'hiddenSchemes' },
                      }"
                      style="text-decoration: none"
                    >
                      <q-tab
                        v-if="isVind"
                        name="hiddenSchemes"
                        :label="t('navbar.hidden_schemes')"
                        style="justify-content: initial"
                        class="menu-lg text-indigo-10"
                        no-caps
                        @click="goToTab('hiddenSchemes')"
                      ></q-tab>
                    </router-link>
                    <q-list>
                      <q-expansion-item
                        v-if="isVind && showAdminPanel"
                        :label="t('navbar.account_management')"
                        v-model="isClickedUserManagement"
                        style="justify-content: initial"
                        class="menu-lg text-indigo-10"
                        no-caps
                        name="accountgegevens"
                        expansion-separator
                      >
                        <q-expansion-item
                          expand-icon="none"
                          :label="t('account_management.account_information')"
                          :header-inset-level="1"
                          :content-inset-level="2"
                          :class="
                            isClickedMenuItemCustomersBasic
                              ? 'currently-selected'
                              : ''
                          "
                          dense
                          @click="goToTab('accountgegevens')"
                        >
                        </q-expansion-item>

                        <q-expansion-item
                          expand-icon="none"
                          :label="t('account_management.users')"
                          :header-inset-level="1"
                          :content-inset-level="2"
                          :class="
                            isClickedMenuItemUsers ? 'currently-selected' : ''
                          "
                          dense
                          @click="goToTab('gebruikers')"
                        >
                        </q-expansion-item>

                        <q-expansion-item
                          expand-icon="none"
                          :label="t('profile.user')"
                          :header-inset-level="1"
                          :content-inset-level="2"
                          :class="
                            isClickedMenuItemUserStats
                              ? 'currently-selected'
                              : ''
                          "
                          dense
                          @click="goToTab('gebruik')"
                        >
                        </q-expansion-item>
                      </q-expansion-item>
                    </q-list>
                  </div>
                </q-tabs>
              </div>
            </template>

            <template v-slot:after>
              <q-tab-panels
                v-model="tab"
                vertical
                style="min-height: calc(100vh - 310px);"
              >
                <div class="nieuwsbrief" name="nieuwsbrief">
                  <q-breadcrumbs class="text-brown navigation-breadcrumbs">
                    <template v-slot:separator>
                      <q-icon size="1.5em" name="chevron_right"></q-icon>
                    </template>

                    <q-breadcrumbs-el :label="t('profile.profile')"></q-breadcrumbs-el>
                    <q-breadcrumbs-el
                      :label="t('navbar.newsletter_settings')"
                    ></q-breadcrumbs-el>
                  </q-breadcrumbs>
                  <div class="main-caption">{{ t('navbar.newsletter_settings') }}</div>
                  <p class="heading2 q-mb-xl">
                    {{ t('profile.newsletter_preferences') }}
                  </p>
                  <div
                    class="text-h5 q-mb-md text-left subTitle"
                    style="
                      margin-left: 114px;
                      margin-top: 37px;
                      font-family: 'ProximaNovaBold';
                      color: #191d96;
                      font-size: 30px;
                    "
                  >
                    {{ t('profile.newsletter_frequency_question') }}
                  </div>
                  <div
                    class="q-gutter-sm text-left radio-buttons"
                    style="margin-left: 108px"
                  >
                    <q-radio
                      keep-color
                      v-model="frequency"
                      val="Dagelijks"
                      :label="t('profile.daily')"
                      class="frequency-radio"
                      dense
                    />

                    <q-radio
                      keep-color
                      v-model="frequency"
                      val="Wekelijks"
                      :label="t('profile.weekly')"
                      class="frequency-radio"
                      dense
                    />
                    <q-radio
                      keep-color
                      v-model="frequency"
                      val="Maandelijks"
                      :label="t('profile.monthly')"
                      class="frequency-radio"
                      dense
                    />
                    <q-radio
                      keep-color
                      v-model="frequency"
                      val="Nooit"
                      :label="t('profile.never')"
                      class="frequency-radio"
                      dense
                    />
                  </div>
                  <q-dialog v-model="showConfirmation">
                    <q-card class="nieuwsbrief-radio-check-dialog">
                      <q-card-section class="row items-center">
                        <span class="q-ml-sm">
                          {{ t('profile.you_will_receive_the_subsidy_newsletter') }}
                          {{ frequencyNL }}
                        </span>
                      </q-card-section>

                      <q-card-actions align="right">
                        <q-btn
                          :label="t('common.ok')"
                          class="orange-btn q-mr-md"
                          @click="disableConfirmation()"
                        />
                      </q-card-actions>
                    </q-card>
                  </q-dialog>
                </div>
                <q-tab-panel
                  class="myBasicDetails q-mb-xl"
                  name="myBasicDetails"
                  :class="!isVind ? 'portals-myBasicDetails' : ''"
                >
                  <q-breadcrumbs
                    class="text-grey navigation-breadcrumbs"
                    :style="!isVind ? 'display:none' : ' '"
                  >
                    <template v-slot:separator>
                      <q-icon size="1.5em" name="chevron_right"></q-icon>
                    </template>

                    <q-breadcrumbs-el :label="t('profile.profile')"></q-breadcrumbs-el>
                    <q-breadcrumbs-el :label="t('navbar.details')"></q-breadcrumbs-el>
                  </q-breadcrumbs>

                  <div class="q-ml-md navigation-for-mobile">
                    <div
                      v-if="!isVind && !isSKS"
                      class="row q-ml-sm q-mb-md portals-nav-mobile no-wrap"
                    >
                      <q-btn
                        class="q-px-md buttons-nav bg-indigo-9 text-white"
                        no-caps
                        dense
                        @click="goToTab('myBasicDetails')"
                        >{{ t('navbar.details') }}
                      </q-btn>

                      <q-btn
                        class="q-px-md buttons-nav bg-grey-3 text-indigo-6"
                        no-caps
                        dense
                        @click="goToTab('savedItemsSchemes')"
                      >
                      {{ t('navbar.saved_items') }}</q-btn
                      >
                    </div>
                  </div>

                  <div class="q-ml-md showOnMobile">
                    <div
                      class="text-h4 text-left main-captions"
                      :class="!isVind ? 'portal-colors' : ''"
                    >
                      {{ labelProfilDetails }}
                    </div>
                  </div>
                  <div
                    class="text-h4 text-left heading-for-desktop"
                    :class="!isVind ? 'portal-colors' : ''"
                  >
                    {{ t('navbar.details') }}
                  </div>
                  <p class="heading2 q-mb-md">
                    {{ t('profile.adjust_personal_info_and_settings_here') }}
                  </p>
                  <div class="cards">
                    <span
                      class="heading-profil"
                      :class="!isVind ? 'portal-colors' : ''"
                      >{{ t('profile.profile_details')}} </span
                    >
                    <span
                      class="heading-text1"
                      :class="!isVind ? 'portal-colors' : ''"
                    >
                      {{ labelProfilCaption }}</span
                    >

                    <div
                      class="card-content"
                      style="display: flex; flex-direction: column"
                    >
                      <div class="input-fields">
                        <div v-if="isVind" class="input-field">
                          <div class="input-profil-info">
                            <label>{{ t('profile.customer_name')}} </label>
                            <q-input
                              :aria-label="t('profile.customer_name_input_field')"
                              type="text"
                              class="q-ma-sm"
                              bg-color="white"
                              disable
                              v-model="customer"
                              borderless
                            ></q-input>
                          </div>
                          <div class="input-profil-infos">
                            <label>{{ t('login.e-mail') }}</label>
                            <q-input
                              :aria-label="t('profile.email_input_field')"
                              type="text"
                              class="q-ma-sm"
                              bg-color="white"
                              :disable="enableEditMode == false"
                              v-model="email"
                              borderless
                            >
                            </q-input>
                          </div>
                        </div>
                        <div class="input-field">
                          <div class="input-profil-info">
                            <label :class="!isVind ? 'portal-label' : ''"
                              >{{ t('account_management.first_name') }}</label
                            >
                            <q-input
                              :aria-label="t('profile.first_name_input_field')"
                              type="text"
                              class="q-ma-sm"
                              bg-color="white"
                              :disable="enableEditMode == false"
                              v-model="firstName"
                              borderless
                              dense
                            >
                            </q-input>
                          </div>

                          <div class="input-profil-infos">
                            <label :class="!isVind ? 'portal-label' : ''"
                              >{{ t('profile.last_name') }}</label
                            >
                            <q-input
                              :aria-label="t('profile.last_name_input_field')"
                              type="text"
                              class="q-ma-sm"
                              bg-color="white"
                              :disable="enableEditMode == false"
                              v-model="lastName"
                              borderless
                              dense
                            >
                            </q-input>
                          </div>
                        </div>
                        <div class="input-field" v-if="!isVind">
                          <div class="q-mt-sm input-profil-info">
                            <label :class="!isVind ? 'portal-label' : ''"
                              >{{ t('login.e-mail') }}</label
                            >
                            <q-input
                              :aria-label="t('profile.email_input_field')"
                              type="text"
                              class="q-ma-sm"
                              bg-color="white"
                              :disable="enableEditMode == false"
                              v-model="email"
                              borderless
                              dense
                            >
                            </q-input>
                          </div>
                          <div class="q-mt-sm input-profil-info">
                            <label :class="!isVind ? 'portal-label' : ''"
                              >{{ t('profile.search_interest') }}</label
                            >
                            <q-select
                              :aria-label="t('profile.search_interest_input_field')"
                              borderless
                              bg-color="white"
                              v-model="searchInterest"
                              :options="searchInterests"
                              option-label="name"
                              :label="t('profile.search_interest')"
                              behavior="menu"
                              :disable="enableEditMode == false"
                              dense
                              style="width: 360px"
                            >
                            </q-select>
                          </div>
                        </div>
                        <div class="input-field" v-if="!isVind">
                          <div
                            v-if="searchInterest != 'Individu'"
                            class="q-mt-sm input-profil-info"
                          >
                            <label :class="!isVind ? 'portal-label' : ''"
                              >{{ t('profile.organization_name_of_kvk') }}</label
                            >
                            <q-input
                              :aria-label="t('profile.number_of_portal_organizations')"
                              type="text"
                              class="q-ma-sm"
                              bg-color="white"
                              :disable="enableEditMode == false"
                              v-model="portalOrganisation"
                              :placeholder="t('profile.name_or_number')"
                              borderless
                              dense
                            >
                            </q-input>
                          </div>
                          <div
                            v-if="searchInterest != 'Individu'"
                            class="q-mt-sm input-profil-info"
                          >
                            <label :class="!isVind ? 'portal-label' : ''"
                              >{{ t('profile.interesting_topics') }}</label
                            >
                            <q-select
                              :aria-label="t('profile.select_theme_type_field')"
                              borderless
                              bg-color="white"
                              v-model="themeType"
                              :options="portalThemeTypes"
                              option-label="name"
                              :label="t('profile.choose_topic')"
                              behavior="menu"
                              :disable="enableEditMode == false"
                              dense
                              style="width: 360px"
                            >
                            </q-select>
                          </div>
                        </div>

                        <div
                          v-if="!isVind"
                          class="portal-edit-buttons flex justify-end q-mr-md q-mt-md"
                        >
                          <div
                            class="mobile-portal"
                            v-if="enableEditMode == false"
                          >
                            <q-btn
                              :aria-label="t('profile.edit_button')"
                              class="orange-btn-filled"
                              no-caps
                              @click="enableEditMode = true"
                            >
                              <q-icon
                                name="mode_edit_outline"
                                size="18px"
                                class="q-mr-sm"
                              >
                              </q-icon>
                              <span>{{ t('profile.modify') }}</span>

                              <q-tooltip>{{ t('profile.edit') }}</q-tooltip>
                            </q-btn>
                          </div>
                          <div
                            v-if="enableEditMode == true"
                            class="q-ma-sm float-left"
                          >
                            <q-btn
                              :aria-label="t('profile.save_button')"
                              class="orange-btn-filled"
                              no-caps
                              icon="save"
                              @click="saveChanges"
                            >
                              <q-tooltip>{{ t('search_schemes.save')}}</q-tooltip>
                            </q-btn>
                          </div>
                        </div>
                      </div>

                      <div v-if="isVind" class="checkbox-button-navigation">
                        <div
                          class="profile-checkbox"
                          style="display: flex; flex-direction: column"
                        >
                          <span class="heading-text2"
                            >{{ t('profile.general_settings') }}</span
                          >
                          <q-checkbox
                            dense
                            v-model="openDetailsInNewTab"
                            :label="t('profile.open_details_in_new_tab')"
                            class="checkbox"
                          />
                        </div>
                      </div>
                      <div
                        v-if="isVind"
                        class="q-my-md row no-wrap justify-between buttons-profiel"
                      >
                        <div class="on-right">
                          <div v-if="enableEditMode == false">
                            <q-btn
                              class="saveProfil"
                              color="orange-8"
                              no-caps
                              @click="enableEditMode = true"
                            >
                              <q-icon
                                name="mode_edit_outline"
                                size="18px"
                                class="q-mr-sm"
                              >
                              </q-icon>
                              <span>{{ t('profile.enable_edit_mode') }}</span>

                              <q-tooltip>{{ t('profile.edit') }}</q-tooltip>
                            </q-btn>
                          </div>
                          <div
                            v-if="enableEditMode == true"
                            class="q-ma-sm float-left"
                          >
                            <q-btn
                              color="orange-8"
                              no-caps
                              icon="save"
                              @click="saveChanges"
                            >
                              <q-tooltip>{{ t('search_schemes.save') }}</q-tooltip>
                            </q-btn>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>

                    <q-tab-panel name="generalSettings" v-if="isVind">
                    </q-tab-panel>
                  </div>
                  <div v-if="!isVind" class="newsInfoWanted flex q-mt-md">
                    <q-checkbox
                      v-model="isInformedAboutNews"
                      @update:model-value="updateIsInformedAboutNewsDialog()"
                      :label="t('profile.want_to_be_informed_about_news')"
                    />
                  </div>
                  <q-dialog
                    v-model="showWarningUnsubscriptionDialog"
                    class="save-scheme-main"
                    persistent
                  >
                    <q-card>
                      <q-card-section class="row items-center">
                        <span class="main-text bold-font q-ml-sm">
                          {{ t('profile.disable_newsletter_confirmation') }}
                        </span>
                      </q-card-section>

                      <q-card-actions
                        class="bold-font q-my-sm q-mb-md"
                        align="center"
                      >
                        <q-btn
                          outline
                          :label="t('search_schemes.yes')"
                          no-caps
                          flat
                          style="width: 90px"
                          class="orange-btn"
                          @click="confirmUnsubscription()"
                          v-close-popup
                        />
                        <q-btn
                          outline
                          :label="t('search_schemes.no')"
                          no-caps
                          flat
                          style="width: 90px"
                          class="orange-btn-filled"
                          @click="cancelUpdate()"
                          v-close-popup
                        />
                      </q-card-actions>
                    </q-card>
                  </q-dialog>
                  <q-dialog
                    v-model="showConfirmUnsubscriptionDialog"
                    persistent
                  >
                    <q-card>
                      <q-card-section class="column items-center">
                        <span class="main-text bold-font q-ml-sm q-mb-md">
                          {{ t('profile.disable_newsletter_reason_question') }}</span
                        >
                        <q-input
                          dense
                          v-model="reasonForUnsubscription"
                          lazy-rules
                          outlined
                          :rules="requiredFieldRule"
                          :label="t('profile.type_the_reason_here')"
                          style="width: 263px"
                        >
                        </q-input>
                      </q-card-section>

                      <q-card-actions
                        class="bold-font q-ml-sm q-mb-md"
                        align="center"
                      >
                        <q-btn
                          outline
                          :label="t('common.ok')"
                          class="orange-btn-filled"
                          flat
                          style="width: 90px"
                          no-caps
                          @click="updateUnsubscriptionFromNewsReason()"
                        />
                      </q-card-actions>
                    </q-card>
                  </q-dialog>
                </q-tab-panel>

                <q-tab-panel
                  class="q-pa-none"
                  style="height: auto; overflow: hidden"
                  name="savedItemsSchemes"
                >
                  <SavedItemsSchemes />
                </q-tab-panel>

                <q-tab-panel
                  class="q-pa-none"
                  style="height: auto; overflow: hidden"
                  name="savedItemsSearches"
                >
                  <SavedItemsSearches />
                </q-tab-panel>

                <q-tab-panel
                  class="locations"
                  name="newsletterLocations"
                  v-if="isVind"
                >
                  <div class="column no-wrap">
                    <q-breadcrumbs class="text-brown navigation-breadcrumbs">
                      <template v-slot:separator>
                        <q-icon size="1.5em" name="chevron_right"></q-icon>
                      </template>

                      <q-breadcrumbs-el :label="t('profile.profile')"></q-breadcrumbs-el>
                      <q-breadcrumbs-el
                        :label="t('profile.profile_settings')"
                      ></q-breadcrumbs-el>
                      <q-breadcrumbs-el :label="t('search_schemes.location_tags_label')"></q-breadcrumbs-el>
                    </q-breadcrumbs>
                    <div class="navigation-for-mobile">
                      <div class="row portals-nav-mobile no-wrap">
                        <q-btn
                          class="buttons-nav"
                          no-caps
                          @click="goToTab('newsletter')"
                          >{{ t('profile.for_whom') }} ( {{ favoriteAudience.length }} )
                        </q-btn>
                        <q-btn
                          class="buttons-nav"
                          no-caps
                          @click="goToTab('newsletterPurposes')"
                        >
                          {{ t('profile.subjects') }} ( {{ favoritePurposes.length }} )</q-btn
                        >
                        <q-btn
                          class="buttons-nav"
                          style="background-color: #191d96; color: white"
                          no-caps
                          @click="goToTab('newsletterLocations')"
                        >
                        {{ t('search_schemes.location_tags_label') }} (
                          {{ selectedFavoriteLocations.length }} )</q-btn
                        >
                      </div>
                    </div>
                  </div>

                  <div class="tags-tabs">
                    <div
                      class="text-h4 q-mb-md text-left main-caption"
                      style="margin-top: 43px; margin-left: 114px; height: 44px"
                    >
                      {{ t('profile.profile_settings') }}
                    </div>
                    <p class="heading2 q-mb-xl">
                      {{ t('profile.profile_settings_subtitle') }}
                    </p>

                    <!-- <div class="text-h6 q-mb-md text-left">Creëer je profiel</div> -->
                    <div class="row wrap location">
                      <q-tabs
                        v-model="tagsTab"
                        dense
                        class="text-grey"
                        align="justify"
                        narrow-indicator
                      >
                        <!-- <q-tab name="industries" label="Industrie">
                        <q-badge color="#0a1f8f" class="tag-badge" floating>{{
                          favoriteIndustries.length
                        }}</q-badge>
                      </q-tab> -->
                      </q-tabs>

                      <q-tab-panel class="locatie" name="locations">
                        <div class="column">
                          <div class="row no-wrap row-location">
                            <span class="headings">{{ t('search_schemes.location_tags_label') }}</span>
                            <p class="justify-content-end steps">{{ t('profile.step') }} 3/3</p>
                          </div>

                          <p class="q-mb-none subheading">
                            {{ t('profile.search_for_location_municipality_or_province') }}
                          </p>
                          <div style="min-width: 250px" class="tag-selector">
                            <q-select
                              bg-color="white"
                              v-model="selectedFavoriteLocations"
                              multiple
                              :options="filterFavoriteLocationsTags"
                              class="budget-input"
                              option-label="label"
                              :placeholder="t('search_schemes.search')"
                              use-input
                              input-debounce="0"
                              behavior="menu"
                              hide-dropdown-icon
                              use-chips
                              @filter="filterLocationsFn"
                              dense
                              borderless
                            >
                              <i
                                v-if="selectedFavoriteLocations == ''"
                                class="las la-search"
                                style="margin-right: 86%"
                              ></i>
                              <template v-slot:no-option>
                                <q-item>
                                  <q-item-section class="text-grey">
                                    {{ t('profile.no_results') }}
                                  </q-item-section>
                                </q-item>
                              </template>
                            </q-select>
                          </div>
                        </div>
                      </q-tab-panel>
                      <div
                        class="location-buttons q-mb-md"
                        v-if="selectedFavoriteLocations == ''"
                      >
                        <q-btn
                          class="q-mr-md float-right submit-profiel"
                          color="orange-8"
                          no-caps
                          :label="t('profile.finish_setting_up_your_profile')"
                          @click="sendTags()"
                        >
                        </q-btn>
                      </div>

                      <q-btn
                        v-else
                        class="q-ml-md float-left submit-profiel"
                        color="orange-8"
                        no-caps
                        :label="t('profile.finish_setting_up_your_profile')"
                        @click="sendTags()"
                      >
                      </q-btn>

                      <!-- This is just hidden for now, don't remove it:-->
                      <!-- <q-tab-panel name="industries">
                        <div style="min-width: 250px">
                          <div
                            class="sidebar-margin item-width"
                            style="min-width: 280px; width: 27vw"
                          >
                            <div class="tree q-mt-lg">
                              <span
                                class="filter-box-heading"
                                v-text="industriesNodes.label"
                              ></span>
                              <q-input
                                dense
                                outlined
                                v-model="filterIndustries"
                                label="Zoeken"
                                class="tree-searchbox"
                              >
                              </q-input>
                              <div class="test">
                                <q-scroll-area
                                  :bar-style="barStyle"
                                  :thumb-style="thumbStyle"
                                  visible
                                  class="tree-scroll-area"
                                >
                                  <q-tree
                                    ref="industriesTree"
                                    :filter="filterIndustries"
                                    :filter-method="myFilterMethod"
                                    :nodes="industriesNodes.options"
                                    node-key="id"
                                    tick-strategy="leaf"
                                    v-model:ticked="tickedIndustries"
                                    v-model:expanded="expandedIndustries"
                                    v-model:selected="selectedIndustries"
                                    @update:ticked="
                                      updateIndustriesPairs(tickedIndustries);
                                      sendIndustriesUpdates(tickedIndustries);
                                    "
                                    class="default-tree"
                                  />
                                </q-scroll-area>
                              </div>
                            </div>
                          </div>
                        </div>
                      </q-tab-panel> -->
                    </div>

                    <!--        <div class="scheme-changes-question">
               
                    <q-checkbox
                      dense
                      size="sm"
                      v-model="schemeChangesInNewsletter"
                      label="Wil je wijzigingen in regelingen binnen dit profiel ook
                    ontvangen?"
                      class="checkbox"
                    />
                  </div> -->
                  </div>
                </q-tab-panel>

                <!-- bez scrolla -->

                <q-tab-panel class="newsletter" name="newsletter" v-if="isVind">
                  <div class="column main-content">
                    <q-breadcrumbs class="text-brown navigation-breadcrumbs">
                      <template v-slot:separator>
                        <q-icon size="1.5em" name="chevron_right"></q-icon>
                      </template>
                      <q-breadcrumbs-el :label="t('profile.profile')"></q-breadcrumbs-el>
                      <q-breadcrumbs-el
                        :label="t('profile.profile_settings')"
                      ></q-breadcrumbs-el>
                      <q-breadcrumbs-el :label="t('profile.for_whom')"></q-breadcrumbs-el>
                    </q-breadcrumbs>
                    <div class="navigation-for-mobile">
                      <div class="row no-wrap">
                        <q-btn
                          class="buttons-nav"
                          no-caps
                          style="background-color: #191d96; color: white"
                          @click="goToTab('newsletter')"
                          >{{ t('profile.for_whom') }} ( {{ favoriteAudience.length }} )
                        </q-btn>
                        <q-btn
                          class="buttons-nav"
                          no-caps
                          @click="goToTab('newsletterPurposes')"
                        >
                          {{ t('profile.subjects') }} ( {{ favoritePurposes.length }} )</q-btn
                        >
                        <q-btn
                          class="buttons-nav"
                          no-caps
                          @click="goToTab('newsletterLocations')"
                        >
                        {{ t('search_schemes.location_tags_label') }}
                        ( {{ selectedFavoriteLocations.length }} )</q-btn
                        >
                      </div>
                    </div>
                    <p
                      class="text-h4 text-left main-caption"
                      style="margin-top: 43px; margin-left: 114px; height: 44px"
                    >
                      {{ t('profile.profile_settings') }}
                    </p>
                    <p class="heading2 q-mb-xl">
                      {{ t('profile.profile_settings_subtitle') }}
                    </p>
                    <div class="q-pt-md card-box">
                      <div
                        class="q-ml-md q-mb-md row no-wrap justify-content-between heading-main"
                      >
                        <h8 class="justify-content-start heading1">{{ t('profile.for_whom') }}</h8>
                        <p class="justify-content-end step">{{ t('profile.step') }} 1/3</p>
                      </div>
                      <div class="column input-field-content">
                        <span
                          class="search-label"
                          v-text="audienceNodes.label"
                        ></span>
                        <q-input
                          v-model="filterAudience"
                          :placeholder="t('search_schemes.search')"
                          class="tree_searchbox"
                          borderless
                          dense
                        >
                          <i
                            v-if="filterAudience == ''"
                            class="las la-search float-left search-i"
                            style="margin-right: 89%; margin-top: 2px"
                          ></i>
                        </q-input>
                      </div>

                      <div class="tree-div">
                        <q-tree
                          ref="audienceTree"
                          :filter="filterAudience"
                          :filter-method="myFilterMethod"
                          :nodes="audienceNodes.options"
                          node-key="id"
                          tick-strategy="leaf"
                          v-model:ticked="tickedAudience"
                          v-model:expanded="expandedAudience"
                          @update:ticked="updateAudiencePairs(tickedAudience)"
                          class="column wrap default-tree profile-tree"
                          icon="chevron_right"
                        />

                        <div class="float-right q-mr-xl">
                          <q-btn
                            class="button-next"
                            color="orange-8"
                            no-caps
                            @click="
                              updateAudiencePairs(tickedAudience),
                                sendAudienceUpdates(tickedAudience),
                                goToTab('newsletterPurposes')
                            "
                          >
                            {{ t('profile.next_step') }}
                            <q-icon
                              name="east"
                              size="18px"
                              class="q-ml-xs"
                            ></q-icon>
                          </q-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </q-tab-panel>

                <q-list
                  class="onderwerp"
                  name="newsletterPurposes"
                  v-if="isVind"
                >
                  <div class="column no-wrap" style="margin-top: 20px">
                    <q-breadcrumbs class="text-brown navigation-breadcrumbs">
                      <template v-slot:separator>
                        <q-icon size="1.5em" name="chevron_right"></q-icon>
                      </template>

                      <q-breadcrumbs-el :label="t('profile.profile')"></q-breadcrumbs-el>
                      <q-breadcrumbs-el
                        :label="t('profile.profile_settings')"
                      ></q-breadcrumbs-el>
                      <q-breadcrumbs-el :label="t('search_schemes.purposes_tags_label')"></q-breadcrumbs-el>
                    </q-breadcrumbs>
                    <div class="navigation-for-mobile">
                      <div class="row no-wrap">
                        <q-btn
                          class="buttons-nav"
                          no-caps
                          @click="goToTab('newsletter')"
                          >{{ t('profile.for_whom') }} ( {{ favoriteAudience.length }} )
                        </q-btn>
                        <q-btn
                          class="buttons-nav"
                          no-caps
                          @click="goToTab('newsletterPurposes')"
                          style="background-color: #191d96; color: white"
                        >
                          {{ t('profile.subjects') }} ( {{ favoritePurposes.length }} )</q-btn
                        >
                        <q-btn
                          class="buttons-nav"
                          no-caps
                          @click="goToTab('newsletterLocations')"
                        >
                          {{ t('search_schemes.location_tags_label') }} (
                          {{ selectedFavoriteLocations.length }} )</q-btn
                        >
                      </div>
                    </div>
                  </div>

                  <div class="purposes-tab">
                    <div
                      class="text-h4 q-mb-xs text-left main-caption"
                      style="margin-top: 43px; margin-left: 114px; height: 44px"
                    >
                      {{ t('profile.profile_settings') }}
                    </div>
                    <p class="heading2 q-mb-xl">
                      {{ t('profile.profile_settings_subtitle') }}
                    </p>
                    <q-card class="card-info">
                      <q-tabs
                        v-model="tagsTab"
                        dense
                        class="text-grey"
                        align="justify"
                        narrow-indicator
                      >
                        <!-- <q-tab name="industries" label="Industrie">
                        <q-badge color="#0a1f8f" class="tag-badge" floating>{{
                          favoriteIndustries.length
                        }}</q-badge>
                      </q-tab> -->
                      </q-tabs>
                      <q-tab-panel id="purposes" name="purposes">
                        <div class="column" style="min-width: 250px">
                          <div
                            class="nav-top"
                            style="
                              display: flex;
                              flex-direction: row;
                              max-width: 796px;
                              flex-wrap: nowrap;
                            "
                          >
                            <span class="heading1">{{ t('search_schemes.purposes_tags_label') }}</span>
                            <p class="step">{{ t('profile.step') }} 2/3</p>
                          </div>
                          <span
                            class="filter-box-heading"
                            v-text="purposesNodes.label"
                          ></span>
                          <q-input
                            dense
                            borderless
                            v-model="filterPurposes"
                            :placeholder="t('search_schemes.search')"
                            class="tree-searchbox"
                            style="background-color: white; max-width: 685px"
                          >
                            <i
                              v-if="filterPurposes == ''"
                              class="las la-search float-left"
                              style="margin-right: 89%"
                            ></i>
                          </q-input>

                          <q-tree
                            ref="purposesTree"
                            :filter="filterPurposes"
                            :filter-method="myFilterMethod"
                            :nodes="purposesNodes.options"
                            node-key="id"
                            tick-strategy="leaf"
                            v-model:ticked="tickedPurposes"
                            v-model:expanded="expandedPurposes"
                            @update:ticked="updatePurposesPairs(tickedPurposes)"
                            class="default-tree"
                            icon="chevron_right"
                          >
                          </q-tree>

                          <div class="next">
                            <div class="row float-right no-wrap">
                              <q-btn
                                class="button-next"
                                color="orange-8"
                                no-caps
                                @click="
                                  updatePurposesPairs(tickedPurposes),
                                    sendPurposesUpdates(tickedPurposes),
                                    goToTab('newsletterLocations')
                                "
                              >
                                {{ t('profile.next_step') }}
                                <q-icon
                                  name="east"
                                  size="18px"
                                  class="q-ml-sm"
                                ></q-icon>
                              </q-btn>
                            </div>
                          </div>
                        </div>
                      </q-tab-panel>

                      <!-- This is just hidden for now, don't remove it:-->
                      <!-- <q-tab-panel name="industries">
                        <div style="min-width: 250px">
                          <div
                            class="sidebar-margin item-width"
                            style="min-width: 280px; width: 27vw"
                          >
                            <div class="tree q-mt-lg">
                              <span
                                class="filter-box-heading"
                                v-text="industriesNodes.label"
                              ></span>
                              <q-input
                                dense
                                outlined
                                v-model="filterIndustries"
                                label="Zoeken"
                                class="tree-searchbox"
                              >
                              </q-input>
                              <div class="test">
                                <q-scroll-area
                                  :bar-style="barStyle"
                                  :thumb-style="thumbStyle"
                                  visible
                                  class="tree-scroll-area"
                                >
                                  <q-tree
                                    ref="industriesTree"
                                    :filter="filterIndustries"
                                    :filter-method="myFilterMethod"
                                    :nodes="industriesNodes.options"
                                    node-key="id"
                                    tick-strategy="leaf"
                                    v-model:ticked="tickedIndustries"
                                    v-model:expanded="expandedIndustries"
                                    v-model:selected="selectedIndustries"
                                    @update:ticked="
                                      updateIndustriesPairs(tickedIndustries);
                                      sendIndustriesUpdates(tickedIndustries);
                                    "
                                    class="default-tree"
                                  />
                                </q-scroll-area>
                              </div>
                            </div>
                          </div>
                        </div>
                      </q-tab-panel> -->
                    </q-card>

                    <!--        <div class="scheme-changes-question">
               
                    <q-checkbox
                      dense
                      size="sm"
                      v-model="schemeChangesInNewsletter"
                      label="Wil je wijzigingen in regelingen binnen dit profiel ook
                    ontvangen?"
                      class="checkbox"
                    />
                  </div> -->
                  </div>
                  <q-dialog v-model="showConfirmation">
                    <q-card>
                      <q-card-section class="row items-center">
                        <i class="las la-2x la-check"></i>
                        <span class="q-ml-sm">
                          {{ t('profile.you_will_receive_the_subsidy_newsletter') }}
                          {{ frequencyNL }}
                        </span>
                      </q-card-section>

                      <q-card-actions align="right">
                        <q-btn
                          outline
                          :label="t('common.ok')"
                          color="primary"
                          @click="disableConfirmation()"
                        />
                      </q-card-actions>
                    </q-card>
                  </q-dialog>
                </q-list>

                <q-tab-panel
                  class="hiddenSchemes"
                  name="hiddenSchemes"
                  v-if="isVind"
                >
                  <HiddenSchemes />
                </q-tab-panel>
                <q-tab-panel
                  class="usersTableManagement"
                  name="gebruikers"
                  v-if="isVind"
                >
                  <UsersTableManagement />
                </q-tab-panel>
                <q-tab-panel
                  class="customersBasicDetails"
                  name="accountgegevens"
                  v-if="isVind"
                >
                  <CustomersBasicDetails />
                </q-tab-panel>

                <div class="userManagement" name="gebruik" v-if="isVind">
                  <UserManagement />
                </div>
              </q-tab-panels>
            </template>
          </q-splitter>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { ref, computed, onMounted /*watch*/ } from 'vue';
import store from '../store/index.js';
import UserService from '../services/userprofile.service';
import FiltersService from '../services/filters.service';
import authService from '../services/auth.service';
import moment from 'moment';
import { useRoute, useRouter } from 'vue-router';
import ErrorModal from '../components/userProfile-components/ErrorModal.vue';
import SavedItemsSchemes from '../components/userProfile-components/SavedItemsSchemes.vue';
import SavedItemsSearches from '../components/userProfile-components/SavedItemsSearches.vue';
import HiddenSchemes from '../components/userProfile-components/HiddenSchemes.vue';
import OrganisationsService from '../services/organisations.service.js';
import UserManagement from '../components/userProfile-components/UserManagement.vue';
import WelcomeDialog from '../components/onboarding-components/WelcomeDialog.vue';
import WarningDialog from '../components/onboarding-components/WarningDialog.vue';
import ShareScheme from '../components/share-components/ShareScheme.vue';
import UsersTableManagement from '../components/userProfile-components/UsersTableManagement.vue';
import CustomersBasicDetails from '../components/userProfile-components/CustomersBasicDetails.vue';
import {
  onBeforeRouteLeave,
  /* onBeforeRouteUpdate, */ onBeforeRouteUpdate,
} from 'vue-router';

import { useI18n } from 'vue-i18n';

export default {
  components: {
    SavedItemsSchemes,
    ShareScheme,
    HiddenSchemes,
    ErrorModal,
    UserManagement,
    WelcomeDialog,
    WarningDialog,
    UsersTableManagement,
    CustomersBasicDetails,
    SavedItemsSearches,
  },
  name: 'UserProfile',
  /* beforeRouteLeave(to, from, next) {
    // If onboarding == false, show modal warning
    // Else
    // if has set proifile after login == false && onboarding iz localstorage == false
    // show dialog
    const localStorageUser = JSON.parse(localStorage.getItem('user'));

    const hasSetupProfileAfterLogin = computed({
      get: () => store.state.user.hasSetProfileAfterLogin,
    });

    const showLeavingProfileSetupWarning = computed({
      get: () => store.state.user.showLeavingProfileSetupWarning,
    });

    if (
      !localStorageUser.hasCompletedOnboarding &&
      !hasSetupProfileAfterLogin.value
    ) {
      store.commit('user/updateShowLeavingProfileModal', true);
    }

    if (!showLeavingProfileSetupWarning.value) {
      next();
    } else {
      next(false);
    }
  }, */
  setup() {
    const { t, locale } = useI18n();
    const localStorageUser = JSON.parse(localStorage.getItem('user'));
    const route = useRoute();
    const router = useRouter();
    const tab = ref(route.params.tab);
    const isClickedNewsletter = computed({
      get: () => store.state.user.isClickedNewsletter,
      set: (val) => store.commit('user/updateIsClickedNewsletter', val),
    });
    const isClickedSavedItems = computed({
      get: () => store.state.user.isClickedSavedItems,
      set: (val) => store.commit('user/updateIsClickedSavedItems', val),
    });
    const isClickedUserManagement = computed({
      get: () => store.state.user.isClickedUserManagement,
      set: (val) => store.commit('user/updateIsClickedUserManagement', val),
    });
    const tagsTab = ref('audiences');
    const filterAudience = ref('');
    const filterPurposes = ref('');
    //This is just hidden for now, don't remove it:
    //const filterIndustries = ref('');
    const expandedAudience = ref([]);
    //const selectedAudience = ref([]);
    const expandedPurposes = ref([]);
    // const selectedPurposes = ref([]);
    //This is just hidden for now, don't remove it:
    //const expandedIndustries = ref([]);
    //const selectedIndustries = ref([]);
    const audiencePairs = ref([]);
    const purposesPairs = ref([]);
    //This is just hidden for now, don't remove it:
    //const industriesPairs = ref([]);
    const audienceTree = ref(null);
    const purposesTree = ref(null);
    const breadcrumbsLabel = ref('');
    const labelSavedItems = ref('');
    const labelProfilDetails = ref('');
    const labelProfilCaption = ref('');
    const showWarningUnsubscriptionDialog = ref(false);
    const showConfirmUnsubscriptionDialog = ref(false);
    const reasonForUnsubscription = ref('');
    //This is just hidden for now, don't remove it:
    //const industriesTree = ref(null);
    //const subTab = ref("myBasicDetailsSubTab");
    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });

    onMounted(async () => {
      UserService.getUser(locale);
      UserService.getHiddenSchemes();
      UserService.getFavoriteAudienceTags();
      UserService.getFavoritePurposesTags();
      authService.getPortalTypes();
      authService.getSearchInterests();
      //This is just hidden for now, don't remove it:
      //UserService.getFavoriteIndustriesTags();
      UserService.getFavoriteLocationsTags();
      UserService.getNewsletterFrequency();
      UserService.getProfileConfiguration();
      await FiltersService.getDefaultFilters(locale.value);
      //this is used for loading saved filters if organisations filters are included:
      OrganisationsService.getAllOrganisationsWithBasicInfo();
      portalLabelsUpdate();
    });

    const favoriteAudience = computed({
      get: () => store.state.user.favoriteAudiencesIdsArray,
    });

    const favoritePurposes = computed({
      get: () => store.state.user.favoritePurposesIdsArray,
    });
    //This is just hidden for now, don't remove it:
    /*
    const favoriteIndustries = computed({
      get: () => store.state.user.favoriteIndustriesIdsArray,
    });
    */
    const tickedAudience = ref(favoriteAudience.value);

    const tickedPurposes = ref(favoritePurposes.value);

    //This is just hidden for now, don't remove it:
    //const tickedIndustries = ref(favoriteIndustries.value);

    //to add parent if all children are selected
    const updateAudiencePairs = (keys) => {
      audiencePairs.value = [];
      if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          let node = audienceTree.value.getNodeByKey(keys[i]);
          audiencePairs.value.push(node);
        }

        var toAdd = [];
        for (let i = 0; i < audienceNodes.value.options.length; i++) {
          if (
            audienceNodes.value.options[i].children &&
            audienceNodes.value.options[i].children.length > 0
          ) {
            var hasAllChildrenTicked = true;

            for (
              let j = 0;
              j < audienceNodes.value.options[i].children.length;
              j++
            ) {
              if (
                !keys.includes(audienceNodes.value.options[i].children[j].id)
              ) {
                hasAllChildrenTicked = false;
                break;
              }
            }
            if (hasAllChildrenTicked)
              toAdd.push(audienceNodes.value.options[i]);
          } else {
            hasAllChildrenTicked = false;
          }
        }
        if (toAdd.length > 0) {
          for (let i = 0; i < toAdd.length; i++)
            audiencePairs.value.push(toAdd[i]);
        }
      }
    };

    //to add parent if all children are selected
    const updatePurposesPairs = (keys) => {
      purposesPairs.value = [];
      if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          var node = purposesTree.value.getNodeByKey(keys[i]);
          purposesPairs.value.push(node);
        }
        var toAdd = [];
        for (let i = 0; i < purposesNodes.value.options.length; i++) {
          if (
            purposesNodes.value.options[i].children &&
            purposesNodes.value.options[i].children.length > 0
          ) {
            var hasAllChildrenTicked = true;

            for (
              let j = 0;
              j < purposesNodes.value.options[i].children.length;
              j++
            ) {
              if (
                !keys.includes(purposesNodes.value.options[i].children[j].id)
              ) {
                hasAllChildrenTicked = false;
                break;
              }
            }
            if (hasAllChildrenTicked)
              toAdd.push(purposesNodes.value.options[i]);
          } else {
            hasAllChildrenTicked = false;
          }
        }
        if (toAdd.length > 0) {
          for (let i = 0; i < toAdd.length; i++)
            purposesPairs.value.push(toAdd[i]);
        }
      }
    };

    //This is just hidden for now, don't remove it:
    //to add parent if all children are selected
    /*
    const updateIndustriesPairs = (keys) => {
      industriesPairs.value = [];
      if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          let node = industriesTree.value.getNodeByKey(keys[i]);
          industriesPairs.value.push(node);
        }
        var toAdd = [];
        for (let i = 0; i < industriesNodes.value.options.length; i++) {
          if (
            industriesNodes.value.options[i].children &&
            industriesNodes.value.options[i].children.length > 0
          ) {
            var hasAllChildrenTicked = true;

            for (
              let j = 0;
              j < industriesNodes.value.options[i].children.length;
              j++
            ) {
              if (
                !keys.includes(industriesNodes.value.options[i].children[j].id)
              ) {
                hasAllChildrenTicked = false;
                break;
              }
            }
            if (hasAllChildrenTicked)
              toAdd.push(industriesNodes.value.options[i]);
          } else {
            hasAllChildrenTicked = false;
          }
        }
        if (toAdd.length > 0) {
          for (let i = 0; i < toAdd.length; i++)
            industriesPairs.value.push(toAdd[i]);
        }
      }
    };
    */

    const user = computed({
      get: () => store.state.user.user,
    });

    const enableEditMode = ref(false);

    const customer = computed({
      get: () => store.state.user.user.person.customer,
    });

    const portalOrganisation = computed({
      get: () => store.state.user.user.person.portalOrganisation,
      set: (value) => store.commit('user/updatePortalOrganisation', value),
    });

    const username = computed({
      get: () => store.state.user.user.username,
      set: (value) => store.commit('user/updateUsername', value),
    });

    const themeType = computed({
      get: () => store.state.user.user.themeType,
      set: (value) => store.commit('user/updateThemeType', value),
    });

    const searchInterest = computed({
      get: () => store.state.user.user.searchInterest,
      set: (value) => store.commit('user/updateSearchInterest', value),
    });
    const isInformedAboutNews = computed({
      get: () => store.state.user.user.isInformedAboutNews,
      set: (value) => store.commit('user/updateIsInformedAboutNews', value),
    });

    const portalThemeTypes = computed({
      get: () => store.state.auth.portalThemeTypes,
    });

    const searchInterests = computed({
      get: () => store.state.auth.searchInterests,
    });

    const email = computed({
      get: () => store.state.user.user.email,
      set: (value) => store.commit('user/updateEmail', value),
    });

    const firstName = computed({
      get: () => store.state.user.user.person.firstName,
      set: (value) => store.commit('user/updateFirstName', value),
    });

    const lastName = computed({
      get: () => store.state.user.user.person.lastName,
      set: (value) => store.commit('user/updateLastName', value),
    });

    const frequency = computed({
      get: () => store.state.user.newsletterFrequencyType,
      set: (value) => {
        store.commit('user/updateNewsletterFrequencyType', value);
        UserService.setNewsletterFrequency(value);
        frequencyNL.value = value.toLowerCase();
      },
    });

    const frequencyNL = ref('');

    const schemeChangesInNewsletter = computed({
      get: () => store.state.user.schemeChangesInNewsletterStatus,
      set: (value) => {
        store.commit('user/updateSchemeChangesInNewsletterStatus', value);
        UserService.setSchemeChangesInNewsletterStatus(value);
      },
    });

    const openDetailsInNewTab = computed({
      get: () => store.state.user.openDetailsInNewTab,
      set: (value) => {
        store.commit('user/updateOpenDetailsInNewTab', value);
        UserService.setOpenDetailsInNewTab(value);
      },
    });

    const updatedUser = {
      username: username,
      email: email,
      firstName: firstName,
      lastName: lastName,
      portalOrganisation: portalOrganisation,
    };

    const saveChanges = async () => {
      enableEditMode.value = false;
      await UserService.editUser(updatedUser);
      if (!isVind.value && themeType.value != '') {
        await UserService.updateThemeType(themeType.value);
      }
      if (!isVind.value && searchInterest.value != '') {
        await UserService.updateSearchInterest(searchInterest.value);
      }
    };
    const confirmUnsubscription = async () => {
      await UserService.updateInformedAboutNews(isInformedAboutNews.value);
      showConfirmUnsubscriptionDialog.value = true;
    };

    const updateIsInformedAboutNewsDialog = async () => {
      if (!isInformedAboutNews.value)
        showWarningUnsubscriptionDialog.value = true;
      else {
        showWarningUnsubscriptionDialog.value = false;
        await UserService.updateInformedAboutNews(isInformedAboutNews.value);
        await UserService.updateUnsubscriptionFromNewsReason('');
      }
    };

    const updateUnsubscriptionFromNewsReason = async () => {
      if (reasonForUnsubscription.value != '') {
        await UserService.updateUnsubscriptionFromNewsReason(
          reasonForUnsubscription.value
        );
        await UserService.updateInformedAboutNews(isInformedAboutNews.value);
        showConfirmUnsubscriptionDialog.value = false;
      } else {
        showConfirmUnsubscriptionDialog.value = true;
      }
      reasonForUnsubscription.value = '';
    };

    const cancelUpdate = () => {
      isInformedAboutNews.value = !isInformedAboutNews.value;
    };

    const formatDate = (stringDate) => {
      const date = new Date(stringDate);
      return moment(date).format('YYYY/MM/DD');
    };

    const audienceNodes = computed({
      get: () => store.state.advancedSearch.audience,
    });

    //This is just hidden for now, don't remove it:
    /*
    const industriesNodes = computed({
      get: () => store.state.advancedSearch.industries,
    });
    */

    const locationsNodes = computed({
      get: () => store.state.advancedSearch.locations,
    });

    const purposesNodes = computed({
      get: () => store.state.advancedSearch.purposes,
    });

    const locationsOptions = computed({
      get: () => store.state.advancedSearch.locationsOptions,
    });

    const filterFavoriteLocationsTags = ref(locationsOptions.value);

    const sendPurposesUpdates = () => {
      UserService.setFavoriteTags(purposesPairs.value, 'Onderwerp');
    };

    const selectedFavoriteLocations = computed({
      get: () => store.state.user.selectedFavoriteLocations,
      set: (value) =>
        store.commit('user/updateSelectedFavoriteLocations', value),
    });

    const sendAudienceUpdates = async () => {
      await UserService.setFavoriteTags(
        audiencePairs.value,
        'Voor wie zoekt u subsidies?'
      );
    };

    const sendTags = async () => {
      const selectedLocation = computed({
        get: () => store.state.user.selectedFavoriteLocations,
      });

      UserService.setFavoriteTags(selectedLocation.value, 'Locatie');
      await UserService.completeOnboarding(localStorageUser.id);
      // store.commit has completed profile after login to true
      router.push({ name: 'SearchGrants', params: { tab: 'search-grants' } });
    };

    //This is just hidden for now, don't remove it:
    /*
    const sendIndustriesUpdates = () => {
      UserService.setFavoriteTags(
        industriesPairs.value,
        'Industrieën'
      );
    };
    */

    const isClickedMenuItemUsers = computed({
      get: () => store.state.user.isClickedMenuItemUsers,
    });

    const isClickedMenuItemSchemes = computed({
      get: () => store.state.user.isClickedMenuItemSchemes,
    });

    const isClickedMenuItemSearches = computed({
      get: () => store.state.user.isClickedMenuItemSearches,
    });
    const isClickedMenuItemCustomersBasic = computed({
      get: () => store.state.user.isClickedMenuItemCustomersBasic,
    });

    const isClickedMenuItemUserStats = computed({
      get: () => store.state.user.isClickedMenuItemUserStats,
    });

    const isClickedMenuItemAudiences = computed({
      get: () => store.state.user.isClickedMenuItemAudiences,
    });
    const isClickedMenuItemPurposes = computed({
      get: () => store.state.user.isClickedMenuItemPurposes,
    });
    const isClickedMenuItemLocations = computed({
      get: () => store.state.user.isClickedMenuItemLocations,
    });
    const showConfirmation = computed({
      get: () => store.state.user.showConfirmation,
    });

    const disableConfirmation = () => {
      store.commit('user/disableConfirmation');
    };

    const showAdminPanel = computed({
      get: () => store.state.auth.showAdminPanel,
    });

    const resetExpansionColorsUserAccount = () => {
      store.commit('user/updateIsClickedMenuItemCustomersBasic', false);
      store.commit('user/updateIsClickedMenuItemUsers', false);
      store.commit('user/updateIsClickedMenuItemUserStats', false);
    };

    const resetExpansionColorsSavedItems = () => {
      store.commit('user/updateIsClickedMenuItemSchemes', false);
      store.commit('user/updateIsClickedMenuItemSearches', false);
    };

    const resetExpansionColorsNewsletter = () => {
      store.commit('user/updateIsClickedMenuItemAudiences', false);
      store.commit('user/updateIsClickedMenuItemPurposes', false);
      store.commit('user/updateIsClickedMenuItemLocations', false);
    };

    const goToTab = (tabName) => {
      if (tabName == 'newsletter') {
        store.commit('user/updateIsClickedMenuItemAudiences', true);
        store.commit('user/updateIsClickedMenuItemPurposes', false);
        store.commit('user/updateIsClickedMenuItemLocations', false);
        router.push({
          name: 'UserProfile',
          params: { tab: 'newsletter' },
        });
      }
      if (tabName == 'newsletterPurposes') {
        store.commit('user/updateIsClickedMenuItemAudiences', false);
        store.commit('user/updateIsClickedMenuItemPurposes', true);
        store.commit('user/updateIsClickedMenuItemLocations', false);
        router.push({
          name: 'UserProfile',
          params: { tab: 'newsletterPurposes' },
        });
      }
      if (tabName == 'newsletterLocations') {
        store.commit('user/updateIsClickedMenuItemAudiences', false);
        store.commit('user/updateIsClickedMenuItemPurposes', false);
        store.commit('user/updateIsClickedMenuItemLocations', true);
        router.push({
          name: 'UserProfile',
          params: { tab: 'newsletterLocations' },
        });
      }

      if (tabName == 'accountgegevens') {
        store.commit('user/updateIsClickedMenuItemCustomersBasic', true);
        store.commit('user/updateIsClickedMenuItemUsers', false);
        store.commit('user/updateIsClickedMenuItemUserStats', false);
        router.push({
          name: 'UserProfile',
          params: { tab: 'accountgegevens' },
        });
      }

      if (tabName == 'gebruikers') {
        store.commit('user/updateIsClickedMenuItemCustomersBasic', false);
        store.commit('user/updateIsClickedMenuItemUsers', true);
        store.commit('user/updateIsClickedMenuItemUserStats', false);
        router.push({
          name: 'UserProfile',
          params: { tab: 'gebruikers' },
        });
      }
      if (tabName == 'gebruik') {
        store.commit('user/updateIsClickedMenuItemCustomersBasic', false);
        store.commit('user/updateIsClickedMenuItemUsers', false);
        store.commit('user/updateIsClickedMenuItemUserStats', true);
        router.push({
          name: 'UserProfile',
          params: { tab: 'gebruik' },
        });
      }
      if (tabName == 'savedItemsSchemes') {
        store.commit('user/updateIsClickedMenuItemSearches', false);
        store.commit('user/updateIsClickedUserManagement', false);
        store.commit('user/updateIsClickedMenuItemSchemes', true);
        router.push({
          name: 'UserProfile',
          params: { tab: 'savedItemsSchemes' },
        });
      }
      if (tabName == 'savedItemsSearches') {
        store.commit('user/updateIsClickedMenuItemSearches', true);
        store.commit('user/updateIsClickedMenuItemSchemes', false);
        store.commit('user/updateIsClickedUserManagement', false);
        router.push({
          name: 'UserProfile',
          params: { tab: 'savedItemsSearches' },
        });
      }

      if (tabName == 'savedItemsSchemes' || tabName == 'savedItemsSearches') {
        store.commit('user/updateIsClickedSavedItems', true);
        store.commit('user/updateIsClickedUserManagement', false);
        store.commit('user/updateIsClickedNewsletter', false);
        resetExpansionColorsNewsletter();
        resetExpansionColorsUserAccount();
      }
      if (
        tabName == 'gebruik' ||
        tabName == 'gebruikers' ||
        tabName == 'accountgegevens'
      ) {
        store.commit('user/updateIsClickedSavedItems', false);
        store.commit('user/updateIsClickedUserManagement', true);
        store.commit('user/updateIsClickedNewsletter', false);
        resetExpansionColorsSavedItems();
        resetExpansionColorsNewsletter();
      }
      if (
        tabName == 'newsletter' ||
        tabName == 'newsletterPurposes' ||
        tabName == 'newsletterLocations'
      ) {
        store.commit('user/updateIsClickedSavedItems', false);
        store.commit('user/updateIsClickedNewsletter', true);
        store.commit('user/updateIsClickedUserManagement', false);
        resetExpansionColorsUserAccount();
        resetExpansionColorsSavedItems();
      }
      if (
        tabName == 'hiddenSchemes' ||
        tabName == 'nieuwsbrief' ||
        tabName == 'myBasicDetails'
      ) {
        store.commit('user/updateIsClickedSavedItems', false);
        store.commit('user/updateIsClickedUserManagement', false);
        store.commit('user/updateIsClickedNewsletter', false);
        resetExpansionColorsUserAccount();
        resetExpansionColorsSavedItems();
        resetExpansionColorsNewsletter();
      }
    };

    const portalLabelsUpdate = () => {
      if (isVind.value) {
        labelSavedItems.value = t('navbar.saved_items');
        labelProfilDetails.value = t('navbar.profile_settings');
        labelProfilCaption.value = t('navbar.details');
      } else {
        labelSavedItems.value = 'Favorieten';
        labelProfilDetails.value = 'Mijn gegevens';
        labelProfilCaption.value = 'Profiel gegevens';
      }
      if (tab.value == 'savedItemsSchemes')
        breadcrumbsLabel.value = 'Favorieten';
      else if (tab.value == 'myBasicDetails')
        breadcrumbsLabel.value = 'Mijn gegevens';
    };

    const isContrast = computed({
      get: () => store.state.user.isSelectedThemeContrast,
    });

    const audienceLabel = computed(() => 
      `${ t('profile.for_whom') } (${ favoriteAudience.value.length })`
    );

    const purposesLabel = computed(() => 
      `${ t('search_schemes.purposes_tags_label') } (${ favoritePurposes.value.length })`
    );

    const locationsLabel = computed(() => 
      `${ t('search_schemes.location_tags_label') } (${ selectedFavoriteLocations.value.length })`
    );

    const hasSetupProfileAfterLogin = computed({
      get: () => store.state.user.hasSetProfileAfterLogin,
    });

    const showLeavingProfileSetupWarning = computed({
      get: () => store.state.user.showLeavingProfileSetupWarning,
    });

    const hasBeenRedirectedToProfile = computed({
      get: () => store.state.user.hasBeenRedirectedToProfile,
    });

    const hasConfirmedLeave = computed({
      get: () => store.state.user.hasConfirmedLeave,
    });

    onBeforeRouteLeave((to, from) => {
      if (to.fullPath != '/login' && from.fullPath == '/profile/newsletter')
        if (
          !localStorageUser.hasCompletedProfileSetup &&
          !hasSetupProfileAfterLogin.value &&
          !hasConfirmedLeave.value
        ) {
          store.commit('user/updateShowLeavingProfileModal', true);
          store.commit('user/setToPath', to.name);
          return false;
        }
    });

    onBeforeRouteUpdate((to, from) => {
      if (from.fullPath == '/profile/newsletter')
        if (
          !localStorageUser.hasCompletedProfileSetup &&
          !hasSetupProfileAfterLogin.value &&
          !hasConfirmedLeave.value
        ) {
          store.commit('user/updateShowLeavingProfileModal', true);
          store.commit('user/setToPath', 'UserProfile');
          store.commit('user/setToPathTab', to.params.tab);
          tab.value = 'newsletter';

          return false;
        }
    });

    /*     watch(
      () => route.params.tab,
      (next, prev) => {
        if (prev == 'newsletter') {
          if (
            !localStorageUser.hasCompletedProfileSetup &&
            !hasSetupProfileAfterLogin.value &&
            !hasConfirmedLeave.value
          ) {
            store.commit('user/updateShowLeavingProfileModal', true);
            store.commit('user/setToPath', 'UserProfile');
            store.commit('user/setToPathTab', next);
            return false;
          }
        }
      }
    ); */
    const isSKS = computed({
      get: () => store.state.auth.env === 30,
    });

    return {
      t,
      locale,
      isSKS,
      resetExpansionColorsUserAccount,
      showWarningUnsubscriptionDialog,
      reasonForUnsubscription,
      cancelUpdate,
      confirmUnsubscription,
      updateUnsubscriptionFromNewsReason,
      showConfirmUnsubscriptionDialog,
      searchInterest,
      labelProfilDetails,
      labelProfilCaption,
      breadcrumbsLabel,
      isClickedNewsletter,
      portalOrganisation,
      isClickedSavedItems,
      isClickedUserManagement,
      hasConfirmedLeave,
      hasBeenRedirectedToProfile,
      showLeavingProfileSetupWarning,
      hasSetupProfileAfterLogin,
      localStorageUser,
      isClickedMenuItemAudiences,
      isClickedMenuItemPurposes,
      isClickedMenuItemLocations,
      isClickedMenuItemSchemes,
      isClickedMenuItemSearches,
      isClickedMenuItemCustomersBasic,
      isClickedMenuItemUsers,
      isClickedMenuItemUserStats,
      purposesLabel,
      locationsLabel,
      audienceLabel,
      isInformedAboutNews,
      isContrast,
      isVind,
      goToTab,
      frequencyNL,
      // subTab,
      tab,
      tagsTab,
      splitterModel: ref(20),
      user,
      enableEditMode,
      saveChanges,
      updateIsInformedAboutNewsDialog,
      portalLabelsUpdate,
      updatedUser,
      username,
      themeType,
      portalThemeTypes,
      searchInterests,
      email,
      firstName,
      lastName,
      formatDate,
      audienceNodes,
      purposesNodes,
      locationsNodes,
      //This is just hidden for now, don't remove it:
      //industriesNodes,
      filterFavoriteLocationsTags,
      selectedFavoriteLocations,
      filterAudience,
      //This is just hidden for now, don't remove it:
      //filterIndustries,
      filterPurposes,
      tickedAudience,
      // selectedAudience,
      expandedAudience,
      labelSavedItems,
      //This is just hidden for now, don't remove it:
      // tickedIndustries,
      // selectedIndustries,
      // expandedIndustries,
      tickedPurposes,
      expandedPurposes,
      // selectedPurposes,
      sendAudienceUpdates,
      sendPurposesUpdates,
      sendTags,
      //This is just hidden for now, don't remove it:
      //sendIndustriesUpdates,
      audiencePairs,
      purposesPairs,
      //This is just hidden for now, don't remove it:
      //industriesPairs,
      updateAudiencePairs,
      updatePurposesPairs,
      //This is just hidden for now, don't remove it:
      //updateIndustriesPairs,
      audienceTree,
      purposesTree,
      //This is just hidden for now, don't remove it:
      //industriesTree,
      // updateSelected,
      favoriteAudience,
      favoritePurposes,
      //This is just hidden for now, don't remove it:
      //favoriteIndustries,
      customer,
      frequency,
      route,
      router,
      showConfirmation,
      disableConfirmation,
      schemeChangesInNewsletter,
      resetExpansionColorsSavedItems,
      resetExpansionColorsNewsletter,
      openDetailsInNewTab,
      showAdminPanel,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || t('common.required_field'),
      ],
      myFilterMethod(node, filter) {
        const filt = filter.toLowerCase();
        return node.label && node.label.toLowerCase().indexOf(filt) > -1;
      },
      filterLocationsFn(val, update, abort) {
        if (val.length < 1) {
          abort();
          return;
        }
        update(() => {
          if (val === '') {
            filterFavoriteLocationsTags.value = locationsOptions.value;
          } else {
            const needle = val.toLowerCase();

            filterFavoriteLocationsTags.value = locationsOptions.value.filter(
              (v) =>
                v.label.split(/,(.+)/)[0].toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      barStyle: {
        right: '2px',
        borderRadius: '9px',
        backgroundColor: '#f0f2f5',
        width: '8px',
        opacity: 0.2,
      },
      thumbStyle: {
        right: '4px',
        borderRadius: '7px',
        backgroundColor: '#0a108f',
        width: '4px',
        opacity: 0.75,
      },
    };
  },
};
</script>
