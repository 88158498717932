<template>
  <div class="filter-box statuses-checkbox">
    <q-checkbox
      dense
      v-model="veryDifficult"
      label="Heel moeilijk"
      class="status-checkbox"
    />
    <q-checkbox
      dense
      v-model="difficult"
      label="Moeilijk"
      class="status-checkbox"
    />
    <q-checkbox
      dense
      v-model="average"
      :label="t('scheme_details.average')"
      class="status-checkbox"
    />
    <q-checkbox
      dense
      v-model="simple"
      label="Eenvoudig"
      class="status-checkbox"
    />
    <q-checkbox
      dense
      v-model="verySimple"
      label="Heel eenvoudig"
      class="status-checkbox"
    />
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import store from '../../store/index';
import SchemeUtils from '../../utils/schemes';
import { useI18n } from "vue-i18n"

export default {
  setup() {
    onMounted(() => {});
    const { locale } = useI18n();
    const veryDifficult = computed({
      get: () =>
        sessionStorage.getItem('showVeryDifficult') == null ||
        store.state.advancedSearch.showVeryDifficult ===
          (sessionStorage.getItem('showVeryDifficult') === 'true')
          ? store.state.advancedSearch.showVeryDifficult
          : sessionStorage.getItem('showVeryDifficult') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateShowVeryDifficult', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const difficult = computed({
      get: () =>
        sessionStorage.getItem('showDifficult') == null ||
        store.state.advancedSearch.showDifficult ===
          (sessionStorage.getItem('showDifficult') === 'true')
          ? store.state.advancedSearch.showDifficult
          : sessionStorage.getItem('showDifficult') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateShowDifficult', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const average = computed({
      get: () =>
        sessionStorage.getItem('showAverageDiff') == null ||
        store.state.advancedSearch.showAverageDiff ===
          (sessionStorage.getItem('showAverageDiff') === 'true')
          ? store.state.advancedSearch.showAverageDiff
          : sessionStorage.getItem('showAverageDiff') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateShowAverageDiff', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const simple = computed({
      get: () =>
        sessionStorage.getItem('showSimpleDiff') == null ||
        store.state.advancedSearch.showSimpleDiff ===
          (sessionStorage.getItem('showSimpleDiff') === 'true')
          ? store.state.advancedSearch.showSimpleDiff
          : sessionStorage.getItem('showSimpleDiff') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateShowSimpleDiff', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const verySimple = computed({
      get: () =>
        sessionStorage.getItem('showVerySimpleDiff') == null ||
        store.state.advancedSearch.showVerySimpleDiff ===
          (sessionStorage.getItem('showVerySimpleDiff') === 'true')
          ? store.state.advancedSearch.showVerySimpleDiff
          : sessionStorage.getItem('showVerySimpleDiff') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateShowVerySimpleDiff', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });

    const resetSelected = () => {
      store.commit('advancedSearch/updateShowOpen', false);
      store.commit('advancedSearch/updateShowClosed', false);
      store.commit('advancedSearch/updateShowForthcoming', false);
      store.commit('advancedSearch/updateShowDiscontinued', false);
      if (!isSearchPaused.value) SchemeUtils.searchSchemes(locale);
    };
    const showDiscontinuedCheckbox = store.state.auth.env !== 20;
    const isSearchPaused = computed({
      get: () => store.state.advancedSearch.applySavedSearchPause,
    });
    return {
      locale,
      isSearchPaused,
      resetSelected,
      group: ref([]),
      veryDifficult,
      difficult,
      closed,
      simple,
      showDiscontinuedCheckbox,
      average,
      verySimple,
    };
  },
};
</script>

<style></style>
